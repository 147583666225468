import Chart from 'chart.js';
import "chartjs-plugin-annotation";
import { GetThemeColor } from '../Global/Global';

export default function GrowthChart(ctx, data, xlabel, ylabel) {
    var themeColor = GetThemeColor().themeMainColor;
    var ctx = document.getElementById("sk-chart");
    var chart = new Chart(ctx, {
        type: 'line',
        data: data ,
        options: {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 20,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: ylabel,
                        fontFamily: 'Jost',
                        fontColor : themeColor,
                        fontSize:16,
                    },
                    gridLines: {
                        color: "rgba(0, 0, 0, 0)",
                    }
                }],
                xAxes: [{
                    ticks :{
                        fontFamily: 'Jost'
                    },
                    gridLines: {
                        color: "rgba(0, 0, 0, 0.1)",
                    }
                }],
            },
            legend: {
                labels: {
                    fontFamily: 'Jost'
                }
            }
        }
    });

    return chart;
}
