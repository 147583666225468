import React, { Component } from 'react';
import DefaultHeader from '../DefaultHeader/DefaultHeader';
import DefaultContentWrapper from '../DefaultLayout/DefaultContentWrapper';
import DefaultLayout from '../DefaultLayout/DefaultLayout';
import nextBtn from "../../icons/openicon@2x.png";
import Project from './Project';
import { GetThemeColor } from '../Global/Global';
import "./css/featured-projects.css";
import data from '../../data/projects.json';
export default class FeaturedProjects extends Component {
    constructor(props){
        super(props);
        this.state = {
            currentIndex : 0,
            nextIndex : 1,
            currentProject :data[0],
            nextProject : data[1]
        }
    }
    headerTextCl = GetThemeColor().themeTextDarkS1;
    setCurrentProject(index){
        
        var nextIndex = index < data.length - 1 ? index+1 : 0;
        this.setState({
            ...this.state,
            currentIndex : index,
            nextIndex : nextIndex,
            currentProject : data[index],
            nextProject : data[nextIndex]
        }, () => {
            document.querySelector(".df-ct-wrapper").classList.toggle("flash");
            document.querySelector(".hd-container").classList.toggle("flash");
        });
    }
    render() {
        return (
            <DefaultLayout>
                <DefaultHeader
                    title="Portfolio"
                    watermark="curiosity"
                    titleColor={this.headerTextCl}
                    bgColor="transparent">
                </DefaultHeader>
                <DefaultContentWrapper>
                    <div className="projects-container">
                        <div className="pr-bg-left"></div>
                        <div className="pr-bg-right"></div>
                        {this.state.currentProject == null ? "" : 
                        <Project title={this.state.currentProject.title}
                                    website={this.state.currentProject.website}
                                    objectif={this.state.currentProject.objectif}
                                    paragraphs={this.state.currentProject.paragraphs}/>}
                        
                        <div className="pr-navigation-next" onClick={()=>this.setCurrentProject(this.state.nextIndex)}>
                            <div className="pr-nav-next-text"><span>Next project</span></div>
                            <div className="pr-btn-next">
                                <img src={nextBtn}></img>
                            </div>
                        </div>
                        <div className="pr-navigation">
                            {data.map((project, index)=>{
                                return(
                                <div key={"d-" + index} className={"pr-link " + (this.state.currentIndex == index ? "pr-link-active" : "")}
                                onClick={()=> this.setCurrentProject(index)}>
                                    <div className="link-dot"></div>
                                </div>
                                );
                            })}
                        </div>
                    </div>
                </DefaultContentWrapper>

            </DefaultLayout>

        );
    }
}