import React, { Component } from "react";
import "./css/featured-projects.css";

export default class Project extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.addEventListener(
      "scroll",
      () => {
        var offset = window.innerHeight + 60;

        var paragraphs = document.querySelectorAll(".pr-p");
        paragraphs.forEach((item, index) => {
          if (item.getBoundingClientRect().top <= offset) {
            item.classList.add("anim-p");
          }
        });
      },
      false
    );
  }

  render() {
    return (
      <div className="pr-item-container">
        <div className="pr-title">
          <h3>{this.props.objectif}</h3>
          <h1>{this.props.title}</h1>
        </div>
        <div className="pr-content-container">
          {this.props.paragraphs.map((paragraph, index) => {
            return (
              <div
                key={"d-" + paragraph.title}
                className={"pr-p " + (index == 0 || index == 1 ? "anim-p" : "")}
              >
                <div className="pr-p-title">
                  <div className="pr-p-title-trait"></div>
                  <h2>{paragraph.title}</h2>
                </div>
                <p>{paragraph.content}</p>
              </div>
              
            );
          })}
        </div>
        <div className="pr-visual">
          {/* <img src="https://picsum.photos/200"></img> */}
        </div>
        <div className="pr-visit">
          <a href={this.props.website} target="_blank" title="visit">
            <div className="pr-visit-arrow"></div>
          </a>
        </div>
      </div>
    );
  }
}
