import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { GetThemeColor } from '../Global/Global';


export default function BubbleChartBonusSkill(ctx, data, xLabel, yLabel){
    var themeColor = GetThemeColor().themeMainColor;
    var chart = new Chart(ctx, {
        type: 'bubble',
        data: { datasets: data},
        plugins: [ChartDataLabels],
        options: {
            scales: {
                y: {
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 100,
                        display:false
                    }
                },
                x: {
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 100,
                        display:false
                    },
                },
                xAxes: [{
                    gridLines: {
                        color: "rgba(0, 0, 0, 0)",
                    },
                    scaleLabel: {
                        display: true,
                        labelString: xLabel,
                        fontFamily: 'Jost',
                        fontColor : themeColor,
                        fontSize:16,
                    }
                }],
                yAxes: [{
                    gridLines: {
                        color: "rgba(0, 0, 0, 0)",
                    },
                    scaleLabel: {
                        display: true,
                        labelString: yLabel,
                        fontFamily: 'Jost',
                        fontColor : themeColor,
                        fontSize:16,
                    }   
                }]
                
            },
            legend: {
                labels: {
                    fontFamily: 'Jost'
                }
            }
        }
    });

    return chart;
}
