import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import "./css/default-header.css";

import NavigationMain, {AnimateNav} from "../Navigation/NavigationMain";

export default class DefaultHeader extends Component {
    constructor(props){
        super(props);
        this.state = {
            navOpen: false
        }
    }
    componentDidMount(){
        document.querySelectorAll("a").forEach((link) => {
            link.addEventListener("click", ()=> {
                //document.body.classList.remove("modal-open");
                this.openNav(false);
            });
        });
    }
    openNav =  (open) => {
        this.setState({
            ...this.state, 
            navOpen : open
        });
        var navHiddenCl = "nav nav-collapsed";
        var navVisible = "nav nav-visible";
        var navs = document.querySelector(".nav");
        var burgers = document.querySelectorAll(".btn-burger div");
        AnimateNav();
        if(open){
            burgers.forEach((item, index)=> {
                item.classList.add("burger-on");
            });
            navs.className = navVisible;
            document.body.classList.add("modal-open");
        }
        else {
            burgers.forEach((item, index)=> {
                item.classList.remove("burger-on");
            });
            navs.className = navHiddenCl;
            document.body.classList.remove("modal-open");
        }
    }
    render() {
        return (
            <div className="hd-container df-header" style={{backgroundColor: this.props.bgColor}} >
                <div className="hd-title" style={{color: this.props.titleColor}}>
                    <div className="blocktrait"></div>
                    <span id="pageTitle"><a href="#">{this.props.title}</a></span>
                </div>
                <div className="btn-burger" onClick={()=> this.openNav(!this.state.navOpen)}>
                    <div className="burger1"></div>
                    <div className="burger2"></div>
                    <div className="burger3"></div>
                </div>
                <div className="nav nav-collapsed">
                    <NavigationMain></NavigationMain>
                </div>
            </div>
        );
    }
}