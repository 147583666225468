import './App.css';
import { HashRouter, Switch, Route } from 'react-router-dom';
import CoreSkills from './components/CoreSkills/CoreSkills';
import Contact from './components/Contact/Contact';
import FeaturedProjects from './components/FeaturedProjects/FeaturedProjects';
import HomeV2 from './components/HomeV2/HomeV2';
import Portrait from './components/Portrait/Portrait';
// import Home from './components/Home/Home';
function App() {
  return (
    <HashRouter >
      <Switch>
        <Route exact path="/" component={HomeV2} />
        {/* <Route exact path="/" component={Home} /> */}
        <Route exact path="/projects" component={FeaturedProjects} />
        <Route exact path="/skills" component={CoreSkills} />
        <Route exact path="/portrait" component={Portrait} />
        <Route exact path="/contact" component={Contact} />
        {/* <Route exact path="/about" component={About} /> */}
      </Switch>
    </HashRouter>
  );
}

export default App;
