import { Component } from 'react';
import { NavLink } from 'react-router-dom';
// import Portrait from '../Portrait/Portrait';
import './css/homev2.css';
export default class Home extends Component {

    paralax = () => {
        let dde = document.documentElement;
        let ow = dde.offsetWidth;
        let oh = dde.offsetHeight;

        document.querySelector(".homev2-container").addEventListener("mousemove", e => {
            let pic = document.querySelector(".hm-pic");
            pic.style.transform = `translateX(${5 * e.clientX / pic.offsetWidth}%)`;
            pic.style.transform = `translateY(${5 * e.clientY / pic.offsetHeight}%)`;
        });
    }
    animateSvg() {
        var title = document.querySelectorAll("#hm-title path");
        for (let i = 0; i < title.length; i++) {
            var pathLength = title[i].getTotalLength();
            title[i].style
                = `stroke-dashArray : ${pathLength}; stroke-dashOffset : ${pathLength}; animation : brushStroke 3s ease forwards ${i / 10 + 0.3}s;`;
        }
    }
    componentDidMount() {
        var currentdate = new Date();
        document.querySelector(".hm-date").textContent = currentdate.getDate();
        this.animateSvg(true);
        // this.paralax();

    }
    componentWillUnmount() {
        document.querySelector(".homev2-container").removeEventListener("mousemove", e => { });
    }
    render() {
        return (
            <div className="homev2-container">
                <div className="hm-title" >
                <svg id="hm-title" width="666" height="89" viewBox="0 0 666 89" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="0" y="0" width="666" height="89" fill="black">
<rect fill="white" width="666" height="89"/>
<path d="M9.16 15.8C6.76 15.8 4.78667 15.1067 3.24001 13.72C1.74667 12.28 1 10.52 1 8.44001C1 6.30667 1.74667 4.54667 3.24001 3.16C4.78667 1.72 6.76 1 9.16 1C11.5067 1 13.4267 1.72 14.92 3.16C16.4667 4.54667 17.24 6.30667 17.24 8.44001C17.24 10.52 16.4667 12.28 14.92 13.72C13.4267 15.1067 11.5067 15.8 9.16 15.8ZM15.96 20.44V65.08H2.28001V20.44H15.96Z"/>
<path d="M40.1069 42.68C40.1069 38.0933 40.9602 34.0667 42.6669 30.6C44.4269 27.1333 46.8002 24.4667 49.7869 22.6C52.7735 20.7333 56.1069 19.8 59.7869 19.8C62.7202 19.8 65.3869 20.4133 67.7869 21.64C70.2402 22.8667 72.1602 24.52 73.5469 26.6V5.88H87.2269V65.08H73.5469V58.68C72.2669 60.8133 70.4269 62.52 68.0269 63.8C65.6802 65.08 62.9335 65.72 59.7869 65.72C56.1069 65.72 52.7735 64.7867 49.7869 62.92C46.8002 61 44.4269 58.3067 42.6669 54.84C40.9602 51.32 40.1069 47.2667 40.1069 42.68ZM73.5469 42.76C73.5469 39.3467 72.5869 36.6533 70.6669 34.68C68.8002 32.7067 66.5069 31.72 63.7869 31.72C61.0669 31.72 58.7469 32.7067 56.8269 34.68C54.9602 36.6 54.0269 39.2667 54.0269 42.68C54.0269 46.0933 54.9602 48.8133 56.8269 50.84C58.7469 52.8133 61.0669 53.8 63.7869 53.8C66.5069 53.8 68.8002 52.8133 70.6669 50.84C72.5869 48.8667 73.5469 46.1733 73.5469 42.76Z"/>
<path d="M139.204 42.04C139.204 43.32 139.124 44.6533 138.964 46.04H108.004C108.217 48.8133 109.097 50.9467 110.644 52.44C112.244 53.88 114.19 54.6 116.484 54.6C119.897 54.6 122.27 53.16 123.604 50.28H138.164C137.417 53.2133 136.057 55.8533 134.084 58.2C132.164 60.5467 129.737 62.3867 126.804 63.72C123.87 65.0533 120.59 65.72 116.964 65.72C112.59 65.72 108.697 64.7867 105.284 62.92C101.87 61.0533 99.2038 58.3867 97.2838 54.92C95.3638 51.4533 94.4038 47.4 94.4038 42.76C94.4038 38.12 95.3371 34.0667 97.2038 30.6C99.1238 27.1333 101.79 24.4667 105.204 22.6C108.617 20.7333 112.537 19.8 116.964 19.8C121.284 19.8 125.124 20.7067 128.484 22.52C131.844 24.3333 134.457 26.92 136.324 30.28C138.244 33.64 139.204 37.56 139.204 42.04ZM125.204 38.44C125.204 36.0933 124.404 34.2267 122.804 32.84C121.204 31.4533 119.204 30.76 116.804 30.76C114.51 30.76 112.564 31.4267 110.964 32.76C109.417 34.0933 108.457 35.9867 108.084 38.44H125.204Z"/>
<path d="M164.581 65.72C160.687 65.72 157.221 65.0533 154.181 63.72C151.141 62.3867 148.741 60.5733 146.981 58.28C145.221 55.9333 144.234 53.32 144.021 50.44H157.541C157.701 51.9867 158.421 53.24 159.701 54.2C160.981 55.16 162.554 55.64 164.421 55.64C166.127 55.64 167.434 55.32 168.341 54.68C169.301 53.9867 169.781 53.1067 169.781 52.04C169.781 50.76 169.114 49.8267 167.781 49.24C166.447 48.6 164.287 47.9067 161.301 47.16C158.101 46.4133 155.434 45.64 153.301 44.84C151.167 43.9867 149.327 42.68 147.781 40.92C146.234 39.1067 145.461 36.68 145.461 33.64C145.461 31.08 146.154 28.76 147.541 26.68C148.981 24.5467 151.061 22.8667 153.781 21.64C156.554 20.4133 159.834 19.8 163.621 19.8C169.221 19.8 173.621 21.1867 176.821 23.96C180.074 26.7333 181.941 30.4133 182.421 35H169.781C169.567 33.4533 168.874 32.2267 167.701 31.32C166.581 30.4133 165.087 29.96 163.221 29.96C161.621 29.96 160.394 30.28 159.541 30.92C158.687 31.5067 158.261 32.3333 158.261 33.4C158.261 34.68 158.927 35.64 160.261 36.28C161.647 36.92 163.781 37.56 166.661 38.2C169.967 39.0533 172.661 39.9067 174.741 40.76C176.821 41.56 178.634 42.8933 180.181 44.76C181.781 46.5733 182.607 49.0267 182.661 52.12C182.661 54.7333 181.914 57.08 180.421 59.16C178.981 61.1867 176.874 62.7867 174.101 63.96C171.381 65.1333 168.207 65.72 164.581 65.72Z"/>
<path d="M197.91 15.8C195.51 15.8 193.537 15.1067 191.99 13.72C190.497 12.28 189.75 10.52 189.75 8.44001C189.75 6.30667 190.497 4.54667 191.99 3.16C193.537 1.72 195.51 1 197.91 1C200.257 1 202.177 1.72 203.67 3.16C205.217 4.54667 205.99 6.30667 205.99 8.44001C205.99 10.52 205.217 12.28 203.67 13.72C202.177 15.1067 200.257 15.8 197.91 15.8ZM204.71 20.44V65.08H191.03V20.44H204.71Z"/>
<path d="M231.584 19.8C234.73 19.8 237.477 20.44 239.824 21.72C242.224 23 244.064 24.68 245.344 26.76V20.44H259.024V65C259.024 69.1067 258.197 72.8133 256.544 76.12C254.944 79.48 252.464 82.1467 249.104 84.12C245.797 86.0933 241.664 87.08 236.704 87.08C230.09 87.08 224.73 85.5067 220.624 82.36C216.517 79.2667 214.17 75.0533 213.584 69.72H227.104C227.53 71.4267 228.544 72.76 230.144 73.72C231.744 74.7333 233.717 75.24 236.064 75.24C238.89 75.24 241.13 74.4133 242.784 72.76C244.49 71.16 245.344 68.5733 245.344 65V58.68C244.01 60.76 242.17 62.4667 239.824 63.8C237.477 65.08 234.73 65.72 231.584 65.72C227.904 65.72 224.57 64.7867 221.584 62.92C218.597 61 216.224 58.3067 214.464 54.84C212.757 51.32 211.904 47.2667 211.904 42.68C211.904 38.0933 212.757 34.0667 214.464 30.6C216.224 27.1333 218.597 24.4667 221.584 22.6C224.57 20.7333 227.904 19.8 231.584 19.8ZM245.344 42.76C245.344 39.3467 244.384 36.6533 242.464 34.68C240.597 32.7067 238.304 31.72 235.584 31.72C232.864 31.72 230.544 32.7067 228.624 34.68C226.757 36.6 225.824 39.2667 225.824 42.68C225.824 46.0933 226.757 48.8133 228.624 50.84C230.544 52.8133 232.864 53.8 235.584 53.8C238.304 53.8 240.597 52.8133 242.464 50.84C244.384 48.8667 245.344 46.1733 245.344 42.76Z"/>
<path d="M296.121 19.96C301.347 19.96 305.507 21.6667 308.601 25.08C311.747 28.44 313.321 33.08 313.321 39V65.08H299.721V40.84C299.721 37.8533 298.947 35.5333 297.401 33.88C295.854 32.2267 293.774 31.4 291.161 31.4C288.547 31.4 286.467 32.2267 284.921 33.88C283.374 35.5333 282.601 37.8533 282.601 40.84V65.08H268.921V20.44H282.601V26.36C283.987 24.3867 285.854 22.84 288.201 21.72C290.547 20.5467 293.187 19.96 296.121 19.96Z"/>
<path d="M357.94 65.72C354.047 65.72 350.58 65.0533 347.54 63.72C344.5 62.3867 342.1 60.5733 340.34 58.28C338.58 55.9333 337.593 53.32 337.38 50.44H350.9C351.06 51.9867 351.78 53.24 353.06 54.2C354.34 55.16 355.913 55.64 357.78 55.64C359.487 55.64 360.793 55.32 361.7 54.68C362.66 53.9867 363.14 53.1067 363.14 52.04C363.14 50.76 362.473 49.8267 361.14 49.24C359.807 48.6 357.647 47.9067 354.66 47.16C351.46 46.4133 348.793 45.64 346.66 44.84C344.527 43.9867 342.687 42.68 341.14 40.92C339.593 39.1067 338.82 36.68 338.82 33.64C338.82 31.08 339.513 28.76 340.9 26.68C342.34 24.5467 344.42 22.8667 347.14 21.64C349.913 20.4133 353.193 19.8 356.98 19.8C362.58 19.8 366.98 21.1867 370.18 23.96C373.433 26.7333 375.3 30.4133 375.78 35H363.14C362.927 33.4533 362.233 32.2267 361.06 31.32C359.94 30.4133 358.447 29.96 356.58 29.96C354.98 29.96 353.753 30.28 352.9 30.92C352.047 31.5067 351.62 32.3333 351.62 33.4C351.62 34.68 352.287 35.64 353.62 36.28C355.007 36.92 357.14 37.56 360.02 38.2C363.327 39.0533 366.02 39.9067 368.1 40.76C370.18 41.56 371.993 42.8933 373.54 44.76C375.14 46.5733 375.967 49.0267 376.02 52.12C376.02 54.7333 375.273 57.08 373.78 59.16C372.34 61.1867 370.233 62.7867 367.46 63.96C364.74 65.1333 361.567 65.72 357.94 65.72Z"/>
<path d="M404.709 65.72C400.336 65.72 396.389 64.7867 392.869 62.92C389.403 61.0533 386.656 58.3867 384.629 54.92C382.656 51.4533 381.669 47.4 381.669 42.76C381.669 38.1733 382.683 34.1467 384.709 30.68C386.736 27.16 389.509 24.4667 393.029 22.6C396.549 20.7333 400.496 19.8 404.869 19.8C409.243 19.8 413.189 20.7333 416.709 22.6C420.229 24.4667 423.003 27.16 425.029 30.68C427.056 34.1467 428.069 38.1733 428.069 42.76C428.069 47.3467 427.029 51.4 424.949 54.92C422.923 58.3867 420.123 61.0533 416.549 62.92C413.029 64.7867 409.083 65.72 404.709 65.72ZM404.709 53.88C407.323 53.88 409.536 52.92 411.349 51C413.216 49.08 414.149 46.3333 414.149 42.76C414.149 39.1867 413.243 36.44 411.429 34.52C409.669 32.6 407.483 31.64 404.869 31.64C402.203 31.64 399.989 32.6 398.229 34.52C396.469 36.3867 395.589 39.1333 395.589 42.76C395.589 46.3333 396.443 49.08 398.149 51C399.909 52.92 402.096 53.88 404.709 53.88Z"/>
<path d="M449.007 5.88V65.08H435.327V5.88H449.007Z"/>
<path d="M503.001 20.44V65.08H489.321V59C487.934 60.9733 486.041 62.5733 483.641 63.8C481.294 64.9733 478.681 65.56 475.801 65.56C472.387 65.56 469.374 64.8133 466.761 63.32C464.147 61.7733 462.121 59.56 460.681 56.68C459.241 53.8 458.521 50.4133 458.521 46.52V20.44H472.121V44.68C472.121 47.6667 472.894 49.9867 474.441 51.64C475.987 53.2933 478.067 54.12 480.681 54.12C483.347 54.12 485.454 53.2933 487.001 51.64C488.547 49.9867 489.321 47.6667 489.321 44.68V20.44H503.001Z"/>
<path d="M537.707 53.48V65.08H530.747C525.787 65.08 521.92 63.88 519.147 61.48C516.374 59.0267 514.987 55.0533 514.987 49.56V31.8H509.547V20.44H514.987V9.56001H528.667V20.44H537.627V31.8H528.667V49.72C528.667 51.0533 528.987 52.0133 529.627 52.6C530.267 53.1867 531.334 53.48 532.827 53.48H537.707Z"/>
<path d="M552.207 15.8C549.807 15.8 547.834 15.1067 546.287 13.72C544.794 12.28 544.047 10.52 544.047 8.44001C544.047 6.30667 544.794 4.54667 546.287 3.16C547.834 1.72 549.807 1 552.207 1C554.554 1 556.474 1.72 557.967 3.16C559.514 4.54667 560.287 6.30667 560.287 8.44001C560.287 10.52 559.514 12.28 557.967 13.72C556.474 15.1067 554.554 15.8 552.207 15.8ZM559.007 20.44V65.08H545.327V20.44H559.007Z"/>
<path d="M589.241 65.72C584.867 65.72 580.921 64.7867 577.401 62.92C573.934 61.0533 571.187 58.3867 569.161 54.92C567.187 51.4533 566.201 47.4 566.201 42.76C566.201 38.1733 567.214 34.1467 569.241 30.68C571.267 27.16 574.041 24.4667 577.561 22.6C581.081 20.7333 585.027 19.8 589.401 19.8C593.774 19.8 597.721 20.7333 601.241 22.6C604.761 24.4667 607.534 27.16 609.561 30.68C611.587 34.1467 612.601 38.1733 612.601 42.76C612.601 47.3467 611.561 51.4 609.481 54.92C607.454 58.3867 604.654 61.0533 601.081 62.92C597.561 64.7867 593.614 65.72 589.241 65.72ZM589.241 53.88C591.854 53.88 594.067 52.92 595.881 51C597.747 49.08 598.681 46.3333 598.681 42.76C598.681 39.1867 597.774 36.44 595.961 34.52C594.201 32.6 592.014 31.64 589.401 31.64C586.734 31.64 584.521 32.6 582.761 34.52C581.001 36.3867 580.121 39.1333 580.121 42.76C580.121 46.3333 580.974 49.08 582.681 51C584.441 52.92 586.627 53.88 589.241 53.88Z"/>
<path d="M647.058 19.96C652.285 19.96 656.445 21.6667 659.538 25.08C662.685 28.44 664.258 33.08 664.258 39V65.08H650.658V40.84C650.658 37.8533 649.885 35.5333 648.338 33.88C646.791 32.2267 644.711 31.4 642.098 31.4C639.485 31.4 637.405 32.2267 635.858 33.88C634.311 35.5333 633.538 37.8533 633.538 40.84V65.08H619.858V20.44H633.538V26.36C634.925 24.3867 636.791 22.84 639.138 21.72C641.485 20.5467 644.125 19.96 647.058 19.96Z"/>
</mask>
<path d="M9.16 15.8C6.76 15.8 4.78667 15.1067 3.24001 13.72C1.74667 12.28 1 10.52 1 8.44001C1 6.30667 1.74667 4.54667 3.24001 3.16C4.78667 1.72 6.76 1 9.16 1C11.5067 1 13.4267 1.72 14.92 3.16C16.4667 4.54667 17.24 6.30667 17.24 8.44001C17.24 10.52 16.4667 12.28 14.92 13.72C13.4267 15.1067 11.5067 15.8 9.16 15.8ZM15.96 20.44V65.08H2.28001V20.44H15.96Z" stroke="black" stroke-opacity="0.56" stroke-width="2" mask="url(#path-1-outside-1)"/>
<path d="M40.1069 42.68C40.1069 38.0933 40.9602 34.0667 42.6669 30.6C44.4269 27.1333 46.8002 24.4667 49.7869 22.6C52.7735 20.7333 56.1069 19.8 59.7869 19.8C62.7202 19.8 65.3869 20.4133 67.7869 21.64C70.2402 22.8667 72.1602 24.52 73.5469 26.6V5.88H87.2269V65.08H73.5469V58.68C72.2669 60.8133 70.4269 62.52 68.0269 63.8C65.6802 65.08 62.9335 65.72 59.7869 65.72C56.1069 65.72 52.7735 64.7867 49.7869 62.92C46.8002 61 44.4269 58.3067 42.6669 54.84C40.9602 51.32 40.1069 47.2667 40.1069 42.68ZM73.5469 42.76C73.5469 39.3467 72.5869 36.6533 70.6669 34.68C68.8002 32.7067 66.5069 31.72 63.7869 31.72C61.0669 31.72 58.7469 32.7067 56.8269 34.68C54.9602 36.6 54.0269 39.2667 54.0269 42.68C54.0269 46.0933 54.9602 48.8133 56.8269 50.84C58.7469 52.8133 61.0669 53.8 63.7869 53.8C66.5069 53.8 68.8002 52.8133 70.6669 50.84C72.5869 48.8667 73.5469 46.1733 73.5469 42.76Z" stroke="black" stroke-opacity="0.56" stroke-width="2" mask="url(#path-1-outside-1)"/>
<path d="M139.204 42.04C139.204 43.32 139.124 44.6533 138.964 46.04H108.004C108.217 48.8133 109.097 50.9467 110.644 52.44C112.244 53.88 114.19 54.6 116.484 54.6C119.897 54.6 122.27 53.16 123.604 50.28H138.164C137.417 53.2133 136.057 55.8533 134.084 58.2C132.164 60.5467 129.737 62.3867 126.804 63.72C123.87 65.0533 120.59 65.72 116.964 65.72C112.59 65.72 108.697 64.7867 105.284 62.92C101.87 61.0533 99.2038 58.3867 97.2838 54.92C95.3638 51.4533 94.4038 47.4 94.4038 42.76C94.4038 38.12 95.3371 34.0667 97.2038 30.6C99.1238 27.1333 101.79 24.4667 105.204 22.6C108.617 20.7333 112.537 19.8 116.964 19.8C121.284 19.8 125.124 20.7067 128.484 22.52C131.844 24.3333 134.457 26.92 136.324 30.28C138.244 33.64 139.204 37.56 139.204 42.04ZM125.204 38.44C125.204 36.0933 124.404 34.2267 122.804 32.84C121.204 31.4533 119.204 30.76 116.804 30.76C114.51 30.76 112.564 31.4267 110.964 32.76C109.417 34.0933 108.457 35.9867 108.084 38.44H125.204Z" stroke="black" stroke-opacity="0.56" stroke-width="2" mask="url(#path-1-outside-1)"/>
<path d="M164.581 65.72C160.687 65.72 157.221 65.0533 154.181 63.72C151.141 62.3867 148.741 60.5733 146.981 58.28C145.221 55.9333 144.234 53.32 144.021 50.44H157.541C157.701 51.9867 158.421 53.24 159.701 54.2C160.981 55.16 162.554 55.64 164.421 55.64C166.127 55.64 167.434 55.32 168.341 54.68C169.301 53.9867 169.781 53.1067 169.781 52.04C169.781 50.76 169.114 49.8267 167.781 49.24C166.447 48.6 164.287 47.9067 161.301 47.16C158.101 46.4133 155.434 45.64 153.301 44.84C151.167 43.9867 149.327 42.68 147.781 40.92C146.234 39.1067 145.461 36.68 145.461 33.64C145.461 31.08 146.154 28.76 147.541 26.68C148.981 24.5467 151.061 22.8667 153.781 21.64C156.554 20.4133 159.834 19.8 163.621 19.8C169.221 19.8 173.621 21.1867 176.821 23.96C180.074 26.7333 181.941 30.4133 182.421 35H169.781C169.567 33.4533 168.874 32.2267 167.701 31.32C166.581 30.4133 165.087 29.96 163.221 29.96C161.621 29.96 160.394 30.28 159.541 30.92C158.687 31.5067 158.261 32.3333 158.261 33.4C158.261 34.68 158.927 35.64 160.261 36.28C161.647 36.92 163.781 37.56 166.661 38.2C169.967 39.0533 172.661 39.9067 174.741 40.76C176.821 41.56 178.634 42.8933 180.181 44.76C181.781 46.5733 182.607 49.0267 182.661 52.12C182.661 54.7333 181.914 57.08 180.421 59.16C178.981 61.1867 176.874 62.7867 174.101 63.96C171.381 65.1333 168.207 65.72 164.581 65.72Z" stroke="black" stroke-opacity="0.56" stroke-width="2" mask="url(#path-1-outside-1)"/>
<path d="M197.91 15.8C195.51 15.8 193.537 15.1067 191.99 13.72C190.497 12.28 189.75 10.52 189.75 8.44001C189.75 6.30667 190.497 4.54667 191.99 3.16C193.537 1.72 195.51 1 197.91 1C200.257 1 202.177 1.72 203.67 3.16C205.217 4.54667 205.99 6.30667 205.99 8.44001C205.99 10.52 205.217 12.28 203.67 13.72C202.177 15.1067 200.257 15.8 197.91 15.8ZM204.71 20.44V65.08H191.03V20.44H204.71Z" stroke="black" stroke-opacity="0.56" stroke-width="2" mask="url(#path-1-outside-1)"/>
<path d="M231.584 19.8C234.73 19.8 237.477 20.44 239.824 21.72C242.224 23 244.064 24.68 245.344 26.76V20.44H259.024V65C259.024 69.1067 258.197 72.8133 256.544 76.12C254.944 79.48 252.464 82.1467 249.104 84.12C245.797 86.0933 241.664 87.08 236.704 87.08C230.09 87.08 224.73 85.5067 220.624 82.36C216.517 79.2667 214.17 75.0533 213.584 69.72H227.104C227.53 71.4267 228.544 72.76 230.144 73.72C231.744 74.7333 233.717 75.24 236.064 75.24C238.89 75.24 241.13 74.4133 242.784 72.76C244.49 71.16 245.344 68.5733 245.344 65V58.68C244.01 60.76 242.17 62.4667 239.824 63.8C237.477 65.08 234.73 65.72 231.584 65.72C227.904 65.72 224.57 64.7867 221.584 62.92C218.597 61 216.224 58.3067 214.464 54.84C212.757 51.32 211.904 47.2667 211.904 42.68C211.904 38.0933 212.757 34.0667 214.464 30.6C216.224 27.1333 218.597 24.4667 221.584 22.6C224.57 20.7333 227.904 19.8 231.584 19.8ZM245.344 42.76C245.344 39.3467 244.384 36.6533 242.464 34.68C240.597 32.7067 238.304 31.72 235.584 31.72C232.864 31.72 230.544 32.7067 228.624 34.68C226.757 36.6 225.824 39.2667 225.824 42.68C225.824 46.0933 226.757 48.8133 228.624 50.84C230.544 52.8133 232.864 53.8 235.584 53.8C238.304 53.8 240.597 52.8133 242.464 50.84C244.384 48.8667 245.344 46.1733 245.344 42.76Z" stroke="black" stroke-opacity="0.56" stroke-width="2" mask="url(#path-1-outside-1)"/>
<path d="M296.121 19.96C301.347 19.96 305.507 21.6667 308.601 25.08C311.747 28.44 313.321 33.08 313.321 39V65.08H299.721V40.84C299.721 37.8533 298.947 35.5333 297.401 33.88C295.854 32.2267 293.774 31.4 291.161 31.4C288.547 31.4 286.467 32.2267 284.921 33.88C283.374 35.5333 282.601 37.8533 282.601 40.84V65.08H268.921V20.44H282.601V26.36C283.987 24.3867 285.854 22.84 288.201 21.72C290.547 20.5467 293.187 19.96 296.121 19.96Z" stroke="black" stroke-opacity="0.56" stroke-width="2" mask="url(#path-1-outside-1)"/>
<path d="M357.94 65.72C354.047 65.72 350.58 65.0533 347.54 63.72C344.5 62.3867 342.1 60.5733 340.34 58.28C338.58 55.9333 337.593 53.32 337.38 50.44H350.9C351.06 51.9867 351.78 53.24 353.06 54.2C354.34 55.16 355.913 55.64 357.78 55.64C359.487 55.64 360.793 55.32 361.7 54.68C362.66 53.9867 363.14 53.1067 363.14 52.04C363.14 50.76 362.473 49.8267 361.14 49.24C359.807 48.6 357.647 47.9067 354.66 47.16C351.46 46.4133 348.793 45.64 346.66 44.84C344.527 43.9867 342.687 42.68 341.14 40.92C339.593 39.1067 338.82 36.68 338.82 33.64C338.82 31.08 339.513 28.76 340.9 26.68C342.34 24.5467 344.42 22.8667 347.14 21.64C349.913 20.4133 353.193 19.8 356.98 19.8C362.58 19.8 366.98 21.1867 370.18 23.96C373.433 26.7333 375.3 30.4133 375.78 35H363.14C362.927 33.4533 362.233 32.2267 361.06 31.32C359.94 30.4133 358.447 29.96 356.58 29.96C354.98 29.96 353.753 30.28 352.9 30.92C352.047 31.5067 351.62 32.3333 351.62 33.4C351.62 34.68 352.287 35.64 353.62 36.28C355.007 36.92 357.14 37.56 360.02 38.2C363.327 39.0533 366.02 39.9067 368.1 40.76C370.18 41.56 371.993 42.8933 373.54 44.76C375.14 46.5733 375.967 49.0267 376.02 52.12C376.02 54.7333 375.273 57.08 373.78 59.16C372.34 61.1867 370.233 62.7867 367.46 63.96C364.74 65.1333 361.567 65.72 357.94 65.72Z" stroke="black" stroke-opacity="0.56" stroke-width="2" mask="url(#path-1-outside-1)"/>
<path d="M404.709 65.72C400.336 65.72 396.389 64.7867 392.869 62.92C389.403 61.0533 386.656 58.3867 384.629 54.92C382.656 51.4533 381.669 47.4 381.669 42.76C381.669 38.1733 382.683 34.1467 384.709 30.68C386.736 27.16 389.509 24.4667 393.029 22.6C396.549 20.7333 400.496 19.8 404.869 19.8C409.243 19.8 413.189 20.7333 416.709 22.6C420.229 24.4667 423.003 27.16 425.029 30.68C427.056 34.1467 428.069 38.1733 428.069 42.76C428.069 47.3467 427.029 51.4 424.949 54.92C422.923 58.3867 420.123 61.0533 416.549 62.92C413.029 64.7867 409.083 65.72 404.709 65.72ZM404.709 53.88C407.323 53.88 409.536 52.92 411.349 51C413.216 49.08 414.149 46.3333 414.149 42.76C414.149 39.1867 413.243 36.44 411.429 34.52C409.669 32.6 407.483 31.64 404.869 31.64C402.203 31.64 399.989 32.6 398.229 34.52C396.469 36.3867 395.589 39.1333 395.589 42.76C395.589 46.3333 396.443 49.08 398.149 51C399.909 52.92 402.096 53.88 404.709 53.88Z" stroke="black" stroke-opacity="0.56" stroke-width="2" mask="url(#path-1-outside-1)"/>
<path d="M449.007 5.88V65.08H435.327V5.88H449.007Z" stroke="black" stroke-opacity="0.56" stroke-width="2" mask="url(#path-1-outside-1)"/>
<path d="M503.001 20.44V65.08H489.321V59C487.934 60.9733 486.041 62.5733 483.641 63.8C481.294 64.9733 478.681 65.56 475.801 65.56C472.387 65.56 469.374 64.8133 466.761 63.32C464.147 61.7733 462.121 59.56 460.681 56.68C459.241 53.8 458.521 50.4133 458.521 46.52V20.44H472.121V44.68C472.121 47.6667 472.894 49.9867 474.441 51.64C475.987 53.2933 478.067 54.12 480.681 54.12C483.347 54.12 485.454 53.2933 487.001 51.64C488.547 49.9867 489.321 47.6667 489.321 44.68V20.44H503.001Z" stroke="black" stroke-opacity="0.56" stroke-width="2" mask="url(#path-1-outside-1)"/>
<path d="M537.707 53.48V65.08H530.747C525.787 65.08 521.92 63.88 519.147 61.48C516.374 59.0267 514.987 55.0533 514.987 49.56V31.8H509.547V20.44H514.987V9.56001H528.667V20.44H537.627V31.8H528.667V49.72C528.667 51.0533 528.987 52.0133 529.627 52.6C530.267 53.1867 531.334 53.48 532.827 53.48H537.707Z" stroke="black" stroke-opacity="0.56" stroke-width="2" mask="url(#path-1-outside-1)"/>
<path d="M552.207 15.8C549.807 15.8 547.834 15.1067 546.287 13.72C544.794 12.28 544.047 10.52 544.047 8.44001C544.047 6.30667 544.794 4.54667 546.287 3.16C547.834 1.72 549.807 1 552.207 1C554.554 1 556.474 1.72 557.967 3.16C559.514 4.54667 560.287 6.30667 560.287 8.44001C560.287 10.52 559.514 12.28 557.967 13.72C556.474 15.1067 554.554 15.8 552.207 15.8ZM559.007 20.44V65.08H545.327V20.44H559.007Z" stroke="black" stroke-opacity="0.56" stroke-width="2" mask="url(#path-1-outside-1)"/>
<path d="M589.241 65.72C584.867 65.72 580.921 64.7867 577.401 62.92C573.934 61.0533 571.187 58.3867 569.161 54.92C567.187 51.4533 566.201 47.4 566.201 42.76C566.201 38.1733 567.214 34.1467 569.241 30.68C571.267 27.16 574.041 24.4667 577.561 22.6C581.081 20.7333 585.027 19.8 589.401 19.8C593.774 19.8 597.721 20.7333 601.241 22.6C604.761 24.4667 607.534 27.16 609.561 30.68C611.587 34.1467 612.601 38.1733 612.601 42.76C612.601 47.3467 611.561 51.4 609.481 54.92C607.454 58.3867 604.654 61.0533 601.081 62.92C597.561 64.7867 593.614 65.72 589.241 65.72ZM589.241 53.88C591.854 53.88 594.067 52.92 595.881 51C597.747 49.08 598.681 46.3333 598.681 42.76C598.681 39.1867 597.774 36.44 595.961 34.52C594.201 32.6 592.014 31.64 589.401 31.64C586.734 31.64 584.521 32.6 582.761 34.52C581.001 36.3867 580.121 39.1333 580.121 42.76C580.121 46.3333 580.974 49.08 582.681 51C584.441 52.92 586.627 53.88 589.241 53.88Z" stroke="black" stroke-opacity="0.56" stroke-width="2" mask="url(#path-1-outside-1)"/>
<path d="M647.058 19.96C652.285 19.96 656.445 21.6667 659.538 25.08C662.685 28.44 664.258 33.08 664.258 39V65.08H650.658V40.84C650.658 37.8533 649.885 35.5333 648.338 33.88C646.791 32.2267 644.711 31.4 642.098 31.4C639.485 31.4 637.405 32.2267 635.858 33.88C634.311 35.5333 633.538 37.8533 633.538 40.84V65.08H619.858V20.44H633.538V26.36C634.925 24.3867 636.791 22.84 639.138 21.72C641.485 20.5467 644.125 19.96 647.058 19.96Z" stroke="black" stroke-opacity="0.56" stroke-width="2" mask="url(#path-1-outside-1)"/>
</svg>

                </div>
                <div className="hm-name">huyen pham</div>
                <div className="hm-job">software engineer</div>
                <div className="hm-pic">
                    {/* <Portrait></Portrait> */}
                </div>
                
                {/* <div className="hm-rect-1"></div> */}
                <div className="hm-hline-1"></div>
                <div className="hm-hline-2"></div>
                <div className="hm-vline-3"></div>
                <div className="hm-vline-4"></div>
                <div className="hm-biography">
                    Coding is easy. Engineering is hard.
                    Building a solution is fairly easy. 
                    Crafting a solution that is rock solid but can also be gracefully demolished
                    is freaking art.
                </div>
                
                <NavLink to="/contact">
                    <div className="hm-calendar">
                        <div className="hm-book">Book me!</div>
                        <div className="hm-date"></div>
                    </div>

                </NavLink>
                <NavLink to="/projects">
                    <div className="hm-cta">
                        Discover
                </div>
                </NavLink>


            </div>
        );
    }
}