import { Component } from "react";
export default class DefaultContentWrapper extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="df-ct-wrapper">
                {this.props.children}
            </div>
        );
    }
}