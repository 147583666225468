import { Component } from "react";
import { NavLink } from 'react-router-dom';
import "./css/navigation.css";
export default class NavItem extends Component {
    constructor(props){
        super(props);
    }
    render(){
        return(
            <div className="nav-item-container">
                            <div className="nav-sm-title animate">
                                <div className="sm-trait"></div>
                                <div className="sm-title">
                                    {this.props.smTitle}
                                </div>
                            </div>
                            <div className="nav-main-title animate">
                                <div className="main-trait"></div>
                            
                                <div className="main-title">
                                    <NavLink to={this.props.linkPath} exact activeClassName="nav-link-active">
                                    {this.props.mainTitle}
                                    </NavLink>
                                </div>
                            
                            </div>
                        </div>
        );
    }
}