import { useState, useEffect } from "react";

export function useMousePosition() {

    const [mousePosition, setMousePosition] = useState({ x: null, y: null });

    const updateMousePosition = ev => {
        setMousePosition({ x: ev.clientX, y: ev.clientY });
    };

    useEffect(() => {
        window.addEventListener("mousemove", updateMousePosition);

        return () => window.removeEventListener("mousemove", updateMousePosition);
    }, []);

    return mousePosition;
};

export function GetThemeColor() {
    var themeMain = getComputedStyle(document.documentElement).getPropertyValue('--themeColor');
    var themeColor2 = getComputedStyle(document.documentElement).getPropertyValue('--themeColor2'); 
    var themeTextLight = getComputedStyle(document.documentElement).getPropertyValue('--themeTextLight'); 
    var themeTextDark = getComputedStyle(document.documentElement).getPropertyValue('--themeTextDark'); 
    var themeTextDarkS1 = getComputedStyle(document.documentElement).getPropertyValue('--themeTextDarkS1'); 
    var themeTextDarkS2 = getComputedStyle(document.documentElement).getPropertyValue('--themeTextDarkS2'); 
    
    return {
        themeMainColor : themeMain,
        themeColor2 : themeColor2,
        themeTextLight : themeTextLight,
        themeTextDark : themeTextDark,
        themeTextDarkS1 : themeTextDarkS1,
        themeTextDarkS2 : themeTextDarkS2
    }
}

