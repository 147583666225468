import { Component } from 'react';
import './css/portrait.css';

export default class Portrait extends Component {

    componentDidMount() {
        var title = document.querySelectorAll("#mePortrait path");
        for (let i = 0; i < title.length; i++) {
            var pathLength = title[i].getTotalLength();
            title[i].style
                = `stroke-dashArray : ${pathLength}; stroke-dashOffset : ${pathLength}; animation : brushStroke 2s ease forwards ${i / 10}s;`;
        }
    }
    render() {
        return (
            <div className="portrait-container">
<svg id="mePortrait" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 671.34 681.77">
  <title>Untitled-1</title>
  <path d="M1230.28,976.61c-75.09,5.93-175.15,2.45-72.79-9.42-48.34,6-81.29-17.2-4.92-14.2C1319.55,962.76,1074.17,968.79,1230.28,976.61Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M633.65,820.32c-9-.09-21.14,5.5-30.72,1V795.71c18.75-2.41,59.31-7.46,62.14-3.39C660.14,817.32,672.6,815.89,633.65,820.32Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="27.42 379.17 58.69 392.03 101.03 392.72 27.42 399.03 27.42 379.17 27.42 379.17" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M1230.28,873.61c-20.17,39.85,1.57,53,0,83.65C1205.57,920.07,1193.38,894.05,1230.28,873.61Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1210.92,893.47c-9,14.84-12.58,14.45-6.49,18.89C1277.72,1012.53,1155.74,915.34,1210.92,893.47Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <polygon points="36.77 325.18 38.75 343.99 35.95 355.81 36.77 325.18 36.77 325.18" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <path d="M1230.28,976.61c-41.18,5.93-85.83-21-38.71-5.08C1187.24,969.65,1228.4,955.56,1230.28,976.61Z" transform="translate(-581.84 -313.76)" fill="#b1abaa" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1093.21,974.08c.07-.47.15-1,.23-1.43-.2.13-.39.27-.6.39a4.9,4.9,0,0,1-4.83.06c-1.39,1.92-2.77,3.84-4.29,5.6h11.08A5.2,5.2,0,0,1,1093.21,974.08Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M1109.92,937.28c-1.79,4.88-1.14,9.45-5,9.34,2.85,4,3,3.15,3.12,4.11a26.56,26.56,0,0,0-5.8-1.54,5.49,5.49,0,0,1,.06,1.35c6,1.32,3,4.17-1.65,7a5.73,5.73,0,0,1,2.76,3.18c1.68,5.28.25,10.7-.52,16a4.6,4.6,0,0,1-.71,2h5.29c-.08-2.46-.15-4.91-.22-7.37a45,45,0,0,1,3.16-17.84C1114.47,947.83,1114.39,939.59,1109.92,937.28Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M1226.44,779.16c-2.35,28.77-2.47-4.88-6.61,8.31C1175.34,747.17,1244.27,863.74,1226.44,779.16Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1230.28,838.06c.58.37-5.07,29.7-12.75,34.66C1185,863.73,1201.42,849.68,1230.28,838.06Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <polygon points="21.09 474.63 26.6 472.12 28.96 478.25 85.94 471.51 86.34 470.69 90.67 469.53 91.38 469.6 115.9 468.07 125.83 453.26 115.96 473.87 21.09 481.95 21.09 474.63 21.09 474.63" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="21.09 413.46 97.53 411.42 21.09 416.6 21.09 413.46 21.09 413.46" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M1230.28,932.17c-17.62-14.46-12.35-39,0-52.29Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1230.28,973.47c-6.59-15.78-65.47-58.26-29.28-70.58C1177.12,925.6,1231.84,945.27,1230.28,973.47Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <polygon points="21.09 416.6 96.09 419.68 21.09 423.92 21.09 416.6 21.09 416.6" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M1221.31,827.66c3.61-10.22-50.66,29.79-30.48,19.42C1199.5,846.45,1248.17,824.56,1221.31,827.66Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <polygon points="21.09 423.92 95.94 422.37 21.09 427.57 21.09 423.92 21.09 423.92" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M602.93,875.18l5.27-1,.27,1.64,2.64-.14c5.84,2.16,2.34,13.53,5.15,21.53-2-1.58-3.67-.44-5.72,9.61-1-3.26-1.95-7.77-7.61-9.16v-1c5.67-.91,1.1-7.57,0-12.55Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.56"/>
  <path d="M602.93,756.49v-5.22C660,743,676.09,758.62,602.93,756.49Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <path d="M624.9,678.47c2.24,1.62,9.42,1.48,12,5.35-9.85,2.43-21.75-1.39-34-1.05V672.32C608.92,671.43,618.15,674.78,624.9,678.47Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.6"/>
  <polygon points="40.39 436.03 21.09 435.42 21.09 433.33 102.09 433.85 40.39 436.03 40.39 436.03" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="28.95 432.34 21.09 430.71 21.09 427.57 96.97 427.04 92.73 433.16 28.95 432.34 28.95 432.34" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="35.73 330.33 21.09 325.11 35.73 330.33 35.73 330.33" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <path d="M1220.35,872.56c3.86-5.44,4.54-8.5-2.39-13.79C1225.19,833.54,1243.46,868.5,1220.35,872.56Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M602.93,831.78c2,.4,6.52-2,10.29-.13a38.15,38.15,0,0,0-2.81,3.19,52.93,52.93,0,0,0,6.75-.65c-1.26,3.22-1,.94-2.95,9.2-.83-.35.64-.26-3.07-1.93-.28,1.45-.63,3.21-1.4,7.43C605.61,845.2,602.43,839.81,602.93,831.78Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.56"/>
  <path d="M1210.91,839.08c3.31-10,34.24-9.4,11.79,1.12C1221.83,836.66,1217.84,849,1210.91,839.08Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <polygon points="21.09 341.31 21.09 348.11 21.09 341.31 21.09 341.31" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <path d="M624.9,678.47l-22-6.15v-4.71C608.33,671,619.05,672.61,624.9,678.47Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.6"/>
  <path d="M1230.28,827.6c-3,4.39-22.07-2.55-6.15-8.38C1224.43,826.12,1225.89,819.36,1230.28,827.6Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M616,643c-3.95-1.35-6-3-13.09-4.16v-1.05l14.69.7c.8,1.67-.54,3.09-.53,4.67A.88.88,0,0,1,616,643Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M1230.28,823.42c4.26-2.71-14.21,7.75-4.09-4.07C1227.43,818.6,1232.19,812.41,1230.28,823.42Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M602.93,884.06l4.41,12.59-4.41,0Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.56"/>
  <path d="M602.93,749.17l18.3.59c-4.44,2.72-11.32,1.08-18.3,1.51Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <path d="M1230.28,816.1c-2.64-4.17-1.69-2.81,0,0C1231.31,821.8,1218.18,813.14,1230.28,816.1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1230.28,813.48l-6.47-3.07C1223.76,813.41,1230.16,800.25,1230.28,813.48Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M602.93,934.26c-.49,1.23,4.21,5.48,0,7.84Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.56"/>
  <path d="M603.94,761.67l-1,.05v-5.23l2.16.22C606.39,758.84,606.23,759.54,603.94,761.67Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <path d="M602.93,744.47c.4.33,3.57.61,5.29,2.16l-5.29.45Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <path d="M1229.23,870.46c1.41.42,1.06-.37,1,3.15C1229.82,873.07,1225.3,872.64,1229.23,870.46Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1230.28,777.41l-1.8-.57,1.8-1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1230.28,955.69l-.77-.52C1229.83,955.11,1230.55,954,1230.28,955.69Z" transform="translate(-581.84 -313.76)" fill="#b39791" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M797.74,443.94a10,10,0,0,0,.71-4.45c-6,9.37-4.21,11.62-.3,9.67A5,5,0,0,1,797.74,443.94Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="18" opacity="0.17"/>
  <path d="M797.94,433.15c-.56,1.56,1.28-16.54-2.11-8C795.2,429.73,788.58,435.44,797.94,433.15Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.17"/>
  <path d="M881.2,375.64c-27.41-2.17-60.25,46-73,53.43C796.67,475.73,862.86,377.94,881.2,375.64Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.17"/>
  <path d="M809.85,397.81h0C808.3,397.74,814.73,409.36,809.85,397.81Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.17"/>
  <path d="M799.78,402.54c-2.29-1.3-3.13,19.3-1,9.41Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.17"/>
  <path d="M953.06,432.89c-92.25-155.46-167.12-50.76-144.13-47.37,10.05-45.46,95.51-25.16,81.78,3.92,41.67,19.68,4.22,2.16,21.67,40.65,2,20.69,1.4-3,7.56-9.9C932.15,424.7,932.63,446.78,953.06,432.89Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.17"/>
  <path d="M797.91,420.85c-.29,1.27,1.25-9.25-.06-2.5C798.52,417.19,796.44,420.91,797.91,420.85Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.17"/>
  <path d="M884.62,375.7c5.5,3.07-8.43-17.53-3.11-4.79C882.11,369.29,880.4,374.81,884.62,375.7Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.17"/>
  <path d="M979.3,600.54a4.73,4.73,0,0,1-.16-1.28l-.85-1.27a9,9,0,0,1,.89.52,152.77,152.77,0,0,1,3.22-6.39c-.44-.07-.45.08-1-.23-5.49-1.79-5.48-2.57-6.49-2.62-4.44-11.09-4.73-10.17-7.54-3.22-1.48-14.84.64-29.68-3.21-44.07-16.53-40.66-14.84-37.16-18.12-1.75-.71,9.09,2.28,5.7,4.38,2.86,16.31,28.17,4.93,70.42,15.46,56.73,7.36,10,.87-10.39,10.5,1.35,2.24,3.23,4.12,3.15,4.84,2.08A5.07,5.07,0,0,1,979.3,600.54Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="8" opacity="0.41"/>
  <polygon points="487.68 322.38 490.81 324.23 491.54 323.58 487.68 322.38 487.68 322.38" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.11"/>
  <polygon points="542.7 331.66 497.4 289.89 483.5 310.83 486 315.67 493.87 304.54 505.12 317.45 505.76 316.34 522.84 321.2 521.38 323.29 543.98 333.35 542.7 331.66 542.7 331.66" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.11"/>
  <path d="M840.29,336.55c-41.17,3.21-74.63,82.57-81.64,102.92C780.61,404.31,800.5,360.08,840.29,336.55Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.54"/>
  <polygon points="111.05 320.07 94.31 326.63 111.05 320.07 111.05 320.07" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <path d="M979.23,512c-.08,8.15,2.24,17.79,1.68,27.17-2.38-1.79-26.69-65.92-10.17-35.12C938.24,420.89,966,453.39,979.23,512Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.47"/>
  <path d="M763.92,487.6l-1.51,2.27a5,5,0,0,1-3.08,2.12,4.93,4.93,0,0,1,3.41,2.33,4.58,4.58,0,0,1,.87,1.44c1.39-1.9,5.45-5.43,15.24-12.31C777.15,482,770,484.65,763.92,487.6Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.36"/>
  <path d="M740.32,509.08c.17-2.58.44-5.15.83-7.69a11.12,11.12,0,0,0-1.69,5.22C739.28,508.72,739.68,509.26,740.32,509.08Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.36"/>
  <path d="M763.82,498a4.69,4.69,0,0,1-.14.69c-.21.79-.42,1.58-.62,2.37C766.55,498.46,765,498.46,763.82,498Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.36"/>
  <path d="M744,584a4.33,4.33,0,0,1,3.89-3.53c.18-.29.37-.59.58-.87,0-.06,0-.12,0-.18a10.34,10.34,0,0,1,.9-4.41,3.52,3.52,0,0,1,.66-1,59.45,59.45,0,0,0,2.22-9.83,3.82,3.82,0,0,1,3.21-3.51c1.68-5.81,2.73-10.27,2.46-10.78-8.19,6-10.34,16-14,23.41-2.84-5.53,1.88-14.71,2.25-20.44a5.17,5.17,0,0,1-3.44-2.9c-.46,10.46,0,22.68-1.19,31.38l-.17,3.11c-.08,5.28.6,7.06,1.72,6.56A46.71,46.71,0,0,1,744,584Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.36"/>
  <path d="M886.28,337.69c17.76,30.82-5.19,17-15.36,2.25C845.61,336.7,880.46,338.5,886.28,337.69Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.29"/>
  <polygon points="78.53 324.07 54.34 340.75 78.53 324.07 78.53 324.07" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <path d="M740.1,518.44a3.3,3.3,0,0,0-.81.16c-.55,11.71-.38,15.15-.32,15.69.9,1,.28,2.68,1.48,3.58.39,2.27.81,3.1,1.21,3.61C740.94,533.92,740.21,526.16,740.1,518.44Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.36"/>
  <path d="M753.71,520c0,3.59,0,7.18-.29,10.76a53.41,53.41,0,0,1,1,6.26c3.51-1.31,1.9-5.82-.17-8.28C763.48,522.32,758.57,519.84,753.71,520Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.36"/>
  <path d="M744.78,487.56a5.64,5.64,0,0,1,1.1-1.75,5,5,0,0,1-.31-2.15L740,499.21a4.2,4.2,0,0,0,1.61-.6A64.33,64.33,0,0,1,744.78,487.56Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.36"/>
  <path d="M746.84,480.73c.64-.74,1.2-1.51,1.77-2.27l-.93-.7-1.26,3.52A5.34,5.34,0,0,1,746.84,480.73Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.36"/>
  <path d="M766.73,483.46q-1.86,2.71-3.67,5.44C769.07,486.2,771.83,481.07,766.73,483.46Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.36"/>
  <path d="M939.29,403.67c-1.08-5.82-42.35-47.25-39.28-32.68C903.44,368.89,932.82,397.61,939.29,403.67Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <path d="M802.6,354.94l-21.36,30.88Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.9"/>
  <polygon points="78.53 324.07 107.95 327.22 78.53 324.07 78.53 324.07" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <path d="M886.28,337.69c2.67,9.51-48.43-7.34-40.4-4.38C869.78,338.37,876.42,336.17,886.28,337.69Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.81"/>
  <path d="M722.59,631c12.21-4.15,29.45-1.86,4.32,5.48C709.53,643.4,702.25,638.15,722.59,631Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M971.33,487.23c.13.4-17.42-51.62-18.16-53.81C951.76,432.44,975.21,489.24,971.33,487.23Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="450.37 287.06 446.9 286.4 447.72 284.36 425.25 280.76 449.96 289.95 450.37 287.06 450.37 287.06" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M744,605.33c-1.08,5.11-.13,13,.74,13,.75,4.12,1.95,5.6,2.23,7-2.34.92-4.06,2.78-6.17,4,2.39,6.26,7.25,2.28,9.63-.64A153.29,153.29,0,0,1,744,605.33Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M740.54,506.25a2.25,2.25,0,0,1-1,.46c.14.41.22.62.25.72l-.29-.83q-.09,6-.17,12h.81A112.74,112.74,0,0,1,740.54,506.25Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M722.59,631c-4.85,4.07-20.39,12.92-25.56,6.26Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M743.94,605.16a67.57,67.57,0,0,1-1-9.87c-2.32-2.72-.9-5.06-2.59-6.12-1.15,8.64-1.09,22.18,4.19,28.26-.33-2.61-.65-5.23-1-7.84C743.77,608,743.87,606.48,743.94,605.16Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="490.61 289.02 475.89 287.87 492.81 287.29 490.61 289.02 490.61 289.02" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M979.83,591.92l-1-.15a27.79,27.79,0,0,0-4.1-3.06C976.74,565.93,983.18,571.9,979.83,591.92Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.8"/>
  <path d="M840.22,334.5c1.32-.48-28.09,8.58-18.55,5.43C826.15,339.2,844.14,330.4,840.22,334.5Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M979.76,580.66a11,11,0,0,0,.18,3.06Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M980.93,591.22a14.83,14.83,0,0,0,3.13.16,5.57,5.57,0,0,1,1.53-.25c1.87-.54,2.63-1.76,3.74-2.81-3.82-3.4-8.39-.32-9.39-4.6q.21,3.81.44,7.64A.41.41,0,0,0,980.93,591.22Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M802.6,354.94c-6.63,2.87,21.69-18.69,12-11Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M746.93,625.36c-1.7.43-4.08,4.38-7.54,4.14-3.61-1.65-11.56,4.08-16.25,1.37C730.21,629.26,738.08,627,746.93,625.36Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M971.33,487.23l1.3.55c1.91,7.3,5.3,14.92,6.6,24.22Q975.27,499.61,971.33,487.23Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M742,496.84l-2,2.37c-.17,2.12-.35,4.23-.52,6.35a10.63,10.63,0,0,1,1.51-3.23C741.27,500.49,741.6,498.65,742,496.84Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.36"/>
  <path d="M982.53,549.45q-.54,9.94-1.06,19.88C979.36,565.11,980.42,555.23,982.53,549.45Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M982.53,549.45,981.42,561c-2.75-5.45-2.62-15.1.05-23.53-.27,1.74,2.85,5.87,1,10.47Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.8"/>
  <path d="M944,410.15c2.39,4.78,1.44,2.87,0,0C940.44,411.32,951.71,422.93,944,410.15Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M953.06,432.89l-4.95-12.8Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M743.16,589.77a11.92,11.92,0,0,0-2.77-.6l2.59,6A52,52,0,0,1,743.16,589.77Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M980.93,532.71l-1.65-19.87Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M679.78,636.26l1.63,2.36c-2-.52-7,3.84-8.38-1.72a32.43,32.43,0,0,0,5.72-.58Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M924.44,378.49l-7.86-5.7Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M932.09,387l-5-6.91Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M741.54,540.29l-.93-.76c.44,1.56.87,3.12,1.31,4.69C741.79,542.91,741.67,541.6,741.54,540.29Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M617,643c.2-1.49.4-3,.59-4.46l1,.42c-.07,1.78,5.13,4.61-1,5.16C617.22,643.78,617.56,644.09,617,643Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="497.4 289.89 496.29 293.71 495.9 288.42 497.4 289.89 497.4 289.89" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M878.44,335.53c-2.2-2.38,5.34,5.86,1.15,1.77C880.74,338,877,335.74,878.44,335.53Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M982.52,547.89c-.11-2.45-.23-4.9-.34-7.35Q982.36,544.22,982.52,547.89Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="495.9 288.42 492.06 290.08 490.65 287.98 495.9 288.42 495.9 288.42" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M840.71,334.45l1.16-1.56C842.36,333.18,847.43,332.42,840.71,334.45Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M935.38,392.69l-3.15-5.31Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M980.91,575.07l-.28-1.61c-.68-.27-1.41-3,.94-3.08Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="437.21 281.89 437.61 283.2 433.63 282.08 434.08 280.71 437.21 281.89 437.21 281.89" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M739.26,522.54c-.14,1.83-.29,3.66-.43,5.48C739,526.2,739.12,524.37,739.26,522.54Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="446.06 283.43 441.42 283.06 446.06 283.43 446.06 283.43" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M678.75,636.32l-3.66.5Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M927.11,380l-.08,1.08C929,382.6,922.13,377.24,927.11,380Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M744.7,618.32l.93,3.29Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M910.33,369.56l-2.13-.37Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="501.23 291.42 498.35 290.27 498.93 290 501.23 291.42 501.23 291.42" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M980.38,591.36l.55-.14.48.67-1.58,0Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M741.35,584.46c-.14.7-.28,1.41-.41,2.11C741.07,585.87,741.21,585.16,741.35,584.46Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M891,342.94l-1-2.12Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M981.57,570.38l-.55.08c-.91-.88-1.8-1.77-.07-2.72A7.64,7.64,0,0,1,981.57,570.38Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.8"/>
  <path d="M926,378.93c0-.25-.36.56-1.58-.44C924.65,377.92,923.84,376.4,926,378.93Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M980,578.76c.15-.36.29-.72.43-1.08l.76.5Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="447.72 284.36 447.15 283.59 448.8 284.26 447.72 284.36 447.72 284.36" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M738.86,530.64l.06,1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="495.87 288.41 494.72 287.52 495.87 288.41 495.87 288.41" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M741.93,580.72l-.41.63Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M906.64,369l0-.6C906.21,368.21,908,369.3,906.64,369Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M1204.54,800.86c18.6,6.61-12-39.05,1.53-13.17C1209.54,782.75,1179.15,820.5,1204.54,800.86Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1184.34,815c-2.58-2.32-4-3.27,0,0C1187.89,811.41,1200.92,802.42,1184.34,815Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <polygon points="618.18 469.36 588.32 450.24 618.18 469.36 618.18 469.36" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <polygon points="569.51 401.41 571.55 392.02 584.88 410.89 569.51 401.41 569.51 401.41" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.46"/>
  <polygon points="518.67 293.53 515.32 293.99 518.67 293.53 518.67 293.53" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M1200,783.11c-19.45-13.25-13.29-27.2,7.82,6.13C1206.92,788.62,1206.21,788.33,1200,783.11Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <polygon points="584.13 418.17 584.9 411.85 599.44 441.62 584.13 418.17 584.13 418.17" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="584.88 410.89 574.99 395.7 573.73 400.44 571.61 392.53 584.88 410.89 584.88 410.89" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="594.6 439.07 599.58 442.11 594.6 439.07 594.6 439.07" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="568.45 380.56 567.39 371.91 570.03 383.14 568.45 380.56 568.45 380.56" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="570.03 383.14 570.48 382.63 571.19 388.44 570.03 383.14 570.03 383.14" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="570.44 381.01 570.41 376.91 570.44 381.01 570.44 381.01" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M1126.83,648.79l-1-1.66Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M1185.09,761.8c-.22-.43-.45-.85-.68-1.28C1184.64,761,1184.87,761.37,1185.09,761.8Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M1183.09,758.12c-.25-.42-.5-.84-.74-1.26C1182.59,757.28,1182.84,757.7,1183.09,758.12Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="456.04 589.12 473.45 538.81 456.04 589.12 456.04 589.12" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <polygon points="381.3 560.91 363.54 563.69 381.3 560.91 381.3 560.91" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="358.49 504.46 349.4 543.67 358.49 504.46 358.49 504.46" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="331.12 575.47 340.91 563.84 331.12 575.47 331.12 575.47 331.12 575.47" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="359.87 585.99 379.92 566.44 359.87 585.99 359.87 585.99 359.87 585.99" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="349.41 543.65 375.12 545.22 349.41 543.65 349.41 543.65 349.41 543.65" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="350.97 584.95 359.34 585.98 350.97 584.95 350.97 584.95" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <polygon points="353.07 566.65 356.9 560.48 353.07 566.65 353.07 566.65 353.07 566.65" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="367.7 553.58 370.02 563.17 367.7 553.58 367.7 553.58 367.7 553.58" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="349.9 596.11 348.41 594.56 349.9 596.11 349.9 596.11" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <polygon points="345.23 543.12 345.23 543.12 345.23 543.12 345.23 543.12 345.23 543.12" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="363 545.74 365.62 548.35 363 545.74 363 545.74 363 545.74" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="339.96 582.27 337.39 581.28 339.96 582.27 339.96 582.27" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="445.08 598.01 445.1 594.65 446.13 597.49 445.08 598.01 445.08 598.01" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.25"/>
  <polygon points="351.5 564.56 350.45 566.13 351.5 564.56 351.5 564.56" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <path d="M1110.86,952c-46.1-71.71,135.71-3.46,89.75,1.62C1176.13,954.08,1143.16,954,1110.86,952Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <polygon points="464.72 606.85 496.13 571.69 478.21 541.99 464.72 606.85 464.72 606.85" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <path d="M1085.37,954.88c.61-.44,1.24-.88,1.91-1.28a5,5,0,0,1,2.86-.6l1.16-.56q.46-1.49,1-2.94c-3.84,1.75-6.48,2.8-8.36,4.48A5.28,5.28,0,0,1,1085.37,954.88Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.39"/>
  <path d="M1083.51,953.82c.31-1,.61-1.89.91-2.83,2.79-5.53-.89-6-2.48-6.11l-.7.63q-.26,3.78-.49,7.56c0,.22,0,.44-.07.66A4.87,4.87,0,0,1,1083.51,953.82Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.39"/>
  <path d="M1091.73,914.4c-7.21,1.33-10.12-12.18-1.66-16.73C1093.46,903.38,1101.54,908.2,1091.73,914.4Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M1093.63,902.72c-9.42-5.53-9.25-18.32-9.22-29.62C1087.74,863.94,1087.9,882.5,1093.63,902.72Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1087,871.51l-2.66.57-4.83-3.35c-.73,7,1.18,16.29,3.47,25.2l-2.28.55c-.32-2.59-1.77-7.36-2.09-12C1072.89,876.62,1076.6,851.17,1087,871.51Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1091.73,914.4c0-3.77-1-4.16,4.49-2.52-.29-2.25-.62-3-1.73-7.13,2.43.39,2.71,3.82,3.54,7.53-.61,1.92,3.33,2.83,2.54,7.38q.87,2.55,1.73,5.11C1098.67,922.8,1096.2,919.07,1091.73,914.4Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M1084.42,951c-.12-1.06-.37-.42.53-2.09-.29-.5.9-.61-1-3.67,2-2.07,3.32-7.75,7.6-9.83C1086.44,946.86,1104.62,938.21,1084.42,951Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.39"/>
  <path d="M1084.41,873.1l.48,4.2Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1103.86,926.3l.93,1.7Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M755.91,854.07a300.1,300.1,0,0,1-5.59-43.78,65,65,0,0,1-2.71-7.38,4.53,4.53,0,0,1,2.07-5.38c-.2-5.23-.35-10.44-.48-15.62-2-10.56-12.73-7.58-19-13.6-6.78-8-13.56-11.71-19.39-5.24-21.12,35.64,4.52,16.4-46.32,29.49-1.22,9-.85,14.76-2.46,21.49h0c-8.84,6.81-19.87,3.21-28,6.23,23.42,120.26-15.69,82.24,95.32,91.28,6.52-1.39,31.93,5.17,31-2.76C753.08,889.28,752.06,871.75,755.91,854.07Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.46"/>
  <path d="M643.77,883.52c.26,1.35.33,7.23-3.73,6.31-5.3,11.25-12.88.39-4.11,1.87-6.95-6.68.35-6.26,6.54-12.89C643.73,879.87,642.2,881.12,643.77,883.52Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.7"/>
  <path d="M640,889.83l3.61-4.77c1,1.35,1.26,3,1.94,6.84C649.16,904.54,649.78,920.89,640,889.83Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M636.54,835.47c.45.66,4.32,26.66-1.14,10.41-.54-1.23.11-3.67-1.07-6.78Q633.5,831,632.67,823c1.58.69,1,2.71,2.2,3.62C635.23,828.51,635.25,828.74,636.54,835.47Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M635.4,845.88l.9-1.82c.57,2.32,1.11,4.54,1.65,6.76l.71-1.55q1.89,11.93,3.77,23.86c-2.93-.21-.24-2.47-3.46-8.87Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M649,909.14l-.54,5.23-2.1-6.78,2,0,.21-.34A7.29,7.29,0,0,1,649,909.14Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M634.87,826.57l-2.2-3.62C634.13,823.17,634.39,822.1,634.87,826.57Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M642.72,880.4c.22-.64-.72.08.15-2.78l.9,4.9C642.16,882.44,642.79,881.25,642.72,880.4Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M634.33,839.1c.41.65,2.45.57.46,2.64C634.64,840.86,634.48,840,634.33,839.1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="55.38 505.67 52.25 506.52 51.81 506.56 55.38 505.67 55.38 505.67" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M634.52,824l-.83-1.12.45-.47Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M651.08,934.77l.49,1.05Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M634.82,879.36l.46-.75.29.28-.76.47Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="55.09 370.06 38.37 341.32 83.82 370.22 80.07 353.62 55.09 370.06 55.09 370.06" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <polygon points="97.66 427.11 96.97 427.04 96.93 423.96 98.94 423.16 96.78 411.47 98.8 398.79 109.65 383.48 125.22 379.96 106.73 400.54 97.66 427.11 97.66 427.11" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M684.48,695.26a80.72,80.72,0,0,1-9.14-7.24c6-3,8.62,2.19,12.77,7.87Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="98.11 402.45 102.05 406.74 96.78 411.47 95.4 410.69 94.7 407.15 98.11 402.45 98.11 402.45" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="107.17 388.75 108.79 392.57 101.1 398.82 101.47 391.37 107.17 388.75 107.17 388.75" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="107.17 388.75 102.37 390.81 105.06 385.57 107.17 388.75 107.17 388.75" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="101.47 391.37 101.1 398.82 98.46 398.84 97.69 401.06 101.47 391.37 101.47 391.37" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M691.81,696.9c-.2,2.76-1.73,5-4.91,2.43L689,696C689.22,696.1,691.36,695.05,691.81,696.9Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M677.6,693.82l5.34,1.2Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M638.46,684.35l1.08.52Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M643.66,686.13l1.44.54Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M675.1,693.18l1,.29Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M1107.21,971.33c-4.22-14.33,28.91,1,35.6-4.49C1185.19,962.26,1130.81,979.42,1107.21,971.33Z" transform="translate(-581.84 -313.76)" fill="#b1abaa" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1209.93,952.53c-3.09-2.16-27-31.34-18.66-28.9C1202.91,934.7,1242.41,988,1209.93,952.53Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1209.93,952.53c-7,5.86-75.9,2.51-99.06-.49C1136.8,952.23,1177.19,954,1209.93,952.53Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1161.43,974.58c.55-.42-8.12-1-1.43-2.69C1161.34,972.77,1169.22,973,1161.43,974.58Z" transform="translate(-581.84 -313.76)" fill="#b1abaa" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1162.3,968.27l1.87-2C1164.29,965.27,1165.29,972.2,1162.3,968.27Z" transform="translate(-581.84 -313.76)" fill="#b1abaa" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M658.12,816.8,652.55,818c-.35-.24-.71-.36-1,0l-6.79.9Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M664.39,793.58l-.93,9.94Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M663.38,804.56c-.45,3.16-.89,6.32-1.34,9.49C662.49,810.88,662.93,807.72,663.38,804.56Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M643.66,819.1l-3.62.17Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M662.05,814.05l-1.66,1.41Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="97.66 427.11 107.35 422.35 114.07 434.25 113.64 429.27 123.28 445.45 105.26 472.01 101.07 473.07 86.45 471.02 85.94 471.51 75.46 467.81 79.49 464.03 79.01 455.82 92.72 450.06 93.89 452.56 92.71 450.06 92.73 447.46 104.58 444.42 104.2 433.48 97.66 427.11 97.66 427.11" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M680.77,755.5l.5,0c3.09,2.1,2.29,5-4.66,4.06-10.59,3.86-31-2.84-43.39,2.61-1.82-.12-3.64.09-5.9-2.28,13.32-6.8,34.3,1.88,50.35-4.42Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <path d="M627.5,760.13l4.72,2q-1.47,1.85-3,3.67c.33.36-1.35-8.56-6.28,1.42C619.73,762.07,616.17,757.65,627.5,760.13Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <path d="M620.72,759.7l-1.06,2a98,98,0,0,0-15.72,0c.38-1.65.77-3.31,1.15-5l3.52-.06C611,761.1,614.8,756.62,620.72,759.7Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <path d="M633.22,762.19l3.15-.46,1.61,2.43Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <path d="M805.77,912.3c.38-.87,3.88.11,4.18-2.63,1.53-.9,1.5-.25,5.09-2.74-2.87-3.18-14.79-4.61-13.45-10.89,2.15-4.84-.25-13.29,7.6-9.64.72-2.91,1.88-2.25,3.88,2.21-7.34-2.74-11.08,5.39-9.16,10.3,7.8-2.67,4.49,5.05,14.45,4.33.06,3.63-1.92,8.25-5.79,13.24Q809.17,914.4,805.77,912.3Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.36"/>
  <path d="M795.3,906l3.68,0c.18.69.35,1.39.53,2.08-.47.59-.74,1.37-1.58,1.58-2.93,5.58-7,9.94-15,13.42,2-1.71,3.5-17.13,9.47-10.51C793.37,910.59,792.57,911.3,795.3,906Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.36"/>
  <path d="M797.93,909.69l1.58-1.58,1.89,4.22Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.36"/>
  <path d="M782.42,921.48c-1.29.31-1.22,1.36-3.18-.82C780.4,920.26,780.36,918.88,782.42,921.48Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.36"/>
  <path d="M1066.19,963.36h0a42.62,42.62,0,0,1,.77,5.52,10.3,10.3,0,0,0,2.81,2,56.45,56.45,0,0,1,2.42-5.53C1070.73,965.87,1068.84,965.46,1066.19,963.36Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.39"/>
  <path d="M1229.23,870.46c-6.11,4.86-15.21,17.56-23.4,21C1207.14,883.34,1213.26,875.4,1229.23,870.46Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1212,887.76c12-11,20.47-24.75,2.57,3.6C1212.4,892,1204.26,899.56,1212,887.76Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1214.59,891.36l2.62-6.75Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1212,887.76c-2.74,8.21-18.09,23.32-25.07,27.7C1184.13,904.31,1199.38,898.15,1212,887.76Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1201.49,902.85l5.26-4.68Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1207.26,896.59c.51-.53,1-1.05,1.54-1.57C1208.29,895.54,1207.77,896.06,1207.26,896.59Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <polygon points="68.14 358.56 48.85 341.28 68.14 358.56 68.14 358.56" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <polygon points="52.58 327.43 52.16 333.19 52.58 327.43 52.58 327.43" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <polygon points="42.01 349.14 47.48 354.69 42.01 349.14 42.01 349.14" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <polygon points="41.51 339.67 40.43 347.58 41.51 339.67 41.51 339.67" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <polygon points="63.59 336.76 63.59 336.76 63.59 336.76 63.59 336.76" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <polygon points="57.68 330.29 58.77 329.71 57.68 330.29 57.68 330.29" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <polygon points="67.6 333.52 64.51 335.01 67.6 333.52 67.6 333.52" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <path d="M1190.3,809.76c-2-.16-2.83-.55,0,0C1194.29,807.07,1200.46,800.69,1190.3,809.76Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1183,816.3c6.1-3.11-9.34-8.86-10.82,2.09C1175.3,817.76,1171.73,817.51,1183,816.3Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1157,831.72c6.91-20.43,3.65-17.29,15.88-23.57C1160.8,800.15,1124.69,802.46,1157,831.72Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1201,826.29c2.22-1.26,3.35-1.86,0,0C1194.51,829.46,1198.32,836.32,1201,826.29Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1211.93,805.71c1.92,1.25-10.65,9.2-4.83,5.95C1209.68,809.76,1215.87,815.59,1211.93,805.71Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1193.19,840.38c2.6-1.78,2.06-.11,1.41-6.92C1194.25,836.35,1194.84,833.64,1193.19,840.38Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1164.05,847.94c-15,9.53-5.12-24.87-24.92-3.63C1109,804.8,1140.76,879.79,1164.05,847.94Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1153.5,797.12a56.05,56.05,0,0,1-5.66,1.51C1149.58,800.34,1151,798.37,1153.5,797.12Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1162.21,797.7c1.43-.72,4-2.1-1.55-2.58C1157.92,796.38,1151.57,795.55,1162.21,797.7Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1214.6,816.12c-1.17-3.62-2.77-4.53,0,0C1219.24,812.25,1219.79,807,1214.6,816.12Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1202.51,825.46c-8.32,7.08,1,19.94,4-1.56C1207.87,822.42,1208.82,822.06,1202.51,825.46Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1210.34,800.93c-1.31-2.79-5.08-6.66.33-10.19Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1223.55,811.41c-.22,2.42,2.83-.19,2.54,7.82C1224.5,819.21,1217.15,812,1223.55,811.41Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1218,808.53l-3.92,1.8C1213.73,808.19,1209,803.23,1218,808.53Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1221.5,810.82l-1.17.07C1220.05,811.28,1218.07,807.65,1221.5,810.82Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1210.91,839.08c20.17,14.41-29.38,12.84,4,33.24C1158.7,894.72,1175.75,863.4,1210.91,839.08Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1203.1,875.21c43.19-11.25-2.17,12.14-21.27,6.94C1185,880.45,1187,879.86,1203.1,875.21Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1216.68,843.26l9.42-4.69C1223.68,841.59,1221.84,845.76,1216.68,843.26Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M701.27,773.23q.54-5.82,1.06-11.63c.77-.92,2.92-.48,2.52-2.55,1.47-.32.87-1.84,5.13-2.31,1.13,1.29.54,3.3.87,7.74-.66.72-1.33,1.43-2,2.15-.42-.16,0,0-1,.51h-.17Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M673,783.64l9.93,3.19C679.23,786.9,674.88,789.07,673,783.64Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M668.29,784.77l4.17-1.11Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M685.52,786.27c.33-.76.67-1.53,1-2.29l.56,1.78Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="96.8 411.48 102.09 414.96 94.9 419.19 96.8 411.48 96.8 411.48" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M1188.45,905.52c-4.76,2,8.07,28-1.33,21.28C1182.64,920.53,1180.5,911.66,1188.45,905.52Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1189.5,921.16c-.08,1.31-5.85-6.17,0-3.62C1189.1,918.25,1189.67,917.94,1189.5,921.16Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1189.49,919.1c0-.52,0-1,0-1.56C1189.49,918.06,1189.49,918.58,1189.49,919.1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <polygon points="95.98 420.67 96.95 420.62 100.96 423.84 96.93 423.96 95.94 422.37 95.98 420.67 95.98 420.67" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M1182.05,851.68c2.35,3.22-27-2.94-9.17,18.77C1178.16,875.77,1180.33,869.41,1182.05,851.68Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1219.86,813c2.41-9.19-30.55,30.3-11.28,19.91C1217.15,828,1218.62,825.23,1219.86,813Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1193.41,854.54c3.6-11.52-17.82-7.33-11.88,4.4C1182.83,863.25,1210.35,852.3,1193.41,854.54Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1196,830h0C1192.82,828.53,1201.11,847.77,1196,830Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1194.85,833.13c1.59-.75-3.12,6.9,3,5C1196.56,836.7,1198.61,837.49,1194.85,833.13Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1216.13,828.61c.52-.52,1-1.05,1.54-1.58C1217.16,827.56,1216.65,828.09,1216.13,828.61Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1217.69,827v-1C1218,826,1219.76,825.87,1217.69,827Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <polygon points="84.85 444.26 40.46 445.57 40.43 442.73 56.63 442.22 80.66 436.07 86.98 435.91 84.85 444.26 84.85 444.26" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="80.66 436.07 56.63 442.22 70.81 436.29 80.66 436.07 80.66 436.07" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M622.26,756.49c2.36,3.51-11.87,5.79-13.65.16l1.7,0a.92.92,0,0,0,1.45-.07Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <path d="M631.62,749.83l12.09.27Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <path d="M622.23,749.79l8.45,0Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <path d="M611.76,756.62l-.79,1.72-.66-1.65Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <path d="M610.82,745.53c10-3.23,23.35-4,35,0-3.14,3.35-13.36-.37-20.35.6-3.05.34-8.59.27-13.68.42C611.47,746.22,611.14,745.87,610.82,745.53Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <polygon points="84.85 444.26 86.98 435.91 89.54 436.04 95.84 441.73 84.85 444.26 84.85 444.26" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M630.62,746.17c5.18,1.06,14.29-2.5,19.87.41C645.6,746.32,636.88,749.78,630.62,746.17Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <path d="M650.49,746.58l-3.64-1,10.47-.49a1.37,1.37,0,0,1,0,2l-2.66.07c-1.13-.7-2.51.27-3.63-.52Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="92.72 432.82 99.15 431.28 94.77 434.38 92.72 432.82 92.72 432.82" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M614.44,746.56l11-.41Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <polygon points="99.15 431.28 98.48 429.14 105.22 431.74 103.16 432.29 99.15 431.28 99.15 431.28" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="75.48 433.31 75.48 431.29 79.14 431.79 83.32 433.41 75.48 433.31 75.48 433.31" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="83.32 433.41 79.64 431.8 87.06 431.88 86.98 433.83 85.36 433.84 83.32 433.41 83.32 433.41" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M677.16,755.43l-4.71-4.11Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="92.72 432.82 94.77 434.38 89.05 433.88 91.14 432.81 92.72 432.82 92.72 432.82" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M651,746.62l3.63.52Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="91.14 432.81 89.05 433.88 88 433.82 87.92 431.87 91.14 432.81 91.14 432.81" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M626.43,746.14h0Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <polygon points="87.92 431.87 88 433.82 86.98 433.83 87.06 431.88 87.92 431.87 87.92 431.87" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="90.56 437.51 89.54 436.04 90.56 437.51 90.56 437.51" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M610.82,745.53l1,1-1-.48Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <path d="M612.27,746.68l1.17,0Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <polygon points="84.36 433.42 85.36 433.84 84.36 433.42 84.36 433.42" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M608.16,745.54l1,.54-1,.55C607.82,746.19,608.13,746.61,608.16,745.54Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <path d="M608.16,745.54c0,.33,0,.67,0,1L605,745Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <polygon points="100.54 429.15 98.48 429.14 97.84 427.59 100.54 429.15 100.54 429.15" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="79.14 431.79 77.52 431.81 79.14 431.79 79.14 431.79" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M617,643l.53,1.11L616,643Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M1217.28,823.47c-.29,4.34-3-17.14,8.8-4.25C1224.76,820,1223.66,820.9,1217.28,823.47Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1218.75,826l-1.06,0C1217.54,825.38,1216.67,824.38,1218.75,826Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M840.71,334.45c44.75,12.43,52.48,19.73,57.55,34.13-12.8-3.47-39.76-37.79-54.21-22.57C772.92,410.18,786.11,373.17,840.71,334.45Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.54"/>
  <path d="M898,410.83c-1.12.2-5,10.41,4.17,6.76C900.38,417.24,906.1,417.27,898,410.83Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.25"/>
  <path d="M798.07,419c-11.53,8.22-6.15,2.32,1.71-16.42C794.43,415.46,783.81,465.8,798.07,419Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.52"/>
  <path d="M799.78,402.54c-7.84-7.69-7.35,3.14-14.85,18.23C770.79,448.85,794.66,409.44,799.78,402.54Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.61"/>
  <path d="M794.15,399.19c2.27-7-13.54,16.66-5.84,9.8C791,407.2,793.73,405.72,794.15,399.19Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M796.89,409.86c1.63-3.27,1-2,0,0C792.84,411.74,801.89,400.36,796.89,409.86Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="327.76 61.36 327.76 61.36 327.76 61.36 327.76 61.36" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="433.02 304.28 431.08 310.65 426.11 306.66 426.27 313.46 430.75 312.76 430.98 315.88 426.65 315.36 426.98 323.38 421.27 320.01 424.34 323.76 405.56 311.96 413.73 295.49 421.87 302.96 418.86 299.1 428.56 304.65 429.46 296.5 433.08 298.94 433.02 304.28 433.02 304.28" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M981.56,603.47a5.6,5.6,0,0,1-.74-.54l-1.48.39c5,23.08-.29,33.46,12,17.78l-3.86-1.67C992,614.83,982.63,607.87,981.56,603.47Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <polygon points="438.3 304.69 434.44 305.64 435.19 304.64 438.3 304.69 438.3 304.69" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M944.83,490.9c-2.66,2.51-2.21,5.18-1,8.1a5.4,5.4,0,0,1,2.88,4.55.53.53,0,0,0,0,.13,1.29,1.29,0,0,0,0,.19c.07.34.16.66.24,1a16.73,16.73,0,0,1,.48,3.31,4.34,4.34,0,0,1-.17,1.36,9.68,9.68,0,0,1-.31,2.58c2.21,11.2-8.15,21.63-1.11,31.07,1.34-.64,1.25-1.6.6-2.68v-.93c1.75-8.08,1.58-15.54,2.53-22a.93.93,0,0,1,0-1.57C951.51,504.87,947.92,499.52,944.83,490.9Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="4" opacity="0.68"/>
  <path d="M946.47,539.58q.75-5.52,1.51-11Q947.22,534.06,946.47,539.58Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M945.87,543.19l.6-2.68c-.17,1.42-.34,2.85-.5,4.28C945.93,544.25,945.9,543.72,945.87,543.19Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M947.45,545.79l0,1c-.09.55-.57-.32-1,2.12-1.95-.38-2.07-1.06-.55-3.16A9.77,9.77,0,0,0,947.45,545.79Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M976.83,590.19l2,1.58Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M975.19,590.21c0-.53,0-1.06,0-1.59l1.56,1.51Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M949,517.57l-.89-.92c.3-.22.6-.44.91-.65Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M776.46,407.78c-7.39-.11-.69-5.35,5.26-12Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.9"/>
  <path d="M772.85,410.89c-.49,3-9.53,23.39-2.07,1.16C770.93,414.57,771,404.8,772.85,410.89Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.9"/>
  <path d="M781.72,395.74h0C780.57,395.41,787,388.64,781.72,395.74Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="144.19 351.54 83.43 351.63 118.61 349.03 144.19 351.54 144.19 351.54" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <polygon points="82.84 349.25 71.78 340.26 82.84 349.25 82.84 349.25" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <polygon points="103.35 340.48 84.62 336.57 103.35 340.48 103.35 340.48" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <polygon points="113.02 330.76 111.06 345.01 113.02 330.76 113.02 330.76" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <polygon points="109.48 345.54 98.48 342.89 109.48 345.54 109.48 345.54" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <path d="M660.46,638.31l2.65,1.5-3.93.21Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M974.91,507.36c.23,1.73,2,3,2.11,10a40.1,40.1,0,0,1-3-9.81Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.8"/>
  <path d="M783.74,503.34c-1.7,9,13.33-8.28,9.46-10.79C792.1,494.69,794,494,783.74,503.34Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.36"/>
  <path d="M769.81,522.16s0,.07,0,.1c-5,2.93-9.9,6.64-6.49,11.26,6-1.08,8.94-6.95,11.21-10.57A4.54,4.54,0,0,1,769.81,522.16Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.36"/>
  <path d="M766.57,504.49a10.91,10.91,0,0,1-5.41,4,61.57,61.57,0,0,1-2.11,6.41,6.1,6.1,0,0,1-2.84,3.12c2.42-.18,5.49,1.47,7.29.36,2-2.17,3.53-2.57,4.6-2,.14-.42.27-.84.42-1.25a25.2,25.2,0,0,1,4.86-14.18C772.12,500.85,768.79,503.13,766.57,504.49Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.36"/>
  <path d="M762,505.39c-.35,1.38-.71,2.76-1.11,4.12a8.57,8.57,0,0,1,1.47-.39C765,506.37,763.46,505.4,762,505.39Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.36"/>
  <path d="M763.45,535.37c-1.45-4.28-6-1-9.09.88.11.92.2,1.85.24,2.79a4.64,4.64,0,0,1-.6,2.64c4.43,2.44,14.35.08,16.32-1.16C769.2,535.05,765.14,536.51,763.45,535.37Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.36"/>
  <path d="M743.18,599.16a21.3,21.3,0,0,0-.07,3.64,2.15,2.15,0,0,0,.52.38Q743.35,601.17,743.18,599.16Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M753.7,518.61c0,.88,0,1.76,0,2.64l5.51,0,2.65-3.14c-2-.35-3.59-.59-4.74-.71A5.16,5.16,0,0,1,753.7,518.61Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.36"/>
  <path d="M905.64,369.05h1C906.46,369,906.51,370.76,905.64,369.05Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M898.83,368.63h0C898.29,369,900.81,367.84,898.83,368.63Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M801.06,358.09h0C800.62,357.75,804.17,356,801.06,358.09Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M801.07,358.1h0C801.34,358.36,799.27,359.25,801.07,358.1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M898.3,366.54l-.53-.06Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="157.55 315.74 121.33 331.12 157.55 315.74 157.55 315.74" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <polygon points="167.87 312.95 139.85 357.33 167.87 312.95 167.87 312.95" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <path d="M743.58,602.8h-.47l.5,6.79c.23-.78.47-1.56.7-2.34C744,605.76,743.78,604.28,743.58,602.8Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M748.65,630.71l-3.5,1.31" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M703.3,644.07l-3.59-2.6c.86-1.07-.51-2.07-.1-3.13l6.85.56C705.4,640.62,704.35,642.35,703.3,644.07Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M699.61,638.34l1.78,2.82-1.68.31-1.08-2.71Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="496.29 293.71 495.84 295.82 491.91 292.5 496.29 293.71 496.29 293.71" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M980.45,649.52a5,5,0,0,1,1.86,2.53c1.09-.77,2.18-1.53,3.28-2.28-1.57-1-3.48-2.44-5.83-4.3l.63,3.85A1.52,1.52,0,0,1,980.45,649.52Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M992.65,648.12a5,5,0,0,1,4.38-.31l.09-.59c-2.1-6.65-3.77-13-7.34-17.76-.14,7.58,1.9,14.88,2.21,19Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M998.82,648.12c5.36-2.44,9.56,3.37,6.06,7.85-.21.27-.41.55-.62.83,2.3.22,5.06.52,5.68-3.32-4-10.07-8-9.36-11.26-5.29Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1081.66,673.61c-1.58,4.14-1.21,8.36-.36,12.49C1081.41,681.94,1081.58,677.77,1081.66,673.61Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.42"/>
  <polygon points="427.84 339.66 425.73 328.75 427.93 332.57 429.23 332.13 429.39 339.78 427.84 339.66 427.84 339.66" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <polygon points="493.17 295.81 495.84 295.82 495.68 300.12 493.17 295.81 493.17 295.81" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M997.12,647.22c-5.45-1.38-1.78-4.72-1.57-7.32C996.07,642.34,996.6,644.78,997.12,647.22Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1005.42,644.09v2.53l-4.64-1C1001.51,645.48,1003.54,641,1005.42,644.09Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M989.78,628.39l-2.06-2.61,3.64-.12c-.17.91-.35,1.82-.52,2.73Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <polygon points="455.33 304.81 454.51 300.58 457.35 303.55 455.33 304.81 455.33 304.81" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M993.18,630.44c.3,0,5.19,6.42.6,2.7C994.38,632.63,991.43,633,993.18,630.44Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <polygon points="460.82 303.68 460.37 300.29 462.58 302.36 460.82 303.68 460.82 303.68" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M1005.42,644.09l-2-1.07,3.12-.52Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <polygon points="433.02 304.28 432.53 300.48 433.02 304.28 433.02 304.28" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="494.74 303.14 495.7 302.52 495.44 303.86 494.74 303.14 494.74 303.14" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="495.68 300.12 494.53 299.45 495.75 298.91 495.68 300.12 495.68 300.12" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="562.07 391.19 560.45 391.22 562.07 391.19 562.07 391.19" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M1162.63,816c11.3-3.14,27-1.2,2.2-8.31C1163.68,810,1148.31,812.66,1162.63,816Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <polygon points="586.72 424.98 584.65 419.23 586.72 424.98 586.72 424.98" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="285.11 504.43 255.09 442.13 305.12 456.58 285.11 504.43 285.11 504.43" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <path d="M760,857.42a27.63,27.63,0,0,1-.12-9.68,4.36,4.36,0,0,1-1.19-1.08c-.45,4.09-.28,8,1.52,11.42A4.25,4.25,0,0,1,760,857.42Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.46"/>
  <polygon points="264.59 534.4 265.37 522.77 238.74 537.53 274.96 507.28 264.59 534.4 264.59 534.4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.28"/>
  <path d="M771.44,900.4c-1.87-6.54,5.26-4.1,6.9-5,.66-.39,1.26-.78,1.82-1.16a5.29,5.29,0,0,1-.33-1.6c-.05-.88-.07-1.76-.09-2.64l-.29,0-.32.24a5.06,5.06,0,0,1-4.19.4,14.69,14.69,0,0,0-6.9,3.13c-2.94-4.46,5.16-6.1-.66-9.39,1.07-.25,1.76-.43,2.23-.57-.15-.34-.28-.69-.42-1a10.65,10.65,0,0,0-1.19-.17c-.39.32-.79.64-1.22.94a4.61,4.61,0,0,1-3.95.46c-6.43,5.7.83,26.69,2.67,32.48,3.14,1.26,8.41-1.4,9.4-4.75,1.16-5.17,1.45-9.84,3.7-12.72C773.59,895.59,773.79,896.91,771.44,900.4Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.36"/>
  <polygon points="345.23 543.12 328.51 524.29 345.23 543.12 345.23 543.12" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <path d="M797.88,850.16a3.91,3.91,0,0,1,.1-1.72,5.33,5.33,0,0,1-1.46.93v1.88a48.76,48.76,0,0,1,1.65,7.38c2.88-.45,4.62-3.33,1.58-8.84Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.36"/>
  <path d="M806.75,836.94a4.42,4.42,0,0,1-3.39,1.66c.06.25.11.49.18.73a5,5,0,0,1,.07,2.62c2.42,4.07.46,14.76.49,23a5.5,5.5,0,0,1,.16,3.54c0,.35.08.69.13,1,3.54-7.9,5.39-12,9.73-15.82C805.27,846,812.68,839.41,806.75,836.94Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <path d="M763.19,884.11a4.83,4.83,0,0,1-3.25-2.35c-.23-.48-.44-1-.63-1.44a.76.76,0,0,1-1-.21c-2.92-6.56,3.61-18.72-4.41-15.15-2.78,17.82,2.56,32.37,6.36,45.79,3.35.38,3.92,5,5.27,5.74C761.84,906,760.88,893.5,763.19,884.11Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.46"/>
  <polygon points="317.51 530.57 291.9 520.12 317.51 530.57 317.51 530.57" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="333.72 556.18 322.05 574.49 333.72 556.18 333.72 556.18" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <path d="M810.48,884.06c-6.39.78-4.57-10-3.28-14.57,12.72-1.6,6.58,4.22,9.44,8.44C815.8,879.1,811.65,880,810.48,884.06Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.36"/>
  <polygon points="332.19 520.08 327.8 519.42 332.19 520.08 332.19 520.08" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <path d="M835.3,852a3.56,3.56,0,0,1,.66-.63,40.83,40.83,0,0,1,3.76-3.93l0-.14c1.15-1,2.18-2.27,3.86-2.46,2.31-4.84,5.73-7.28,4.24-10.09-6,8.34-15.76,12.62-18.75,19.56L823.41,857c3.51,3.24,8.07-.81,11.67-4.67C835.15,852.26,835.22,852.14,835.3,852Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <polygon points="276.1 518.92 279.87 500.79 283.45 520.98 276.1 518.92 276.1 518.92" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.28"/>
  <polygon points="315.08 509.08 316.48 520.15 315.08 509.08 315.08 509.08 315.08 509.08" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="332.29 548.71 332.29 548.71 332.29 548.71 332.29 548.71 332.29 548.71" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="338.45 547.31 330.43 545.34 338.45 547.31 338.45 547.31" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="317.51 530.57 317.26 525.5 317.51 530.57 317.51 530.57" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="273.66 524.26 275.93 519.41 273.66 524.26 273.66 524.26" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.28"/>
  <path d="M839.69,847.34l3.86-2.46v.88Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <polygon points="335.85 549.82 338.43 548.35 335.85 549.82 335.85 549.82 335.85 549.82" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="287.71 518.59 289.82 519.59 287.71 518.59 287.71 518.59" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M816.18,853.19l-1.51,1.07-.54-.53c0-.19,0-.38.08-.58A1.35,1.35,0,0,1,816.18,853.19Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M810,909.67l.78.53-.78.53Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.36"/>
  <path d="M854.32,842.67c0-.31,0-.61,0-.92l.77.51Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <polygon points="327.43 523.76 327.98 523 327.43 523.76 327.43 523.76 327.43 523.76" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="284.6 506.5 285.11 507.03 284.6 506.5 284.6 506.5" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M1027.44,867.33c-1.08-16.47,28.16-39.21,34.08-44.46C1037.34,863.1,1048.54,853.83,1027.44,867.33Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <path d="M1062.48,853.73c-6.47,11.77-2-13.17,1.57-20.87C1064,833.39,1062.84,849.17,1062.48,853.73Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <path d="M1068.76,821.86l-4.71,11-1.56-1.07c0-.35,0-.7,0-1-.88-.9,1.84-2.33,5.73-10C1069.08,820.79,1069.11,821.23,1068.76,821.86Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1058.81,850.1h0C1058.2,852.54,1057.93,858.88,1058.81,850.1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <path d="M1026.39,872c.35-1.4.69-2.79,1-4.19C1027.55,869.36,1033.19,872.24,1026.39,872Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.25"/>
  <path d="M1057.25,856.9,1054.1,860Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1030.66,876.2c-.25.95-.49,1.89-.73,2.84.19-.56-1.6-.51-.08-3Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1027.08,876.5c-.41.81-.38,1,0,0C1027.6,876.59,1028.19,878.8,1027.08,876.5Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.25"/>
  <polygon points="435.42 585.96 435.06 585.7 435.42 585.96 435.42 585.96" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.25"/>
  <path d="M1027.1,880.45l.35-.3S1028,881.07,1027.1,880.45Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.25"/>
  <polygon points="467.55 492.26 449.03 498.89 467.55 492.26 467.55 492.26" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <polygon points="380.14 557.73 381.52 554.68 380.14 557.73 380.14 557.73 380.14 557.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <path d="M1005,871h0Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.25"/>
  <path d="M999.21,857.92l.52-1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.25"/>
  <path d="M1010.88,868.61c1.09-1.38,1.55-3.19,4.38-.73l-2.73,3.56Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.25"/>
  <path d="M1016.21,865.21s-.81.66-.36-.26Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.25"/>
  <polygon points="353.07 566.65 354.12 565.6 353.07 566.65 353.07 566.65 353.07 566.65" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="356.19 536.35 380.73 534.13 356.19 536.35 356.19 536.35 356.19 536.35" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="360.4 515.96 358.09 514.45 360.4 515.96 360.4 515.96" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="344.62 484.69 342.95 479.43 344.62 484.69 344.62 484.69" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="342.92 499.46 341.35 523.53 342.92 499.46 342.92 499.46" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="354.09 478.26 349.29 475.36 354.09 478.26 354.09 478.26" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="350.43 534.74 350.43 534.74 350.43 534.74 350.43 534.74 350.43 534.74" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="345.3 476.16 348.36 480.9 345.3 476.16 345.3 476.16" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="354.15 507.01 356.52 502.65 354.15 507.01 354.15 507.01 354.15 507.01" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="350.4 532.66 350.94 531.59 350.4 532.66 350.4 532.66 350.4 532.66" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="333.72 556.18 337.91 556.18 333.72 556.18 333.72 556.18 333.72 556.18" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="332.14 558.82 331.11 556.2 332.14 558.82 332.14 558.82" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="332.68 560.9 331.43 560.83 332.68 560.9 332.68 560.9" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="356.19 536.35 370.29 540.99 356.19 536.35 356.19 536.35 356.19 536.35" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <path d="M1183,920.12c20.25,18.86-35.25,2.71-53,8.76C1127.16,917.92,1166.47,920.29,1183,920.12Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1183,920.12c-19.94.51-51.66.77-82.43-.46C1114.48,915.06,1160.21,915.41,1183,920.12Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1192.13,932.16c1.77,3,3,1.91-8,2.05C1185.38,931.33,1183.7,933.24,1192.13,932.16Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1078.14,865.24l-1.08,10.46c-1.65-.51-3.73,4.54-7.47,1.88,3,8.46,3.7-2.9,8,.21.34,1.58.67,3.16,1,4.73-1.42,2.12-10.56,4.47-3.72,8.73-6.08-1.2-8.6-11.16-12.55-18.17-.85,2.36-.52,4.41-1.09,8.76.22-5.67-4.18-13.5.69-20.26,7.54-17,8.51-21.28,11.07,3.52C1073.14,863,1077.69,861.38,1078.14,865.24Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1078.14,865.24l-3.11-1.8c-4.69,10.3-3.48-9.75-5.85-15.39-5.38,2.46-.24,10.22-7.23,13.53.74-7.56,3-24.62,8.86-30.34a5.48,5.48,0,0,1,2.65.44c-1,10.08.91,20.58,6.28,33Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1085,948.9c-.18.52-.37,1.05-.55,1.58l-.79-.73Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.39"/>
  <path d="M1084.43,946.28l1.28.51-.74.55Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.39"/>
  <path d="M1182.45,918.5c-176.27,23.2-55.6-127.92-31.26-21C1165,919.78,1164.34,897.59,1182.45,918.5Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1088.07,872.56h-.56c-.92-2.65-1.88-4.71-.52-9C1090,863.59,1088.72,868.51,1088.07,872.56Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1089.27,888.73q1.23,3.94,2.44,7.89Q1090.5,892.68,1089.27,888.73Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1088.22,884c.3,1.23.6,2.46.89,3.7C1088.82,886.49,1088.52,885.26,1088.22,884Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1092.3,898.69l.49,1.58C1092.63,899.74,1092.46,899.22,1092.3,898.69Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M1087.55,877.79v1.05C1087.54,878.49,1087.55,878.14,1087.55,877.79Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1079.74,864.69l-2.62-4.68c.88-.93,1.74,0,2.45.06a15.44,15.44,0,0,1,.48-12.4c4.35-4.25,8,12.08,5.39,21.22Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1077.61,877.79c-1.82,2.86-10.75,6.74-8.23-2.41C1071.67,881.87,1076.69,870.35,1077.61,877.79Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1085.44,868.89q-.23-6.3-.46-12.58c1.34-.55,3.33,1.73,2.21,7.31q-.15,3.43-.32,6.88C1086.4,870,1085.92,869.42,1085.44,868.89Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1097.44,912.85l.46,1-.38,1.8C1096.61,914.84,1096.56,915.55,1097.44,912.85Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M782.56,801.9c1.88.26,3.85.59,5.82,1-1.74-2-5.61-3.71-9.91-5.18a32,32,0,0,1,3.1,4.1A5.57,5.57,0,0,1,782.56,801.9Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M750.78,811.36c-.2,4.37,1.81,6.52,4.05,7.86C753.36,816.66,752,814.05,750.78,811.36Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.46"/>
  <path d="M758.52,792.4c-1.67-2.14-3.71-4.25-3.77-5.45-3.31,1.25-3.07,5.73-2.48,9.81a5.29,5.29,0,0,1,2,.3A11.54,11.54,0,0,1,758.52,792.4Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.46"/>
  <path d="M761.45,859.88a4.14,4.14,0,0,1-1.5-2.46,27.52,27.52,0,0,1-.12-9.66,4.54,4.54,0,0,1-2.11-4,138.78,138.78,0,0,1,1-18.13,31.14,31.14,0,0,1-2.5,2.16c-2.6,3.1.6,9.82-.8,12.08.65,9.34-1.31,17.19.42,23.35a1,1,0,0,0,.29,1c.61,6.87,1.45,12.77,2.08,17.22l1.21-.92c-2.63-6.5-1-13.88,2.55-19.86A8.25,8.25,0,0,1,761.45,859.88Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.46"/>
  <path d="M759.77,910.72a.9.9,0,0,0,.46,0l.24,2.55c-34.28,3.59-73.22-1.38-110.54-1.5-.14-.91-.28-1.82-.43-2.73C685.22,907.52,722.86,912.15,759.77,910.72Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="165.51 463.62 136.86 436.76 172.69 463.1 165.51 463.62 165.51 463.62" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M760.5,791.45c.17-.06.33-.14.5-.19a13,13,0,0,1,8,0c-3.84-4.75-13.09-12.1-21.67-13.89.26,1,.52,2.11.79,3.16C753.29,784.22,756.11,789.64,760.5,791.45Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.46"/>
  <path d="M758.35,828.61c.1-1,.21-2,.35-3a107.66,107.66,0,0,1-8.19-14.87c-5.35,6.78,4,12.82,5,17.38A4.18,4.18,0,0,0,758.35,828.61Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.46"/>
  <path d="M753,796.77c-.16-1.13-.37-2.19-.55-3.17-1.87-8.12-.88-9.45-3.62-9.92l.15,14.39A5.46,5.46,0,0,1,753,796.77Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.46"/>
  <path d="M749,807.13c0,2.48.06,5,.08,7.43l1.47-3.65C750,809.67,749.52,808.41,749,807.13Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.46"/>
  <path d="M749.11,816.07c2.16,2.8,12.24,13.43,2.93,15.23Q750.59,823.68,749.11,816.07Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.46"/>
  <path d="M719,754.71q1,2.47,2,4.95c-4.61-2.74-9.85-1.22-15.26-7.15C708.45,751.11,714.5,751.81,719,754.71Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M756,840.29q-.42,6-.85,11.91C753.56,849.35,750.67,820.41,756,840.29Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.46"/>
  <path d="M752.38,791.57c-.55-2.37-1.79-2.53-4.24-11,2.68,2.59,3.63,1.49,7.57,6.7a.4.4,0,0,1-.67.09Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.46"/>
  <path d="M714.77,756.18l5,3.46-7.62,1.46C711.86,757.63,710.76,757.86,714.77,756.18Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M756,840.29a23.54,23.54,0,0,0-3.69-6.26c-.75-.58-.31,0-.27-2.73l3.42-2.69Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.46"/>
  <path d="M714.77,756.18a52.17,52.17,0,0,0-3.92,8.3c.38-1.46-1.73-5.49-.2-8.36Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M754.65,859.48l3.8,2.46C757,863.24,752.3,864.45,754.65,859.48Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.46"/>
  <path d="M755.09,892.43c.58,2,1.17,3.93,1.75,5.9C756.26,896.36,755.67,894.4,755.09,892.43Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M755,890.32c0,.39-.11.78-.16,1.17-.32-1.85-.65-3.7-1-5.54C753.73,886.35,755.05,887.25,755,890.32Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M753.43,880.45l.5,3.18a.41.41,0,0,1-.52-.07C753.58,882.86,752.9,883.52,753.43,880.45Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M642.72,880.4c.07.82.66-.16,1,3.12Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M757.1,899.24c.38,1.38.76,2.77,1.13,4.15C757.86,902,757.48,900.62,757.1,899.24Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M754.28,860.4l.25,2.76c.3.35.3.64-.66,1.8C754,863.44,754.14,861.92,754.28,860.4Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.46"/>
  <path d="M644.93,888.29l-1.28-3.23c.09-.23.19-.45.28-.67C644.26,885.69,644.6,887,644.93,888.29Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M638,844.35l-.64-3.14C637.61,842.26,637.82,843.31,638,844.35Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M753,875.71v0Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M753.1,872.57l-.06,2.07Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.23"/>
  <path d="M754.54,849.05l.3,2.23Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.46"/>
  <path d="M636.54,835.47c-.16-.81-.31-1.61-.47-2.42C636.23,833.86,636.39,834.66,636.54,835.47Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M648.69,907.65s0,.06-.35,0c0-.7,0-1.4,0-2.1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M651.5,818l.3-1.21.75,1.17Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M646,893.5l-.39-1.6Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M647.65,901.35c-.14-.52-.28-1-.41-1.55C647.37,900.31,647.51,900.83,647.65,901.35Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M751.89,833.91a.46.46,0,0,1,.42.12c0,.53-.09,1.06-.13,1.59C752.08,835.05,752,834.48,751.89,833.91Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.46"/>
  <path d="M753.68,866.82c0,.34-.07.69-.11,1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.46"/>
  <path d="M638.49,847.46c-.1-.46-.19-.92-.29-1.37C638.3,846.54,638.39,847,638.49,847.46Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M752.41,836.49c.14.38.27.77.41,1.15C752.68,837.26,752.55,836.87,752.41,836.49Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.46"/>
  <path d="M753.43,880.45l-.21,1.18c-.07-.41-.13-.82-.19-1.23A.62.62,0,0,1,753.43,880.45Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M754.83,856.25l-.17,1.15Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.46"/>
  <path d="M753.41,883.56l.52.07-.26,1C753.58,884.24,753.5,883.9,753.41,883.56Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M711.28,763.53l.41-1.33Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M708,767l.83-.41Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M748.43,782.1l.24.58Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.23"/>
  <polygon points="81.75 349.8 91.57 372.45 81.75 349.8 81.75 349.8" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <polygon points="115.71 361.17 127.36 371.17 115.71 361.17 115.71 361.17" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <path d="M697.54,691.66c1.79-.65,15.77,1.12,3.71,1.62a2.34,2.34,0,0,0-.63,1.52l-.85.89c-1.44-1.63-7.55,5.4-6-2.17Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M693.81,693.52v3.2l-2,.18-1.27-1.14Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="124.04 378.44 120.56 374.82 135.3 368.54 140.78 373.47 131.75 388.67 124.04 378.44 124.04 378.44" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="107.35 423.39 104.24 407.69 110.74 405.04 107.35 423.39 107.35 423.39" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="129.26 384.67 129.85 387.84 129.26 384.67 129.26 384.67" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="127.86 384.65 111.6 391.47 127.86 384.65 127.86 384.65" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="108.96 402.91 104.24 407.69 106.6 399.85 108.96 402.91 108.96 402.91" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="108.94 400.79 106.6 399.85 111.6 391.47 113.03 392.51 108.94 400.79 108.94 400.79" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M687.17,699.58l4.62-2.17c2.52,3.5.29,4.28-2.66,4.66Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M695.45,697.45,697,694.8c.12.35.32,0,1.05,1l-.88,2.44Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M705.87,692.2l1.08,2-2.83,2.13C704.64,695.17,704.67,694.81,705.87,692.2Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="98.46 398.84 101.08 398.84 100.05 400.32 98.46 398.84 98.46 398.84" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="108.32 396.17 106.73 397.75 108.32 396.17 108.32 396.17" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M698.05,694.85l1.72.84c-.57.06,0,0-1.69.16A.67.67,0,0,1,698.05,694.85Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M700.62,694.8l.63-1.52Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M708,694.28c0,.18,0,.36,0,.54C708,694.64,708,694.46,708,694.28Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="137.15 436.98 136.54 438.6 137.5 435.48 137.15 436.98 137.15 436.98" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="113.58 428.11 107.35 423.39 110.05 427.25 110.18 419.62 113.58 428.11 113.58 428.11" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="120.22 436.06 136.54 438.6 137.13 440.95 120.22 436.06 120.22 436.06" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M676.61,759.58c.74-.19,2.95-.73,6-1.3l-1.35-2.76c1,1,5.61.2,7.1,4.23A15.65,15.65,0,0,1,676.61,759.58Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M704.85,759.05l.92,1.74-3.44.81Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M695.78,757.41,693,755C693.55,753.75,698.09,752.87,695.78,757.41Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M674.55,763.81l2.42,1.93-2.38,1.08c0-1,0-2,0-3Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="103.16 432.29 105.22 431.74 104.34 435.26 103.16 432.29 103.16 432.29" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M710.65,756.12,710,757h-1.87l1-1.08Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M675.58,761.17l1-1.56Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M625.38,759.06h3.71l-1.59,1.07C627.14,759.73,627.08,760.58,625.38,759.06Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <path d="M625.38,759.06l.55.55-4.19.08c.18-.22.37-.43.56-.65Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.5"/>
  <path d="M761,923.68c-12.53-5.82-56.15.35-37.7-10.44l3.56-.38c.85.47-.61.15,5.34.39,8.14,0,19.63,0,28.83,0C761.42,915.56,761.41,915,761,923.68Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.7"/>
  <path d="M761.15,917.54c-.06-1.41-.12-2.82-.17-4.24C761,914.72,761.09,916.13,761.15,917.54Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M761,923.68l.15-3c.08,1.06.15,2.12.22,3.18A1,1,0,0,1,761,923.68Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M1184.28,883.56c2.3,1.82,29.33-11.24,27.73-2.69C1200.14,902.75,1199.39,897.59,1184.28,883.56Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1198.92,896.11c15.58-5.06-15.63,10-16.47,22.38C1161.37,903.6,1167.18,914.1,1198.92,896.11Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <polygon points="64.48 353.33 64.48 353.33 64.48 353.33 64.48 353.33" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <path d="M646.32,667.09h0C651.16,670.67,640.78,670.92,646.32,667.09Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.6"/>
  <path d="M1116.79,825.59c10.24,13,2.55,30.23,29.82,29.28C1113.54,860.68,1115,854.72,1116.79,825.59Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1163.18,851.33c.24,1.38.55,2,0,0C1161.6,851.53,1156.69,852.47,1163.18,851.33Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1163.21,851.68c-6.9-2.23-17.08,11.66-1.89,5C1161.26,855.26,1166.34,857.25,1163.21,851.68Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1153.45,854.84c0,2.31-5.21,3.7-6.84,0Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1177.71,816.78c-.87-.49-1.38-1.47,0,0C1180,816.06,1181.34,813,1177.71,816.78Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1158.68,851.71l-3.68,3.08Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1145.61,854.74l-3.15-1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1148.21,822.37l-.78-.42C1147.71,821.91,1148.52,820.61,1148.21,822.37Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1140.9,849.05h0C1141.13,849.35,1139.24,850.19,1140.9,849.05Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1141.43,853.69l-1.06,0C1140.5,853.24,1140,852,1141.43,853.69Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1219.86,813c0-.7,0,0,0-2.1C1220.6,811.22,1220.27,810.75,1219.86,813Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1153,858.54c27.38-7.5,9.6,25.48,33.86,1.44C1178.91,883.57,1175.57,896.74,1153,858.54Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1215.12,872c-1,1.5-7.17,5.77-9.41,2.61Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1203.1,875.21c-1.21.35-1,1.2-.77-2.1C1203.81,872.91,1206.6,870.28,1203.1,875.21Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1203,861.91c.59.8,2.87,5.54-1.3,2.05C1201.71,863.8,1201.78,863.6,1203,861.91Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1196.16,865.67c.32-.65.4-.94,0,0C1195.7,865.76,1195.05,864.23,1196.16,865.67Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1198.92,896.11c-.42,3.21-43.72,17.64-29.5-1.35C1183.19,899.09,1163,865.76,1198.92,896.11Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1177,884.61c-7,2.44-22.89-.49-.3-2.53C1181.43,880.38,1186.34,885.12,1177,884.61Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1164.36,854.32c1.08,1,1.79,1.34,0,0C1162,854.86,1167.52,862.4,1164.36,854.32Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1170.59,858.07l-.43.65C1170,858.9,1168.42,857.08,1170.59,858.07Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1185.34,860l-1.57,0C1184,859.65,1185.43,851.29,1185.34,860Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M899.86,470c6.78-13.38-12.53-6.45-8.75-13.86,10.37-6.58,6.31-32.32,3-11,.61,6.66-20.17,81-5.79,38.74C894.34,477.77,917.05,474.77,899.86,470Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.07"/>
  <path d="M892,409.83c-19.34-16.71-1.17,12.76-1.22,18.62C895.78,425.59,894.66,418.48,892,409.83Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.07"/>
  <path d="M820.14,444.49c1.52-1.32,3.2-2.73,5-4.08-3.94,1.46-7.5,4.38-10.85,7.62A55.2,55.2,0,0,1,820.14,444.49Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.21"/>
  <path d="M786.37,484.11a71.88,71.88,0,0,0,3.93-8.89l0-.07c-3.48,3.68-6.59,6.74-6.05,10.82Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.21"/>
  <path d="M840.45,464.3l-1.9-.16a4.38,4.38,0,0,1-.81,1c-6.53,5.95-28.08,11.41-32.51,1.47a27.76,27.76,0,0,0-3.63,1.07c-1.77,1.16-3.62,2.23-5.5,3.39a5.39,5.39,0,0,1-1.42.61,3.62,3.62,0,0,1-.53.17c2.8-.5,6,1.18,6.08,4.14a10.38,10.38,0,0,1,1.11-.09c-.43.62-.89,1.23-1.36,1.83a.56.56,0,0,0,0,.12,73.42,73.42,0,0,1-2.88,7l10.68-9.54c-1.85,4.93-5.84,7.88-5.65,11.5,5.37-3,5.43-10.79,10.87-8.37-2.62,5.88-5.08,11.88-7.48,15.34,5.62.39,5.87-.31,6.58-.26,12.05-18,9.45-23.64,19.34-20.49C839.78,470.81,841.28,466.93,840.45,464.3Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.21"/>
  <path d="M876.35,354.82v0Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M790.07,442l-.91,1.06C788.91,443.16,788,440.89,790.07,442Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M987.27,666.53l0-.07c-1.64.95-3.3,1.85-5,2.64a5.19,5.19,0,0,1-5.61-.59l-2.53,1.83c7.57.21,8.88,3.24,10.76-.26.46.26.91.53,1.36.8.38-.35.74-.71,1.12-1A3.83,3.83,0,0,1,987.27,666.53Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.42"/>
  <path d="M928.75,589.66c1.29-1.17,2.76-2.23,4.26-3.38a9.9,9.9,0,0,0,2.83-3.65,5.45,5.45,0,0,1,1-6.07c0-.16.07-.31.1-.46a13.51,13.51,0,0,1,.41-2,11.84,11.84,0,0,1,1.52-3.75,3.44,3.44,0,0,1-.29-1.52c-5.14,6.85-10.51,14.74-11.51,20.9V591A4.28,4.28,0,0,1,928.75,589.66Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="3" opacity="0.65"/>
  <path d="M918.58,622.05c1.07-5,1.9-10.1,2.81-15.15a4.25,4.25,0,0,1,.05-2.59c.28-.85.56-1.7.85-2.54,0-1.68,0-3.3,0-4.84C917.8,597,918.61,610.16,918.58,622.05Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <path d="M922.27,596.93c-2.2,2.83-7,6.23-11.41,9.51l13.68-14.58Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M935.48,571.93q3.12-6.52,6.23-13.06C942.86,560,940.64,565,935.48,571.93Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="3" opacity="0.65"/>
  <path d="M945.84,545.77c.6,0,.34,10.65-3.52,11.63C943.67,555.19,941.48,547.91,945.84,545.77Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="3" opacity="0.65"/>
  <path d="M942.32,557.4l3.55-6.38-4.22,10.43C942,560.53,940.9,561.37,942.32,557.4Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="3" opacity="0.65"/>
  <path d="M935.48,571.93c1.38-2.09,2.75-4.19,4.12-6.28-.54.79.61,1.24-4.11,7.37C935.49,572.66,935.49,572.29,935.48,571.93Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="3" opacity="0.65"/>
  <path d="M927,588.13c0,.52,0,1,0,1.57l-1.59,1C926,589.88,926.52,589,927,588.13Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M940.19,564.11l1-1.1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="3" opacity="0.65"/>
  <path d="M946.52,547.93l.9-1.12Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M925,591.75l.42-.48Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M872.55,626c-1.92-6.55,34.9,38.74,28.11-5.86C910.08,603.77,866.65,621.74,872.55,626Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.23"/>
  <path d="M940.19,498.83c-.5-.88-1-1.78-1.63-2.71a30.51,30.51,0,0,0-.2,3.76A5.56,5.56,0,0,1,940.19,498.83Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.16"/>
  <path d="M942.46,513.18a4.2,4.2,0,0,1-1.74-.29c2.17,7.87,2.7,12.38-15.77,15.56-12.56,8.86,23.66,8,20.27-16.16A5.67,5.67,0,0,1,942.46,513.18Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.16"/>
  <path d="M870.59,540.58c6.28-.68,11-7,18.31-8.36C884.08,533.5,877.16,542.89,870.59,540.58Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.77"/>
  <path d="M898.29,524.36c-16.34.7-14.21-14.9,0-4.19l-2.56,1.21Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.56"/>
  <path d="M905.62,523.84c1.71,1.53,8.52.89,9.94,5.74Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.56"/>
  <path d="M933,577.75l-5.92,10.38,5.43-11C932.85,577.18,933.06,577.34,933,577.75Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="3" opacity="0.65"/>
  <path d="M908.25,609.06l-7.33,3.66Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M866.41,539c1.05.19,3.8-2.66,4.17,1.55Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M849.17,531.15h0C847.69,530.94,854.54,536,849.17,531.15Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.85"/>
  <path d="M933,577.75l-.49-.59c.14-1.12.09-1,3-4.12Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="3" opacity="0.65"/>
  <path d="M897.26,529.06l1-2.61Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.56"/>
  <path d="M934.36,574.53l-1.52,1.11Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="3" opacity="0.65"/>
  <polygon points="145.52 374.24 120.8 350.66 145.52 374.24 145.52 374.24" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <polygon points="154.08 380.22 154.88 394.89 154.08 380.22 154.08 380.22" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M725.82,671.3l0,2.58Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.6"/>
  <path d="M797.13,562.91c-3.64-15-19.71-34.85-5.57,1.76C794.12,568.57,804.06,574.23,797.13,562.91Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.21"/>
  <path d="M777.55,564.71a20.72,20.72,0,0,1-1.53,4.68c2.56.05,5.18,1.64,5.1,4.4a4.81,4.81,0,0,1,.47.44,4.67,4.67,0,0,1,3.67-1.83C782.65,569.8,780.09,567.19,777.55,564.71Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="4" opacity="0.21"/>
  <path d="M835.55,495.62l-9.38.45c-1.24-1-.34-3-4.58-6.44-1.44,2.71-2.66,4.7-8.46,7.06-.06-.89-1-1.38-1.06-4.2,6.58-4.47,17.16-9.29,26.25-3.88C838,491.17,838.52,494.09,835.55,495.62Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" opacity="0.39"/>
  <path d="M762.34,509.12c.36-1.2.72-2.39,1.09-3.59h0a23.4,23.4,0,0,0,2.66-2.83c.16.6.32,1.19.49,1.78Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.36"/>
  <path d="M790,538.48l-.93-4.76Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="4" opacity="0.74"/>
  <path d="M792.08,550l-1.41-4.29Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M789.1,542.59c.66.37,1,.06,0,0C788.69,542.13,791.49,539.8,789.1,542.59Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M765,503.94c-.52.53-1,1.07-1.57,1.6L764,504Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.36"/>
  <path d="M789.1,542.59l1,.06c0,.32,0,.63,0,1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M789.06,529.6c0-.36,0-.71,0-1.07l.77.53Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="4" opacity="0.74"/>
  <path d="M786.17,673.47c.4-3.63-18-22.86-7.71-5.22C780.78,672.53,786.22,692.75,786.17,673.47Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M779.35,638.64c.6-3.47-.87-6.12-2.63-6.47Q778,635.43,779.35,638.64Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.34"/>
  <path d="M770.62,617.68a4.59,4.59,0,0,1,.13,1.59c.76,1.58,1.52,3.15,2.3,4.72,4.19-3.1-.6-10.15-3.25-9.72C770.06,615.41,770.33,616.54,770.62,617.68Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.34"/>
  <path d="M773.85,622.85l-1.2.33c.33.67.68,1.35,1,2a11.26,11.26,0,0,1,5.27-1.47l-4.41-16.26C765.34,606.82,774.68,620.17,773.85,622.85Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.34"/>
  <path d="M767.54,599.68a12.6,12.6,0,0,1,.69,3.9,5.78,5.78,0,0,1-.08,1.27c0,.33.09.66.14,1a5.53,5.53,0,0,1,.81-2.85c1.74,2.24,3.72,3,5,1.82l-1.72-6.72c.53-2.36,0-2.22-.25-3.46-2.25.52-3.66,2.46-4.71,4.82C767.46,599.53,767.51,599.59,767.54,599.68Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.34"/>
  <polygon points="189.29 349.5 150.33 361.41 187.03 391.28 191.53 360.44 189.29 349.5 189.29 349.5" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.6"/>
  <path d="M771.09,663.25c3.06-.53,4-1.58,0,0C768.43,666.16,780.12,666.32,771.09,663.25Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M1063.07,684c8.33-7.28.44-11.53-6.81-11-2.8,2.77-5.68,5.43-8.61,8a10,10,0,0,0,.4,1.54c-.48-.23-1-.44-1.4-.64q-3.2,2.82-6.49,5.53c.29.51.58,1,.88,1.58a6,6,0,0,1,5.5-.22c2.21-3.26,8.21-2.77,9.07,1.49C1063,687.93,1067.12,683.2,1063.07,684Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.42"/>
  <path d="M1045.18,699.75c-2,2.17-2.89,4.22-4.87,6.32a24.77,24.77,0,0,1,4.62.41A63.18,63.18,0,0,1,1045.18,699.75Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.42"/>
  <polygon points="429.39 339.78 427.84 339.66 428.21 355.34 427.05 356.11 433.55 351.71 429.39 339.78 429.39 339.78" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.42"/>
  <path d="M1030.05,694.28c.18,1.35,7.16,1.22,2.88,5.35-.72-.3.5-.41-2.59-1.84a16.55,16.55,0,0,0,1.5,4.45c-7.09-7.29-6.37-1.13-11.73-4.83-.46-3.4,2.58-5.93,6.28-5.22h0C1027.43,693.2,1029.45,692.5,1030.05,694.28Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.38"/>
  <path d="M1041,689c-1.12.05-2.14.12-3.07.2-.92.74-1.82,1.49-2.75,2.22a5.22,5.22,0,0,1-5.23.81c1.13,2,10.26,3.31,15.57-1.19l-3-2Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.42"/>
  <path d="M1012.27,731.91c-3.12-3.66-2.53-10.95,1.05-13.06,6.5,3.36-.3,4.18-1.49,9.68,2.52-.06,1.19.55,6.54-.17A37.31,37.31,0,0,0,1012.27,731.91Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.38"/>
  <path d="M1028.82,691.67c-2.37.08-1.74.51-2.43.52l3.66,2.09.52-1-.28-.85A6,6,0,0,1,1028.82,691.67Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.42"/>
  <path d="M1069.37,684.19l-1.38-.84c.38.26.49-1.88,2.13-.06Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.42"/>
  <path d="M1003.47,657.85a5.26,5.26,0,0,1,2.87,2,5,5,0,0,1,.74-.13c-.29-1.83-.44-2-.5-2.57a18.25,18.25,0,0,0-2-.78C1004.18,656.89,1003.82,657.37,1003.47,657.85Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M1097,752.83c-.89.84-.22-2-4.4,1.71,0-.95.1-1.9.08-1.57-8.39-3-7.77-7.21-5.62-15.83,2.82.79-.46,4.56,2.61,7.33v1C1090,747.91,1096.42,747.11,1097,752.83Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.9"/>
  <path d="M1091.56,729.24l-.31-.22a4.88,4.88,0,0,1-3.21,1.33,9.25,9.25,0,0,0-1,1.58c-.43,8.23,1.42,7.76,2.59,12.54C1089.25,731.29,1094.27,732.67,1091.56,729.24Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.9"/>
  <path d="M1092.24,747.62l-2.59-2.1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="257.93 446.92 247.99 441.12 268.81 479.9 235.09 446.43 250.03 488.93 224.18 455.5 226.8 509.7 241.21 485.22 249.2 520.28 263.1 487.62 259.86 522.43 273.38 479.11 275.86 481.87 257.93 446.92 257.93 446.92" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.28"/>
  <polygon points="311.56 476.56 294.15 463.04 311.56 476.56 311.56 476.56" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="330.64 476.27 325.36 466.51 325.59 468.73 308.63 459.47 330.3 482.18 330.64 476.27 330.64 476.27" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="323.98 464.36 278.67 422.32 297.79 443.7 291.9 447.44 323.98 464.36 323.98 464.36" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="294 460.52 286.74 500.32 294 460.52 294 460.52" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="261.33 436.55 249.34 429.15 261.54 444.11 255.49 439.15 292.94 446.38 261.33 436.55 261.33 436.55" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="276.22 480.91 276.79 482.16 279.31 479.82 274.37 508.86 271.2 474.25 276.22 480.91 276.22 480.91" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.28"/>
  <polygon points="274.6 471 264.66 456.42 274.6 471 274.6 471" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.28"/>
  <polygon points="308.63 459.47 326.13 472.81 302.28 453.76 308.63 459.47 308.63 459.47" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.33"/>
  <polygon points="280.24 483.98 287.72 454.74 280.24 483.98 280.24 483.98" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.28"/>
  <polygon points="286.74 500.32 302.85 501.34 286.74 500.32 286.74 500.32 286.74 500.32" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <path d="M854.39,764.37l-7.9,5.81q-2.87-4.09-5.75-8.18c.13-.48.27-1,.4-1.45Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.6"/>
  <polygon points="284.04 456.84 280.04 467.55 284.04 456.84 284.04 456.84" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.28"/>
  <polygon points="283.04 456.85 273.6 452.66 283.04 456.85 283.04 456.85" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.28"/>
  <polygon points="303.41 462.08 294 460.52 303.41 462.08 303.41 462.08" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.33"/>
  <polygon points="280.93 453.77 273.64 451.07 280.93 453.77 280.93 453.77" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.28"/>
  <polygon points="291.14 450.56 289.97 452.05 291.14 450.56 291.14 450.56" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="304.97 456.86 308.62 458.95 304.97 456.86 304.97 456.86" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.33"/>
  <polygon points="289.76 455.83 287.72 454.74 290.96 453.95 289.76 455.83 289.76 455.83" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="285.1 445.87 289.32 446.87 285.1 445.87 285.1 445.87" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="310.2 465.74 307.57 462.61 310.2 465.74 310.2 465.74" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="289.25 487.1 288.52 489.11 288.46 485.77 289.25 487.1 289.25 487.1" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="283.05 455.81 281.95 453.78 283.05 455.81 283.05 455.81" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="312.81 465.22 311.24 465.76 312.81 465.22 312.81 465.22" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="279.73 498.7 279.72 497.61 279.73 498.7 279.73 498.7" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M839.76,760.67l-.45-1.08Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M830.78,833.83c-13.69-1.46,8.81-16.16-8.28-36.77-18.78,20.7-3.16,13-14.14,24.46a29,29,0,0,1,.18,8.79c8.37-6.18,15.13-14.4,15.68-24.49,4.58,5.4-1.81,25.74-3.83,28.59-8,12.34-9-9.17-11.62,13.62C809.46,860.75,834.81,834.89,830.78,833.83Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <path d="M795.38,801.39c-1.4-12.63-24.32-14.27-32.47-17.2l-2,1.2c4.06.66,5.15,3,6.06,5.4,5.34.83,10.31,4.85,13.87,9.92l3.88,1.51a51.8,51.8,0,0,1,8,1.9,4.87,4.87,0,0,1,4.92,2.14,19.88,19.88,0,0,1,3.38,2.33,22.6,22.6,0,0,1,1.72,1.66c.2-.68.23-1.44.37-2.15C799.51,804.91,797.32,803.1,795.38,801.39Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.46"/>
  <path d="M805.29,813.6a7.31,7.31,0,0,0-2.23-5.5l-.86,1.64A22.27,22.27,0,0,1,805.29,813.6Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M813.09,852.16c4-5.81,11.68-7.82,16.73-15.17,3-.47,5.9-8.32,10.53-4.75,0,.36,0,.71,0,1.06a69,69,0,0,1-19.77,18.38c-.94.11-1.7.33-4.42,1.51l-2,0h-.06C813.86,852.76,812.75,853.21,813.09,852.16Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <polygon points="270.96 504.96 275.68 488.76 270.96 504.96 270.96 504.96" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.28"/>
  <polygon points="340.37 481.1 343.51 482.62 340.49 477.39 340.37 481.1 340.37 481.1" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="337.16 508.07 336.92 509.72 337.16 508.07 337.16 508.07" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="332.14 523.27 332.14 523.27 332.14 523.27 332.14 523.27 332.14 523.27" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="336.35 519.63 334.28 520.15 336.35 519.63 336.35 519.63" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="337.36 524.25 338.45 523.28 337.36 524.25 337.36 524.25 337.36 524.25" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <path d="M808.11,833c-.13.68-.28,1.36-.46,2a4.56,4.56,0,0,1-1.84,2.77c1.91,2.53,2.21,3.67,3.68,3.9C827,823.35,819,822.94,808.11,833Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <path d="M808.77,848l2.49,4Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M813.09,852.16l1.06,1-2.11-1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M754.24,864.81c.1-.55.19-1.1.29-1.65h1.62c0,.35,0,.7,0,1.05Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.46"/>
  <path d="M755,890.32c-.11-.71-.22-1.42-.34-2.14C754.81,888.9,754.92,889.61,755,890.32Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="312.37 504.17 295.56 508.07 312.37 504.17 312.37 504.17" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="295.57 508.09 300.28 515.47 295.57 508.09 295.57 508.09 295.57 508.09" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="309.55 523.9 312.12 522.37 309.55 523.9 309.55 523.9 309.55 523.9" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="299.21 516.97 302.35 514.88 299.21 516.97 299.21 516.97 299.21 516.97" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="295.02 519.03 299.21 516.98 295.02 519.03 295.02 519.03 295.02 519.03" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="335.85 549.82 335.85 549.82 335.85 549.82 335.85 549.82 335.85 549.82" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="331.11 556.2 331.11 556.2 331.11 556.2 331.11 556.2 331.11 556.2" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="325.88 558.8 327.45 558.8 325.88 558.8 325.88 558.8 325.88 558.8" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="329.95 484.77 329.95 484.77 329.95 484.77 329.95 484.77" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="311.91 503.34 313.37 508.16 311.91 503.34 311.91 503.34 311.91 503.34" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="306.51 513.76 311.25 512.28 306.51 513.76 306.51 513.76 306.51 513.76" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <path d="M1078.15,799.62c2-16-6,.12-9.41-1.3,2.7,2.39,2.56,5.2,1.42,8.09a3.85,3.85,0,0,1-1.34,2.79c-1.86,3.39-4.26,6.74-4.26,9.51.58,1.81,6.47-5.73,3.64,2.07l.56,1.08,1.51-2.69c4.59,2.66-.28,9.57.54,12.07l2.11.54c6.87-9.08-3.76-22.48,2.75-27.12C1074.29,821.5,1082.87,803.55,1078.15,799.62Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1065.53,825.48l-3.06,5.26Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <polygon points="418.92 509.61 447.71 493.96 418.92 509.61 418.92 509.61" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <polygon points="384.93 542.07 384.93 542.07 384.93 542.07 384.93 542.07" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <path d="M1076.74,735.08a5.29,5.29,0,0,1,3.48,3.45c.08.25.18.51.27.76.26-.19.52-.37.78-.58,1.14-5,1.08-8.06,3-9.78l0,0-.1.11a4.94,4.94,0,0,1-5.32,1,5.61,5.61,0,0,1-2.93,2C1076.09,733,1076.4,734.06,1076.74,735.08Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.38"/>
  <path d="M1080.73,740a30.17,30.17,0,0,1,1.6,5.37,5.43,5.43,0,0,1,.53.17h1c-.1-4.38-2-4.8-2.1-6.27C1081.45,739.49,1081.09,739.73,1080.73,740Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.38"/>
  <polygon points="447.08 444.15 419.68 457.57 447.08 444.15 447.08 444.15" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <polygon points="477.25 439.48 458.66 448.94 477.25 439.48 477.25 439.48" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <path d="M1087.06,773.18,1067.15,762l-52.32,37.5,72.23-26.3Zm-33.7,13.62,0,2.83,0-2.83Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <polygon points="357.25 482.99 370.33 490.97 357.25 482.99 357.25 482.99" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="370.32 485.09 357.25 482.99 370.32 485.09 370.32 485.09" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="358.81 464.7 345.3 476.16 358.81 464.7 358.81 464.7" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="422.07 450.06 433 447.55 422.07 450.06 422.07 450.06" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <polygon points="369.28 466.25 382.31 462.04 369.27 467.83 369.28 466.25 369.28 466.25" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="358.3 472.54 363.52 469.93 358.3 472.54 358.3 472.54" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="444.55 442.18 444.55 442.18 444.55 442.18 444.55 442.18" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <polygon points="438.31 443.8 435.68 445.88 438.31 443.8 438.31 443.8" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <polygon points="355.13 487.72 352.45 488.83 355.13 487.72 355.13 487.72" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="366.13 467.27 369.23 468.33 364.57 469.91 366.13 467.27 366.13 467.27" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="369.23 468.33 368.01 463.83 369.23 468.33 369.23 468.33" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="366.13 467.27 362.43 469.87 366.13 467.27 366.13 467.27" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="357.87 497.26 357.87 497.26 357.87 497.26 357.87 497.26 357.87 497.26" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="438.3 443.81 437.24 444.32 438.3 443.81 438.3 443.81" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <polygon points="358.75 499.56 358.44 499.71 358.75 499.56 358.75 499.56" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="352.77 518.41 352.55 521.75 352.77 518.41 352.77 518.41" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="343.07 479.37 342.98 477.08 344.27 479.32 343.07 479.37 343.07 479.37" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="354.06 480.73 353.42 480.27 354.06 480.73 354.06 480.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="340.49 477.05 342.98 477.45 337.58 464.25 340.49 477.05 340.49 477.05" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="331.52 458.56 334.57 460.54 285.16 425.55 331.52 458.56 331.52 458.56" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="339.92 462.72 336.78 463 343.14 475.15 357.26 465.28 339.92 462.72 339.92 462.72" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="343.15 477.23 343.26 476.72 343.64 477.27 343.15 477.23 343.15 477.23" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M1139.31,921.16c-3.27,1.42,18.6-3.39,5.8,1.35C1142.45,921.76,1139.57,927.57,1139.31,921.16Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1067.66,855s.66.82-.26.36Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1072.92,831.78c.59-7.27,1.45-15.61-.52-23.53,10.37-3.3,6.91,40.49,8.83,52l-4.11-.29c-.2-.35.51-.62-.54-2.08C1075.37,847.16,1066.73,847.3,1072.92,831.78Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1076.58,857.93l.53,1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1079.09,786.18l.84-.21c2.43-2.49,6.25-3.47,8.6-2.21,8.65,21.29,11,46.15,25.69,65.28,1,.24,1,.7,1.72,2.6-.4.66-1.2.63-1.88,1.09,1.35.32,0-2.15,8.22,3.87-6.57,11.34-8.39,14.88-14.66-1.4-.56.86-1.11,1.72-1.66,2.59-1.82.39-1.32.15-6.35,1.29,2.09,4.63,13.66-1.79,10.49,9.68-2.77-1.28-3.84-2-8.89-4.21-.28,2.46.46,3.5,2.66,7.57-2.25-.83-2,.41-8.17-2.31-1.67,23.74-2.68,4-5.13-6.4-1,2.28-1.57,5-2.5,9.15-1.89-21.87-10.06-44.22-8.4-67.45,0-.34,0-.69,0-1C1080,804.29,1076,790.81,1079.09,786.18Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1163.37,884.08c-6.17,5.85,18.5,17.68,3.29,27.39C1157.17,907.36,1120.55,874.76,1163.37,884.08Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1144,882c1.29,14.79-23.24-26.51,9-23.44C1150.11,891.68,1140.66,856.32,1144,882Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1152.89,858.42c-7.5.87-14.52-1.11-17.42,13.48C1128.09,864.76,1137,850.81,1152.89,858.42Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1142.21,887.39l.78-1.73C1143.07,886.54,1150.82,902.14,1142.21,887.39Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1138,856.38l-2.4,1.51C1133,857.3,1123.48,856.92,1138,856.38Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1147.72,896.07l4.56,5.43Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1138.84,876.72l1.29,5.56Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1136.54,874.76l2.28,1.94Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1122.72,857.14l-.44-.54,4,.4Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1134.43,858.76c-.34,1.29-.67,2.59-1,3.89C1133.76,861.35,1134.09,860.05,1134.43,858.76Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1133.45,869.32c.18.6.36,1.21.53,1.81C1133.81,870.53,1133.63,869.92,1133.45,869.32Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1163.88,910.24l1.58.64Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1161.54,909.31l1.29.53Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1135.33,873l.85.08C1136,873.51,1136.38,875.09,1135.33,873Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1156.51,905.55l1.12.94Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1080.29,820.79c.82,9.4,3.39,18.22,4.45,30.89-.83-.82-.93-3.41-4.5-3.7.19-2.5-4.67-6.09,1.57-7.31h0c-5.74-7.66-5-25.14-2.14-35.56C1080.1,807.9,1080.18,815,1080.29,820.79Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1085.14,852.64c.26,1.24.52,2.49.77,3.73l-.93-.06C1085,855.09,1085.08,853.86,1085.14,852.64Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1086.72,861l.27,1.13Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M759.29,792c2.25-2.57-3.15-3.38-3.58-4.75l-.67.09c.69,2,1.38,4.07,2.06,6.11A11.4,11.4,0,0,1,759.29,792Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.46"/>
  <path d="M761,791.26h0c-.57-.45-1.15-.89-1.73-1.33l-1.86,3.25A11.24,11.24,0,0,1,761,791.26Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.46"/>
  <path d="M752.42,793.6l0-2,1.41.84Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.46"/>
  <path d="M732.15,913.25l-1.77,0Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M728.41,913.23l-1.6-.37Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="244.57 432.21 232.59 428.81 227.94 432.39 224.7 423.57 147.56 391.61 149.84 401.29 183.82 414.6 198.93 441.15 188.97 457.2 151.97 443.72 134.78 414.84 114.92 397.38 154.38 454.25 202.63 467.26 231.05 436.43 244.57 432.21 244.57 432.21" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M736.71,768c11,.36,21.81,5.59,31.73,9.93,31.11-2.23,24.09,25.25,37.78,16.78-1.14-13.84-24.46-25.38-9.33-33-4.94,11,4.73,13.8,13.36,20.85,9.09,11.88.13,24.36-14.87,18.8-9-20-13.45-15.76-38.74-24-4.88-3.79-14-3.63-19.89-8.85C736.73,768.37,736.72,768.2,736.71,768Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <path d="M757.16,777.4c9.92,4.35,22.88,3.05,32.41,11.49-4.19,1.32-13.25-.37-17.22-5.27C767.92,783.63,763,780,757.16,777.4Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.23"/>
  <polygon points="138.72 431.76 148.66 446.91 147.08 446.93 142.37 443.27 138.72 431.76 138.72 431.76" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M740.42,770.61c3.34,2.18,12.8,1.66,16.22,6.77Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M729.42,760.69l1.07,0,5.73,7.34Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="138.19 434.37 141.83 441.19 137.15 436.98 138.19 434.37 138.19 434.37" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M772.34,786.27l-8.91-2.07,8.92-.58C772.57,784.17,772.9,784.58,772.34,786.27Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.46"/>
  <path d="M724.21,757l4.71,3.66Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M736.75,768.53l2.63,2.07Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M701.22,677.54c2.71-.48,6.9-4.38,6.79,2.09C703.72,685.51,703.11,679.44,701.22,677.54Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.6"/>
  <path d="M698.05,694.85l0,1L697,694.8Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="143.94 391.07 141.07 389.61 145.58 391.22 143.94 391.07 143.94 391.07" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M718,702.39l-2.44-.51Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M720.09,702.94l-1.07-.37Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="111.05 406.64 135.06 429.14 111.95 421.43 110.05 416.03 110.05 407.18 111.05 406.64 111.05 406.64" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="110.05 416.03 111.66 424.4 110.02 418.19 110.05 416.03 110.05 416.03" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="111.05 406.64 110.04 407.18 111.02 402.45 111.05 406.64 111.05 406.64" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="109.74 411.88 110.09 414.04 109.83 413.94 109.74 411.88 109.74 411.88" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="111.02 402.45 109.97 403.01 108.96 402.91 111.04 401.49 111.02 402.45 111.02 402.45" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="109.81 417.13 110.02 418.19 108.71 417.75 109.81 417.13 109.81 417.13" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="128.6 388.76 113.64 403.52 114.56 397.21 128.6 388.76 128.6 388.76" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="119.42 390.52 115.91 394.21 115.78 393.67 115.96 392.9 119.42 390.52 119.42 390.52" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M710.43,702.52c-.32-.2-1.49.6-2.69-.77l4-.09Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="122.55 389.01 119.92 390.16 122.55 389.01 122.55 389.01" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="115.78 393.67 115.9 394.21 111.04 401.49 109.11 401.99 108.94 400.79 113.03 392.51 115.78 393.67 115.78 393.67" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="138.19 434.37 137.14 435.42 113.58 428.11 113.35 426.74 138.19 434.37 138.19 434.37" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="138.15 432.32 112.06 421.3 138.69 430.21 138.72 431.76 138.15 432.32 138.15 432.32" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="112.12 424.33 112.65 426.43 111.66 424.4 112.12 424.33 112.12 424.33" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M1177.35,906l2.23-1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1153.08,801c-.59-.19-.59,1-2-.36C1151.82,800.3,1151.46,799.79,1153.08,801Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1113.17,822.9l-1.05-1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1116.63,852.57l-4.11,1.36c-.13-1.13.71-1.63,3.42-2.29Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1115.54,850.7l-1.32-1.66c0-.65,0-1.29.08-1.94C1113.92,848.09,1116.59,846.64,1115.54,850.7Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1117.89,832.3l1.4.93-.48.48-.93-1.4Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1125.38,848l.36-.31S1126.32,848.65,1125.38,848Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1120,855.11l-1.23-.75Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1163.88,854.33v2C1162.93,856.05,1159.28,857,1163.88,854.33Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1151.35,856.9l-1.55-.56Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1144.56,881c-5-.12,2.69-21,10.37-15.8C1175.15,874.45,1157.38,882.24,1144.56,881Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1154.48,882c-3.53-7.85,18.22-8.92,8.93,1.57C1161.56,884.64,1161.35,885.12,1154.48,882Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1157.56,867.37c1.37-2.31-8.6.88-4.65-8.39C1153.45,859.86,1154,860.45,1157.56,867.37Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1163.4,883.56l2.55-2.09C1166.09,882.91,1167.33,883.23,1163.4,883.56Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1163.89,856.36l2.61,2.08Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1164.93,896.61c4.3-4.7-14.21-8,9.42-12C1171.85,896.73,1186.65,899.86,1164.93,896.61Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1179.54,885.09l2.15-1.49Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1177,884.61l1.58.48Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M907.18,459.89a29.08,29.08,0,0,0,15.25,3.36L922,463c-6.25-1.32-12.43-3-18.55-4.8-12.77.52-20.56,7.6-3.75,5.63C900.22,460.7,903.58,457.88,907.18,459.89Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M914.61,472.74c4.52,2,8.89,2,11.17.3A43.09,43.09,0,0,1,914.61,472.74Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.06"/>
  <path d="M889.23,488.52a4,4,0,0,1,1,.09,4.42,4.42,0,0,1,.48-1.06l.22-.31c.06-.12.11-.24.18-.36l.5-.65a18.57,18.57,0,0,1-3.18,2c.05.11.1.22.16.33Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2"/>
  <path d="M921.15,481.31a4.46,4.46,0,0,1,.66-.44c-3.3-4.21-10-3.19-13.51-4.19-5.73.07-10.71,4.67-15,8.22a4.67,4.67,0,0,1,.81-.31l1.12-.16c.25-.44.58-.51,1.22.07a3.53,3.53,0,0,1,.92.34,3.69,3.69,0,0,1,1.61,1,3.75,3.75,0,0,1,.87,1.26c7.43,1.22,10.23-3.29,13.15-7.2a4.35,4.35,0,0,0,1.34,2.19,5.14,5.14,0,0,1,1.37-.62l1.16-.16a4.43,4.43,0,0,1,.9-.35l1.33-.18a3.68,3.68,0,0,1,1.93.43Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2"/>
  <path d="M902.14,468.52a4.72,4.72,0,0,1-2.08-2.16l-3.69,2.93c-4.9,2.5-4.18,5.57-5.18,6.91,8,.32,13.36-3.5,19.1-3.64,0-.26,0-.5,0-.74A36,36,0,0,1,902.14,468.52Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.13"/>
  <path d="M911.92,484.91a.84.84,0,0,0,.06-.14c.33-1.61.65-3.23,1-4.84-4.37-1.28-4.73,4.84-6.29,5.75l5.14-.53Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="4" opacity="0.71"/>
  <path d="M878.27,514.28c20.72,18.05-34.41,28.28-21.4,12.32C856.46,519.11,868.59,445.65,878.27,514.28Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.09"/>
  <path d="M925,490.89c5.9-3.82,8.84-2.07,13.07,4.18C934.63,494,931.5,490.07,925,490.89Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.16"/>
  <path d="M857.54,496.66l1.05-7.87C861.78,490.17,859,492.66,857.54,496.66Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="4" opacity="0.78"/>
  <path d="M854.17,508.06c-.09.13-.18.24-.27.35S853.24,507.61,854.17,508.06Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M846.11,511.59l-.35-.27C845.74,511.27,846.56,510.66,846.11,511.59Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M934.05,494.28l.52.53Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.16"/>
  <path d="M908.3,476.68l-1.66,0Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M831.89,472.57c11.56,1.57,20.63,9.16,1.53,6.69C816.78,480.07,816.26,476.32,831.89,472.57Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="8" opacity="0.26"/>
  <path d="M831.37,472.57c-6.13,1.76-7.21,13.13-15.14,13.65C818.82,481,822.3,466.84,831.37,472.57Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="4" opacity="0.6"/>
  <path d="M816.23,486.22l1,0c1.16-.81,2.78-.78,3.67-2.05a3.31,3.31,0,0,1,1,0c1.23,4.54-4.84,5.26-9.89,8.33Q814.16,489.35,816.23,486.22Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M819.37,479.06l-.49.56c.09-.19-.87-.16-.08-1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M952.87,667.5a26.43,26.43,0,0,1,3.57.56,5.34,5.34,0,0,1,1.26-2.7c.5-.59,1-1.16,1.53-1.73a1.62,1.62,0,0,1-.1-.18c-.9.36-1.81.71-2.74,1a4.55,4.55,0,0,1-4.4-.92c-.44,1.34-.84,2.61-1.23,3.83C951.46,667.4,952.17,667.44,952.87,667.5Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M948.36,667.3c-2.6-2.27-8.31-1.28-11.89.76A98.21,98.21,0,0,1,948.36,667.3Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M950.13,658.91c-.73.27-1.47.53-2.2.78,1.43,1.65,2.12,3.84,3.73,5.34l.42-1.42A5.34,5.34,0,0,1,950.13,658.91Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <path d="M944.88,667.35c1.55-.48,2.18-.84,2.54-.06,1.18,0,2.36,0,3.54.09l.7-2.35-3.94-5.26c-.46.16-.91.33-1.37.48-2.64,4.8-7.92,4.71-9.28,7.74C939.67,667.7,942.27,667.47,944.88,667.35Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <path d="M937.17,668c2.82-2.86,8.6-2.22,9.19-7.73h0q-5.45,3.94-10.9,7.88h.24Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <path d="M952.3,684.28a30.34,30.34,0,0,0-4.23.32l-.15.07v0c-1,.14-2.11.32-3.16.51a5,5,0,0,0-.67,2.33,6.78,6.78,0,0,0,5.06,1.19,26.41,26.41,0,0,1,3.14-2A4.39,4.39,0,0,1,952.3,684.28Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.42"/>
  <path d="M965.79,676a9.27,9.27,0,0,1,.07,3.48,16.64,16.64,0,0,0,2.46-2.13l1-3.68C968.16,674.5,967,675.28,965.79,676Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.42"/>
  <path d="M983.93,668.27c-.55.28-1.1.57-1.66.83a5.59,5.59,0,0,1-3.34.46V670l-3.09-.92c-1.23.9-2.48,1.79-3.72,2.67l12.78.4c.65-.62,1.3-1.25,2-1.86A6.76,6.76,0,0,0,983.93,668.27Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.42"/>
  <path d="M920.08,613.71l-.36.31C919.67,614,919.14,613.09,920.08,613.71Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M941.21,685.73c-.2,1.4-.51,2.81-.87,4.29l-.09.29c2.19-1.66,2.51-3.23,1.64-4.69Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.23"/>
  <path d="M801.91,552.7c.73.72.87.67,0,0C802,552.52,804.34,551.66,801.91,552.7Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M899.47,597.35c-6.18,1.6-4.06,4.62-39.68,1.44C862.4,613.28,917.31,606.65,899.47,597.35Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="4" opacity="0.23"/>
  <g opacity="0.75">
    <path d="M881.25,565.4h0c3.36-1.35,6.64-2.7,9.87-4.09-3.55.41-7.13.55-10.73.73-1.56,1.14-2.69.72-4.46.27-1.28.1-2.57.22-3.86.37l-3,.39s0,0,0,0a3.62,3.62,0,0,1-.06.46c-1.61.93-1.31.42-3.18,0-1.4.18-2.81.34-4.22.47a3.87,3.87,0,0,0-.37.24,3,3,0,0,1,0,.67c3.85.1,7.68.53,10.94.66A88.6,88.6,0,0,0,881.25,565.4Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5"/>
    <path d="M860.6,564.88a3.27,3.27,0,0,1,.63-.66v-.07l.13-.15a40.89,40.89,0,0,1-5.85.1l2.9.79C859.13,564.87,859.87,564.87,860.6,564.88Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5"/>
  </g>
  <path d="M720.34,684.26c-.09.12-.18.24-.28.35S719.4,683.8,720.34,684.26Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M767.59,696.38c-19.41,9.23,6.36-29.82-30.08-7.2C720.68,668,760.41,649.88,767.59,696.38Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.6"/>
  <path d="M731.52,681.23h0C736,681.36,750.08,679.68,731.52,681.23Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.6"/>
  <path d="M819.18,595.07c-4.4,5.69-28.5-26.13-26.48-8C792.76,603.14,821.73,617.47,819.18,595.07Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.15"/>
  <path d="M795.82,669.63c2.36,8.7-10.33,1.29-9.46,5.87C788.63,679.27,792.6,680.36,795.82,669.63Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M775.35,628.49a5,5,0,0,1,.25.59A14.84,14.84,0,0,0,778,632.6c.65,7.16,2.77.67,1.91-.63q-.51-4.11-1-8.24l-3.46-.63-.55,1.09A7.69,7.69,0,0,0,775.35,628.49Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.34"/>
  <path d="M778.46,636.5a7.9,7.9,0,0,0,.63.8c.55,2.8.81,2,1,2.64,0,.13,0,.26,0,.4.18.38.37.75.57,1.13l1.61,1.55q-1.17-5.52-2.33-11l-1.26,3.17a1,1,0,0,0-.41.94Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.34"/>
  <path d="M774.53,607.47l-3.33,1.58c-2.2-.64-1.88-6,3.21-3.13Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.34"/>
  <path d="M798,563.05l1.45,2.72Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M772.39,598.09c0-.71.07-1.42.11-2.13C772.46,596.67,772.42,597.38,772.39,598.09Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.34"/>
  <path d="M774.41,605.92h-.55a.75.75,0,0,1-.15-1l.4-.11Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.34"/>
  <g opacity="0.79">
    <path d="M799.65,566.53c.87,2.68.59,1.78.9,2.78C801.82,568.35,800.2,567.13,799.65,566.53Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
    <path d="M799.54,566.18c-.13.05,0,.17.11.35C799.61,566.42,799.58,566.31,799.54,566.18Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
    <path d="M807.25,576.25c-2.24-1.76.78-.73-6.7-6.94C803.17,572.67,801.14,571.17,807.25,576.25Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  </g>
  <path d="M836.1,485.16c.21-1.09,21.75,11.85,6.54,11.69C834.82,496.15,838,496.53,836.1,485.16Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.56"/>
  <path d="M820.41,488.27c.51-1.37,1-2.74,1.55-4.11l12-.06C833.31,487,832.62,485.14,820.41,488.27Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M826.66,486.2c1.9-.73,2,.33,7.34-2.1l2.1,1.06,2.09,3.64-10.42-2C827.16,487,826.67,487.06,826.66,486.2Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="4" opacity="0.69"/>
  <path d="M826.66,486.2l1.11.6L823,488.28Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M775.26,628.31a5.38,5.38,0,0,1,.62,1.92,5.2,5.2,0,0,1,.4.82c.27.69.55,1.38.82,2.06.38-.32.7-.53.94-.51-1-2.8-2.07-5.61-3.11-8.41l-1.53.49C774,625.89,774.62,627.11,775.26,628.31Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.34"/>
  <path d="M786.37,675.51c-3.73,9.58-2.09,6.1,0,0C786.89,671.85,790.27,687.41,786.37,675.51Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M778.69,635.13c.13.73.26,1.45.4,2.17-.29-.73-.57-1.46-.85-2.2A2.27,2.27,0,0,1,778.69,635.13Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.34"/>
  <path d="M769.76,614.09c2.36-6.86,1.87-5.7,4.1-8.18l-.15-1a41.43,41.43,0,0,1-5-4.48,17,17,0,0,0-.36,5.9C768.73,608.94,769.19,611.52,769.76,614.09Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.34"/>
  <path d="M770.22,613.66l.48-2c.53,1.75,1,3.5,1.58,5.25C771.59,615.82,770.9,614.74,770.22,613.66Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.34"/>
  <polygon points="174.7 348.62 174.7 348.62 174.7 348.62 174.7 348.62" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.23"/>
  <polygon points="157.76 351.96 158.69 352 157.76 351.96 157.76 351.96" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.23"/>
  <path d="M769.14,688.65c-.87-.42-1-.31,0,0C769.14,689.12,766.32,691.2,769.14,688.65Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M781.9,678.69c-.25.34,1,.71-.4,1.6C781.77,680,780.62,679.83,781.9,678.69Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M767,679c.13-.26-.82-.38.1-1.14C767.2,678.19,767.86,678.28,767,679Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M753.77,667.77l-.45.05S753.43,666.79,753.77,667.77Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M770.19,667.86s.65.81-.26.36A4.06,4.06,0,0,1,770.19,667.86Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M986.26,682a5.37,5.37,0,0,1-4-3.6,5.16,5.16,0,0,1-3.05-2.29c-4.52,6-3.14,11.93-.34,16C978.5,687.3,982.05,684.31,986.26,682Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.42"/>
  <path d="M1013.85,669.18c-1.72.25-3.33.43-4.78.57-2.7,1.8-5.39,3.62-8,5.5a2.5,2.5,0,0,0,1.31.2h0c1-1.12,2.58-.73,3.67-1.56,7.87.24,5.93-3.6,8.32-4.67C1014.19,669,1014,669,1013.85,669.18Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.42"/>
  <path d="M1026.39,692.19l-6.28,5.22c-5.93-3.07-12.65-4.23,1.88-8Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.42"/>
  <path d="M1001.29,675.46h1.05a35.75,35.75,0,0,1-1.14,4.56C1000.45,679.11,1000.2,679.61,1001.29,675.46Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.42"/>
  <path d="M1014.33,669.22l-.48,0,1.53-3.71Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.42"/>
  <path d="M1008.08,673.91l2.63-.55-1.85,2.5Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.42"/>
  <path d="M1082.46,747.05a5.23,5.23,0,0,1-.83,2.6l1.79-.55c-.19-1.19-.38-2.39-.56-3.59l-.52-.07A8.1,8.1,0,0,1,1082.46,747.05Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M1088.53,783.76l-8.6,2.21c-7.25-3.89,9.65-3.67,3.9-12.88a1.24,1.24,0,0,1,1.62,0l1.61.06C1087.73,775.37,1089.61,776.2,1088.53,783.76Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1083.9,745.5c0-.52,0-1,0-1.56C1083.91,744.46,1083.9,745,1083.9,745.5Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M1106.36,837.53l-.49-1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1087,735.06v0Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M799,802.52c39.92-11-16.61-28.09-2.07-40.78h0c12.87-.63,13.83,7.11,18.74-9.47,3-3.4,11.09,11.66,11.1-5.74,5.2,2.23,8.72,21,14.56,27.76-3.2,1.9-5.18-9.52-10.43-3.68-3.65-4.2-6.54-10.75-13.42-13.62,1.78,7.47,36.69,55.32,14.46,47.58-10.9-9.24-15.53-27-28-34.76,0,7.18,19,21.09,9.32,28.48l2.4,1.9C813.63,804,810.9,804.57,799,802.52Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <path d="M830.87,770.6l1.67-3.5a25.21,25.21,0,0,1,8.76,7.18c4.16,4.4,7.67,12.85,9.94,20.38C849.89,807.27,832.94,776.13,830.87,770.6Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <path d="M840.35,832.24c-2.59,1.15-5.35,4.79-10.53,4.75.28-1.13.08.14,1-3.66,4.6-4.27,8.46-10.32,8.36-18.28,2.14-1.4,3.77-8.32,7.91-8.89-.35,2.61-.7,5.21-1,7.82C846.64,818.47,842.1,825.37,840.35,832.24Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <path d="M835.38,723.67c-1.11,1.24-2.06,2.39-2.89,3.46a20.27,20.27,0,0,1,1.71,9.47,2.78,2.78,0,0,1,3.28-.2C842,733.12,835.83,726,835.38,723.67Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M832.44,741.35a12,12,0,0,1-5.26,4.33,5.66,5.66,0,0,1,1.7,1.25l1.22.29,2.8-4.35a6.37,6.37,0,0,1-.35-1.69C832.51,741.24,832.48,741.3,832.44,741.35Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="280.4 467.77 277.25 474.45 280.18 467.48 280.4 467.77 280.4 467.77" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.28"/>
  <path d="M847.14,806.16c-1.88,2-3.92,6-7.91,8.89.74-3.55,3.84-9.12,5-15.32C846.08,800.28,845.75,801.83,847.14,806.16Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <path d="M835.59,781.06c4.44,2.74,12.71,21.48,15.65,13.6C852.44,812.81,839.56,786.8,835.59,781.06Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <path d="M851.26,793.62l-7.86-17.76Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <path d="M839.22,816.09c-.77,5.48-1.22,13.19-8.35,17.24C833.34,829.32,836.44,822.09,839.22,816.09Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <path d="M843,825c1.24-4.09.62-4.06,3.15-11C845.06,815.93,846.77,822.17,843,825Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <polygon points="276.76 473.53 279.26 478.86 276.19 473.64 276.76 473.53 276.76 473.53" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.28"/>
  <polygon points="276.76 473.53 274.72 471.46 276.76 473.53 276.76 473.53" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.28"/>
  <polygon points="276.21 480.9 276.76 479.63 276.22 480.91 276.21 480.9 276.21 480.9" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.28"/>
  <path d="M842.35,775.32l-1-1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <polygon points="330.53 474.39 330.63 478.07 330.53 474.39 330.53 474.39" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="278.83 422.29 288.26 421.8 278.83 422.29 278.83 422.29" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="325.47 475.03 327.03 478.42 325.47 475.03 325.47 475.03" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="284.55 424.89 286.15 423.39 288.24 426.22 284.55 424.89 284.55 424.89" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="302.41 489.84 301.89 490.85 302.41 489.84 302.41 489.84" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <path d="M834.28,735.62a10.39,10.39,0,0,1-1.84,5.73,11.12,11.12,0,0,1-.93,1.17l1.39.35c3.56-6.52,3.09-4.25,4.27-6.73C835.91,735.29,835,735.23,834.28,735.62Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="279.69 467.04 276.29 467.55 278.26 464.23 279.69 467.04 279.69 467.04" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.28"/>
  <polygon points="280.4 467.77 280.17 467.48 280.4 467.77 280.4 467.77" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="279.75 465.25 278.26 464.23 280.13 464.03 279.75 465.25 279.75 465.25" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.28"/>
  <path d="M820.86,817.12c-4.65,4.86-9.86,9.73-12.24,12.59-.09.84-.22,1.68-.37,2.53,1.51-.25,4.91-1.75,11-5-1.43,6-4.69,7-6.91,9.64,9,13.2,11.22-19.89,15.32-26C823.16,809.38,823.53,818.71,820.86,817.12Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <path d="M827.7,810.87c-2.15,3.06-3.87,4.66-7.13,10.19C821.59,817.12,821.64,793,827.7,810.87Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <path d="M808.39,831.45c-.12.8-.28,1.6-.46,2.41l5.25-4.05C809.9,830.46,809.05,831,808.39,831.45Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <path d="M828.21,839.13l-2.55,3.07Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <path d="M829.23,838l-1,1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <path d="M772.34,786.27c0-.87,0-1.74,0-2.6l17.21,5.22c.14.91.28,1.81.43,2.71A21.63,21.63,0,0,1,772.34,786.27Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="302.35 514.88 299.76 514.89 302.35 514.88 302.35 514.88" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <path d="M1069.25,798.31l-2.61-4.8c1.13-2.8,2.39-3.79,7.46-4.64C1077.18,790.06,1074.9,800.71,1069.25,798.31Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1075,793.6c0-1.69-1.92-1.53-1.58-6.27,3.22-.13,5.9,1.06,5.51,7.88C1078,795.36,1077.36,794.66,1075,793.6Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1075.53,809.31l-3.13-1.06,3.6-5.41C1075.84,805,1075.69,807.15,1075.53,809.31Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1078.17,795.16l.79.05c-1,.66,1.72,2.86-.29,7.29-.08-1.18-.54.23-1.06-4.16C1077.8,797.28,1078,796.22,1078.17,795.16Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1072.4,811.92c.52.87,1,1.74,1.58,2.61C1073.45,813.66,1072.92,812.79,1072.4,811.92Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1072.38,809.83l1.29.49-1.3,1.07C1072.38,810.87,1072.38,810.35,1072.38,809.83Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1078.67,802.5c.18.06.21-.76,1-.51,0,.7,0,1.39,0,2.09Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1077.61,798.34v1l-.76-.51Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <polygon points="425.73 500.76 435.67 497.63 425.73 500.76 425.73 500.76" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <path d="M1023.46,815.67c5.49-2.21,3-1.68-5.63-.8Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="443.5 494.48 446.13 493.46 443.5 494.48 443.5 494.48" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <polygon points="438.28 496.07 438.93 494.63 438.28 496.07 438.28 496.07" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <polygon points="437.24 497.64 438.28 497.11 437.24 497.64 437.24 497.64" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <polygon points="445.66 490.26 451.53 484.86 445.66 490.26 445.66 490.26" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <polygon points="456.58 487.7 468.64 484.05 456.58 487.7 456.58 487.7" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <path d="M1069.25,790.42c-1.57,9.48-16.93-3.11-7.15,2.94C1062.53,791.43,1063.53,786.12,1069.25,790.42Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="436.72 491.89 441.56 491.43 436.72 491.89 436.72 491.89" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <path d="M1052.56,797.25l-.07-13.28C1050.3,785.12,1062,795.86,1052.56,797.25Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="436.72 491.89 436.72 491.89 436.72 491.89 436.72 491.89" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <polygon points="432.51 493.43 431.56 494.59 432.51 493.43 432.51 493.43" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <polygon points="486.67 449.13 430.42 482.49 486.67 449.13 486.67 449.13" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <polygon points="435.66 481.96 441.74 484.08 435.66 481.96 435.66 481.96" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <polygon points="424.17 477.76 421.03 485.08 424.17 477.76 424.17 477.76" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <polygon points="419.97 457.39 425.59 453.37 419.97 457.39 419.97 457.39" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <path d="M1085.45,773.12c-.75.22,0-.59-3.64.58a2.61,2.61,0,0,1-3.11.58c-.3-.74-.61-1.48-.91-2.23C1079.33,772.28,1083.38,767.49,1085.45,773.12Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <polygon points="423.63 487.73 423.63 487.73 423.63 487.73 423.63 487.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <path d="M1078.7,774.28l3.11-.58c-.19,1.45.93,3.68-3.24,4.51Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1069.25,790.42c.6-1.65-.57-2.09,1.75-5.48l2.45,2.39c0,.68,0,1.36,0,2Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1077.63,788.39l-3.16-1.06v-2.75l4.62,1.6c-.11,2.12-.22,4.25-.32,6.37Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <polygon points="430.42 482.48 430.42 482.48 430.42 482.48 430.42 482.48" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <path d="M1057.83,783.84l1.39,2.25Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="433.04 483.53 432.28 483 433.04 483.53 433.04 483.53" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <path d="M1060.13,778.94l-.36-.26Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="358.81 464.7 379.2 452.66 358.81 464.7 358.81 464.7" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="362.48 431.75 367.18 432.81 358.89 444.45 355.17 428.08 358.32 433.56 362.49 429.67 362.48 431.75 362.48 431.75" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="359.34 444.31 376.45 445.25 359.81 450.58 359.34 444.31 359.34 444.31" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="418.86 487.74 418.86 487.74 418.86 487.74 418.86 487.74" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <polygon points="372.22 488.44 372.22 488.44 372.22 488.44 372.22 488.44" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="379.65 490.55 379.65 490.55 379.65 490.55 379.65 490.55" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="361.41 463.09 364.06 463.17 361.41 463.09 361.41 463.09" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="358.08 487.52 358.08 487.52 358.08 487.52 358.08 487.52" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="357.37 456.68 355.76 465.34 357.37 456.68 357.37 456.68" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="344.73 472.59 343.36 471.63 345.2 472.52 344.73 472.59 344.73 472.59" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="329.06 458.07 336.72 462.9 297.66 433.84 329.06 458.07 329.06 458.07" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="324.88 442.73 317.36 424.49 354.12 466.25 324.88 442.73 324.88 442.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="335.84 457.89 352.55 466.28 335.84 457.89 335.84 457.89" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="354.12 466.25 355.65 465.75 354.12 466.25 354.12 466.25" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <path d="M1106.43,848.78c2.33-1.58,2.12-1.21,5.1,1.31C1110.05,851.22,1109.41,851,1106.43,848.78Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1096.71,867.39c-.33-1.74-2.66-1.32.29-4C1097.2,863.73,1099.5,865,1096.71,867.39Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1097.78,856.56c-2.14.67-1.75,2.11-4.26-.7C1094,855.48,1095.29,854.12,1097.78,856.56Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1114.15,856.55c1.14-1.62,5.31-.68,2.49,1.64A10.87,10.87,0,0,1,1114.15,856.55Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1105.85,844.45c.35-1.07-2.51-2.85-.71-5C1104.75,840.8,1108.17,841.07,1105.85,844.45Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1080.29,820.79l-.36-7.37Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1079.91,812c0-1.43,0-2.87,0-4.3C1079.91,809.1,1079.91,810.54,1079.91,812Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1081.41,828.44c-.1-1.16-.2-2.32-.29-3.48C1081.21,826.12,1081.31,827.28,1081.41,828.44Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1083.82,843.29l-.78-3.21Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1090.71,861.19c.1-.56-1.41-.26-.47-2C1090.1,859.69,1091.68,859.15,1090.71,861.19Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1079.67,802a1.54,1.54,0,0,1-.48,0c0-.71.1-1.42.16-2.13C1079.45,800.56,1079.56,801.28,1079.67,802Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1088,855c.46-.79.48-1.06,0,0C1087.42,854.89,1087.07,852.81,1088,855Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1081.75,834.41c-.07-.73-.13-1.46-.19-2.19C1081.62,833,1081.68,833.68,1081.75,834.41Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1078.94,797.82l0-1.62Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1084.93,848l-.33-1.63Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1081,823.18c-.16-.47-.31-.94-.47-1.41C1080.67,822.24,1080.82,822.71,1081,823.18Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1104.78,849.81s.65.81-.26.35C1104.6,850,1104.69,849.93,1104.78,849.81Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1079.12,794.15l-.06-1.15Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1082.35,837l-.37-1.09Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1103.58,855.26l-.35.3S1102.65,854.65,1103.58,855.26Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1088.69,857.7l-.35-.27S1089.14,856.77,1088.69,857.7Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1154.48,882c-6.78-3.35-4.4-1.92,0,0C1171.54,883.66,1140.14,888.74,1154.48,882Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1164.77,897.56c-1.09,1.09-5.23,1.86-3-2.31Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M1082.26,843.9l.41,1.55-.56.16-.36-1.68Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M1081.81,840.67l.36.51-.18.12-.18-.63Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M826.66,748.12q-.35,3.69-.71,7.38c-21.67-8.28.56,14.62-29.05,6.23C829.61,756.13,794.88,748.29,826.66,748.12Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <polygon points="138.69 430.21 136.11 429.14 138.69 430.21 138.69 430.21" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <polygon points="110.09 414.04 110.09 412.35 110.09 414.04 110.09 414.04" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.3"/>
  <path d="M708,702.18l1.24.38Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M1157.59,882.52l-2.07-.59Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.5"/>
  <path d="M840.27,486.69l-1-1c.34-.95.68-1.9,1-2.84Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M841.93,484.92l-.35-.27S842.38,484,841.93,484.92Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M820.94,484.13l-3.67,2.05Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M929.34,679.18c-1.3.11-2.6.2-3.89.28,1.15.18,2.4.31,3.69.51A4.36,4.36,0,0,1,929.34,679.18Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M941.21,685.73c-.07.47-.16.95-.25,1.43l.24.36c2.33,1.5,2.87.83,3.1-.17l1.89-2.46C944.53,685.17,942.87,685.48,941.21,685.73Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M952.21,686.24a7.17,7.17,0,0,0-3.19,2.29,2.09,2.09,0,0,0-.5.08c-8.13,4.18,1.42,14,1,18.74a.72.72,0,0,0,0,1c4.6-4.71,6.39-11.5,8.18-17.69A5.54,5.54,0,0,1,952.21,686.24Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.38"/>
  <path d="M968.33,677.4,966,678.68a11.76,11.76,0,0,1-.75,3.23C967.51,681.32,969.29,680.66,968.33,677.4Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.38"/>
  <path d="M945.37,725.15c-2.64,3-4.63,3.05-10.2,6.53-1.34-6.58-4.16-24.83,10-23.33C949.08,712.43,933.32,723.8,945.37,725.15Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.38"/>
  <polygon points="362.49 429.67 358.15 435.16 374.72 414.87 374.69 423.59 371.82 418.19 372.25 425.18 370.61 419.01 362.49 429.67 362.49 429.67" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="354.13 441.68 352.8 423.51 359.35 448.99 354.13 441.68 354.13 441.68" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="363.29 417.74 358.09 426.23 354.99 420.38 363.29 417.74 363.29 417.74" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <path d="M941.2,687.52c-.15.21-.3.41-.43.61-.13.62-.27,1.25-.43,1.89a4.46,4.46,0,0,1-2.25,3s0,.09-.07.13L934,706.36c2.7-6.75,10.67-12.9,9.34-18.22Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.38"/>
  <path d="M945.37,725.15l-6.27-2.29,2.6-3c.88,1.66,7.37.91,5.22,5.23Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.38"/>
  <polygon points="358.09 426.23 363.47 418.24 358.5 426.96 358.09 426.23 358.09 426.23" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="351.5 429.1 353.97 441.18 349.57 423.89 351.5 429.1 351.5 429.1" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M939.94,696.27a68.07,68.07,0,0,1,5.42-8.8l3.16,1.14C946.93,690.09,946.69,691.69,939.94,696.27Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M946.92,725.12l-3.64-4.19C944.91,720.22,953.09,720,946.92,725.12Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.38"/>
  <path d="M949.54,707.35c0,1.8.67,3.1.62,7C948.24,712.92,948.63,711.59,949.54,707.35Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.38"/>
  <path d="M962.61,722.51l-3.14-.54c.22-.62.45-1.25.67-1.88Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.38"/>
  <polygon points="362.48 431.75 361.77 433.2 361.04 432.65 362.47 431.23 362.48 431.75 362.48 431.75" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <path d="M951.19,728.69c-.87.09-.88.76-1.93-.84C950.1,727.53,949.82,727,951.19,728.69Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.38"/>
  <path d="M931.41,717.4c.2-1,.39-1.93.59-2.89C931.8,715.47,931.61,716.44,931.41,717.4Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M932.69,711l.65-2.09C933.13,709.6,932.91,710.29,932.69,711Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M933.47,707.88l.3-1.1Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M930.7,725.09c0-.33,0-.66,0-1C930.73,724.43,930.72,724.76,930.7,725.09Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="349.26 421.88 349.16 420.7 349.26 421.88 349.26 421.88" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M945.36,687.47l-2.58,3.66.51-3,1-.79Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M845.53,616.41c1.61,2.09-12.19-12.4-5.82,0C836.66,620.46,844.71,623.27,845.53,616.41Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.23"/>
  <path d="M828.89,620.55c-.07,1-.09,1.55,0,0C827.46,615.38,824.17,632.62,828.89,620.55Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.23"/>
  <polygon points="87.88 321.13 98.47 335.54 87.88 321.13 87.88 321.13" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="20" opacity="0.23"/>
  <path d="M763.63,684.86l-2.2,3.38C760.18,688.19,760.69,682.54,763.63,684.86Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M834.51,608.67c2.17,2.43,3.55,2.52,0,0C831.48,605.35,836.36,615.38,834.51,608.67Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.23"/>
  <path d="M820.4,672.47c2-7.42-10.75,2.48-5.92,17.84a4.58,4.58,0,0,1,3.2,5.88c-.06.23-.1.45-.16.67.6,1,1.24,1.94,2,2.93C826.91,700.91,816.89,680.87,820.4,672.47Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M800.56,694.82c1.56,2.63,3,3.39,0,0C799.31,681,821.87,669.92,800.56,694.82Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M815.91,691c-.6-10.39-4.73-20.44-6.41-10.83,1.41,4.87,2.42,7.87,3.23,9.86A5.7,5.7,0,0,1,815.91,691Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M804.09,677.56c-2.47-.23,3.52-12.27,4.5-3.44C808.74,673.55,806,686.76,804.09,677.56Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M808.4,674.94l.54-2.62Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M801.85,669.64c-11.39-5.59-8,38.68,2.37,13.09C803.7,679.7,805.29,679.61,801.85,669.64Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M794.25,675.51c-3.72,1.79,9.89,16.93-8.34,19.82C787.3,691.93,788.46,684.87,794.25,675.51Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M791.12,678c.2.14-12.79,38.43-7,12C786.15,691.48,781.76,680.5,791.12,678Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M966.27,727.74l-.88,1.31-.43-.44,1.31-.87Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.38"/>
  <path d="M833.23,789.23a23.43,23.43,0,0,1-.61,8.91c-8.82-5.21-23.49-21.17-19.18-30.7C822,771.4,826.77,780.36,833.23,789.23Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <path d="M831.45,708.22a5.91,5.91,0,0,1-.54,3.14c.48.26,1,.51,1.45.79a4.58,4.58,0,0,1,2.26,3.06l0,0c3.83,1.78,3,6.09.28,8.1.32,2.14.75,4,2.12,4.51,0,.49,0,1,.08,1.46,1.28-5.64,6.22-3.6,8.88-3.63C844.45,722.49,836.77,713.37,831.45,708.22Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.6"/>
  <path d="M835.62,726.16l1.45,1.68Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <path d="M833,695.09c-15.55-25.67-13.11-8.44-5.09,8.79l.13,0a5.19,5.19,0,0,1,3.19,6.47l1,1.68.13.07a4.58,4.58,0,0,1,2.26,3.06l0,0a4,4,0,0,1,2.57,4.13c2.9,3.53,5.92,5.95,8.79,6.31C860.13,731.71,828.69,700.2,833,695.09Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.6"/>
  <path d="M846.55,720.41l1.05-1.55c.35,1.56.7,3.13,1,4.69Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.6"/>
  <path d="M849.69,724.6l-1.05-1.05Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.6"/>
  <path d="M846.42,727.64c-.29,0-.15.85-1.15.19C845.59,727.64,845.47,727,846.42,727.64Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.6"/>
  <path d="M836.09,737.67l1-1.07C836.75,737,836.42,737.31,836.09,737.67Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.75"/>
  <polygon points="435.26 470.33 434.17 478.66 435.26 470.33 435.26 470.33" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.75"/>
  <path d="M1058.29,768l.74-4Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <path d="M1077.92,787.75c-.12.13.17.26-.29.64C1077.67,788.13,1077.56,788.18,1077.92,787.75Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <polygon points="355.95 451.12 355.06 446.92 355.95 451.12 355.95 451.12" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="357.21 456.34 356.12 452.68 357.21 456.34 357.21 456.34" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="356.66 452.76 356.26 449.51 356.66 452.76 356.66 452.76" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="355.7 446.92 355.06 446.92 355.33 445.3 355.7 446.92 355.7 446.92" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="354.73 442.63 355.65 443.79 354.73 442.63 354.73 442.63" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <polygon points="316.08 427.19 322.48 440.17 320.05 440.1 320.41 444.59 300.26 423.41 316.08 427.19 316.08 427.19" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="299.71 418.15 299.24 423.38 299.71 418.15 299.71 418.15" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <path d="M883.23,726.67l-.66,3.12Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.6"/>
  <polygon points="327.09 445.84 330.61 451.04 327.09 445.84 327.09 445.84" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="332.25 453.17 335.19 456.57 332.25 453.17 332.25 453.17" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <polygon points="324.88 442.73 325.43 443.76 324.88 442.73 324.88 442.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="314.96 425.44 314.38 423.39 316.16 425.43 314.92 425.45 314.96 425.44 314.96 425.44" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="314.92 425.45 316.08 427.19 314.92 425.45 314.92 425.45" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="354.13 441.68 354.57 442.25 354.13 441.68 354.13 441.68" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M891.87,731.06,890,728.75Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.6"/>
  <path d="M894.06,734.11l-2-2.71Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.6"/>
  <polygon points="314.38 423.39 313.72 422.44 314.38 423.39 314.38 423.39" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.6"/>
  <polygon points="362 420.75 359.26 423.92 359.62 420.25 362 420.75 362 420.75" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.38"/>
  <path d="M830.45,692.15c-.24-3.3-.47-6.61-.71-9.91l1.13.53q1.05,5,2.11,9.94C831.93,693.48,831.16,692.92,830.45,692.15Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.23"/>
  <path d="M823.9,677.33c3.07.56,4.35,0,0,0C822,677.89,828.27,686.83,823.9,677.33Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M830.45,692.15l2.53.56c.54,3.3,6,9.78,5.24,14.63Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.23"/>
  <path d="M808,693.53a4.67,4.67,0,0,1,4.56-3.5c.88-2.53,1-4.72-1.05-5.79-4.92,8.31-7.55,14.2-8.71,18.27a4.05,4.05,0,0,1,4.26-.94A25,25,0,0,1,808,693.53Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M823.22,677c.58.29.89.4,0,0C818.37,678.55,824.24,686.64,823.22,677Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M821,679.6c-.47.24,1.11,1.58-1.66,2C820.14,682.94,817.54,674,821,679.6Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M827.94,703.88c-.62-4-6-9.43-4.83-12-2.47-11.5-4.27.58-3.92,9.53A53.09,53.09,0,0,1,827.94,703.88Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M807.19,697.85a15.35,15.35,0,0,0-1.25,3.47,4.47,4.47,0,0,1,1.16.25A28.89,28.89,0,0,1,807.19,697.85Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M802.64,685.91l2.09-3.65Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M811.55,684.24l-.55.11C810.81,683.55,808,676.78,811.55,684.24Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M801.58,688.51l1.07-1.55C802.93,687.67,805.22,688.81,801.58,688.51Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.23"/>
  <path d="M937.46,508.18a.53.53,0,0,0,0-.13,1.77,1.77,0,0,0,0-.2c-.07-.33-.16-.66-.24-1a16.84,16.84,0,0,1-.48-3.31,4.62,4.62,0,0,1,1.68-3.69q-1.26-2.49-3-5.21C933.16,513.82,950.6,522,921.78,527c-12.14,8.56,21.28,8.09,20.48-13.8A5.11,5.11,0,0,1,937.46,508.18Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.28"/>
  <path d="M901.17,596.23c-1.47,16.83-71.3-16.88-49-2.7C856,613.27,931.16,605.55,901.17,596.23Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="4" opacity="0.7"/>
  <polygon points="486.18 319.95 487.1 310.31 479.44 317.44 486.76 330.84 488.97 329.08 486.18 319.95 486.18 319.95" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.66"/>
  <polygon points="538.65 338.27 493.35 296.5 492.64 304.47 518.78 327.81 517.33 329.89 539.93 339.96 538.65 338.27 538.65 338.27" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.66"/>
  <polygon points="494.47 322.18 489.99 324.3 493.01 326.43 495.95 325.18 494.47 322.18 494.47 322.18" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.66"/>
  <path d="M1085.48,744.48l.29-.26c.56-2.61,1.9-3.83,3-2.74,6.91,20.43,11.9,44.8,20.86,62.7.41.18.49.63,1.1,2.45,0,.68-.37.71-.56,1.2.59.23-.37-2.12,3.88,3.28-.73,11.61-.87,15.23-6-.42-.07.89-.15,1.78-.23,2.66-.65.51-.49.24-2.29,1.69,1.59,4.44,5.1-2.66,5.73,8.86-1.31-1.08-1.84-1.72-4.2-3.57.29,2.45.76,3.43,2.29,7.29-1-.66-.73.54-3.6-1.73,3.24,23.52-.41,4.09-3.08-6,0,2.32.2,5,.52,9.19-4.34-21.45-11.23-43-14.39-66l-.19-1C1088.82,762.29,1085,749.26,1085.48,744.48Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6" opacity="0.4"/>
  <path d="M961.28,628.34l-.78,3.51c-5.85,1.58,1.29,3.4.53,8.37-2,1.79-4.18-6.05-7.86-9.07l2.05,5.34c-.75,1.36-2.91,1.5-4.6-2.65-6.86-13.54-4.93,7.74-10-3.59-13.25,11.08,7-27.7-2.79-58.74-2.66,2.32-6.31,5-3.65-3.73,10.88-33.86,10-37.64,17.31,5.63.64,14.89-4.65,28.92-6.42,43.72,4.25-6.17,4.73-7,6.66,4.78,1,.27.8,1,5.76,4C958.42,626.69,957.72,625.36,961.28,628.34Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.41"/>
  <path d="M943.74,519.36c-3.15,1.87-3.29,4.57-2.76,7.68a5.38,5.38,0,0,1,1.82,5.07s0,.08,0,.12v.2c0,.34,0,.68,0,1a16.19,16.19,0,0,1-.25,3.34,4.67,4.67,0,0,1-.46,1.3,10.24,10.24,0,0,1-.86,2.45c-.27,11.41-12.65,19.34-7.83,30.08,1.45-.33,1.57-1.29,1.17-2.48.07-.31.13-.61.2-.91,3.47-7.51,4.91-14.82,7.25-20.94a.93.93,0,0,1,.36-1.53C947.23,534.44,944.88,528.44,943.74,519.36Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.68"/>
  <path d="M933.4,570.62l1.17-2.48c-.47,1.35-1,2.71-1.42,4.07C933.23,571.68,933.32,571.15,933.4,570.62Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M934.38,573.51l-.24,1c-.22.52-.5-.44-1.48,1.85-1.81-.8-1.78-1.49.16-3.21A10.09,10.09,0,0,0,934.38,573.51Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M906.61,612.27c1.51-.86,3.17-1.58,4.89-2.37a9.76,9.76,0,0,0,3.55-2.95,5.48,5.48,0,0,1,2.29-5.71c.07-.15.14-.29.2-.43a13.39,13.39,0,0,1,.84-1.9,11.93,11.93,0,0,1,2.29-3.32,3.57,3.57,0,0,1,.05-1.54c-6.5,5.56-13.46,12.1-15.77,17.89l-.29,1.31A4.34,4.34,0,0,1,906.61,612.27Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.65"/>
  <path d="M917,596.43l8.91-11.4C926.83,586.34,923.58,590.74,917,596.43Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.65"/>
  <path d="M932.82,573.14c.59.1-2,10.47-6,10.59C928.65,581.86,928.1,574.28,932.82,573.14Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.65"/>
  <path d="M926.85,583.73l4.86-5.46-6.39,9.26C925.87,586.72,924.61,587.29,926.85,583.73Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.65"/>
  <path d="M917,596.43l5.38-5.24c-.7.66.33,1.34-5.61,6.3C916.88,597.14,917,596.78,917,596.43Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.65"/>
  <path d="M905.28,610.41l-.33,1.53-1.77.69Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M923.32,589.82l1.18-.87Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.65"/>
  <path d="M933,575.4l1.13-.9Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
  <path d="M913.31,601.57l-8,8.84,7.68-9.53C913.32,601,913.49,601.19,913.31,601.57Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.65"/>
  <path d="M913.31,601.57l-.35-.69c.38-1.06.31-1,3.81-3.37C915.62,598.86,914.47,600.22,913.31,601.57Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.65"/>
  <path d="M915.36,598.72l-1.71.76Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.65"/>
  <path d="M838.53,598.68c31,19-114.42-84.3,9.91-96.36C788.16,475,769.38,572.34,838.53,598.68Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.41"/>
  <path d="M838.54,598.68c45.91,32-96.68-76.33-5.11-99.58C786.61,481.21,789.42,569.7,838.54,598.68Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.41"/>
  <path d="M959.08,431.69c-80.86-2.71-43.91-109-143.15,6.61-12.56,10.33,6.91-1.85-3.35-11.09-17.72,9.44-15.06.39-7.36-6.79,31-.82,22.61,1.57,45.94-30,23.81-32.35,40.29,1,35.33-21.9,15.3.93-32.39-19.08-51.69-10.06-21.45,3.73-32.85,75.37-30.2,55.37C833.49,257.64,955.85,416.31,959.08,431.69Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M802.93,381c-7.06,5.34-51.71,70.1-37.09,50.06,24.24-47.84,52.74-97.5,79.45-94.88C826.32,351.25,806,361.93,802.93,381Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M763.1,435.84c5.28.05-6.47,40.58,2,24.38,10.35-10,29-44.51,18.12-14.92C775.47,480.15,742.08,481,763.1,435.84Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M808.62,353.73l-21.36,30.89Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M846.24,333.29c1.32-.47-28.1,8.58-18.55,5.44C832.17,338,850.15,329.2,846.24,333.29Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M808.62,353.73c-6.63,2.88,21.68-18.69,12-11Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M803.45,419.65c27.77-60.57,20.86-36.25,16.76-52.35,64.52-32.45,80.38,14.72,67.85.89-13.5,6.1-32.64,13.22-59.89,53.13C819.36,428.6,844.19,396.37,803.45,419.65Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M766.74,488.56c17.19-14,11.28-34.09,51.82-58.12-14.28,24.41,8.19-.79,9.58-1.21-4.22,8.34-14.48,24.43-6.11,12.87,43.87-58.82,75.74-87.36,83-16.06-30.83-75.71-68.07,13.2-65.55,33-32.62-7.67-10.75-6.34-8.71-18.17C801.86,460.06,798.71,478.68,766.74,488.56Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M846.72,333.25c44.75,12.43,52.49,19.73,57.55,34.12-12.79-3.46-39.75-37.78-54.21-22.57C778.93,409,792.12,372,846.72,333.25Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M776.75,425.39c-.62,2.9,32.7-59.63,22.5-35.69C795.8,419,778.64,443.39,776.75,425.39Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M782,429.07c21.25,6,6.85,33.44-1.12,19.92C780.57,444.74,791.89,433.45,782,429.07Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M798.72,436.88c-10.92,8,23-66.3-1.64-18.29C794.44,431,813.48,399.33,798.72,436.88Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M806.55,401.33c.43-.92-23.84,34.53-19.14,26.84C792.26,421.64,805.74,383.25,806.55,401.33Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M804.45,439.49c-2.05-10.18-3.33-5.69,9.4-14Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M802.37,395.58c1.2,1.48-12.89,19.72-9.32,11.62C796.43,404.2,798.65,394.26,802.37,395.58Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M802.37,395.58c-.85.21,2.19-11.6,6.58-9.08C808.49,386.49,805.66,392.83,802.37,395.58Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M802.9,408.65c1.63-3.27,1-2,0,0C798.85,410.54,807.9,399.15,802.9,408.65Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M815.1,404.64,814,406.21C814.64,407.87,812.68,402.24,815.1,404.64Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M801.31,383.54l1.07-1.55Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M776.75,425.39c-12.51,17.6-3.46,26.51-18.07,42C757.65,449.83,792.77,380,776.75,425.39Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M782.48,406.58c-7.39-.12-.69-5.35,5.26-12.05Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M778.86,409.69c-.48,3-9.52,23.38-2.07,1.16C777,413.37,777,403.59,778.86,409.69Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M787.74,394.54h0C786.59,394.21,793,387.43,787.74,394.54Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M772,449.4c2.27-25.15.67-11.9,13.83-28.93Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M780.4,434.77h0C780,428.06,791.6,430.77,780.4,434.77Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M780.4,434.77h0C783,433.65,778.4,445.35,780.4,434.77Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M807.08,356.89h0C806.64,356.55,810.19,354.75,807.08,356.89Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M807.08,356.89h0C807.36,357.15,805.29,358,807.08,356.89Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M818.07,492.31c-.7-.05-1,.65-6.58.27,2.41-3.46,4.87-9.47,7.48-15.35-5.43-2.41-5.49,5.36-10.86,8.38-.19-3.62,3.8-6.58,5.64-11.51L793,492.68l-1.33-1.39c4.22-4.7,11.2-10.07,15.71-16.57-6.2.23-11.55,5.15-17.06,10-.78-6,6.25-9.76,10.8-16.51l-2.74-.62c2-2.27,8.83-2.59,9.78-9.32h0c7.63-5.23,15.09-17.51,25.08-19.75q-2.34,4.65-4.69,9.3c-.86-1.36-1.28-.13-2.21.59,1-.62,1.47-1.25,2.22-.6-2.85,3-7.54,4.11-9.32,10.06,1.2.09,2.23-2,5.14-4.41.17,1.26.34,2.51.52,3.77-.32.28-.56.63-.86.93,1.21-1.08.48-.89,3.46-2,1.32,3.45-4.09,7.93-5.21,13.22.68-4.72,22.61-2.66,16.71-6.9.27-3.78-11.28,8.32-8-11.25,1.33,4.51,5.39,7.43,11.17,9.62,2.7-3.05,10.84,6.79-4.71,10.92C827.52,468.68,830.12,474.32,818.07,492.31Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.2"/>
  <path d="M790.69,452.52l-.35.3C790.29,452.83,789.75,451.91,790.69,452.52Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.2"/>
  <path d="M796.09,440.77c-.4.45-.66.76-.92,1.06C794.92,442,794,439.69,796.09,440.77Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.2"/>
  <path d="M805.52,432.71h0C804.88,433.43,808,428.2,805.52,432.71Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10" opacity="0.2"/>
  <path d="M828.52,447.86l-3.73,2.4c1.23-1,.28-2.12,4.16-4.88l-.44,2.49Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7" opacity="0.2"/>
  <path d="M921.15,481.31a4.44,4.44,0,0,1,1.74-.86c-3-5.23-10.33-4-14.1-5.07-8,.1-14.57,9.05-19.9,11.6l.75,1.55a3.72,3.72,0,0,1,.57.08,4.42,4.42,0,0,1,.48-1.06l.22-.31c.06-.12.11-.24.18-.36l.78-1a4.91,4.91,0,0,1,2.2-1.28l1.13-.16c.63-2,.76-2.78,3.66,1.11,8.53,2.07,11.48-2.75,14.58-6.91a4.14,4.14,0,0,0,2.39,2.85l1-.14a4.43,4.43,0,0,1,.9-.35l1.33-.18a3.68,3.68,0,0,1,1.93.43Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" opacity="0.07"/>
  <path d="M912.4,483.84l-5.25.55c1.57-.92,1.93-7,6.29-5.76Q912.93,481.23,912.4,483.84Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.25"/>
  <path d="M836.34,493.51,827,494c-1.24-1-.34-3-4.58-6.44-1.44,2.71-2.66,4.7-8.45,7.06-.07-.89-1-1.38-1.06-4.19,6.58-4.48,17.15-9.3,26.24-3.89C838.75,489.06,839.31,492,836.34,493.51Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.3"/>
  <path d="M836.88,483.05c.86.77.51-1.23,4.18,1.53l1.05,0c1.6,2.24,6.4,4.63,7.37,9.7-3.35-.54-8.44,1.93-13.14-.81.87-2.27,1.75-4.55,2.63-6.82C838.51,485.34,836,485.18,836.88,483.05Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.3"/>
  <path d="M821.19,486.16l1.55-4.11,12-.06C834.09,484.84,833.4,483,821.19,486.16Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.3"/>
  <path d="M827.45,484.09c1.89-.73,2,.33,7.33-2.1l2.1,1.06,2.09,3.64-10.42-2C827.94,484.93,827.45,485,827.45,484.09Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.3"/>
  <path d="M827.45,484.09l1.1.6-4.74,1.48Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.3"/>
  <path d="M841.06,484.58l-1-1c.33-.95.67-1.9,1-2.84C841,482,841.05,483.3,841.06,484.58Z" transform="translate(-581.84 -313.76)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" opacity="0.3"/>
</svg>

            </div>
        );
    }


}