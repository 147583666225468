import { Component } from 'react';
import DefaultHeader from '../DefaultHeader/DefaultHeader';
import DefaultContentWrapper from '../DefaultLayout/DefaultContentWrapper';
import DefaultLayout from '../DefaultLayout/DefaultLayout';
import Chart from 'chart.js';
import "chartjs-plugin-annotation";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import data from "../../data/skills.json";
import growthDat from "../../data/growth.json";
import visionDat from "../../data/vision.json";
import bonusDat from "../../data/bonus.json";
import LineChart from "../SkillCharts/LineChart";
import BubbleChart from "../SkillCharts/BubbleChart";
import BubbleChartBonus from "../SkillCharts/BubbleChartBonusSkill";
import "./css/skills.css";
import * as Global from '../Global/Global';
import sorry from "../../images/sorry_ppl.png";
export default class CoreSkills extends Component {

    componentDidMount(){
        Chart.plugins.unregister(ChartDataLabels);
        this.showSkill(null, "growth");
        this.renderGrowthChart();
        
    }
    themeColor = Global.GetThemeColor().themeMainColor;
    skills = data.map((dat)=> {
        return dat;
    });

    growthData = {
        labels : growthDat.labels,
        datasets : growthDat.datasets
    };

    visionData = {
        datasets : visionDat.datasets
    }

    bonusData = {
        datasets : bonusDat.datasets
    }
    hintFigure = (e) =>  {
        document.querySelector(".sk-desc").classList.add("sk-desc-visible");
        document.querySelector(".sk-desc").classList.remove("sk-desc-hidden");
        document.querySelector(".sk-left").classList.add("out-of-focus");
        document.querySelector(".sk-center").classList.add("out-of-focus");
    }
    closeHint = (e) =>  {
        document.querySelector(".sk-desc").classList.remove("sk-desc-visible");
        document.querySelector(".sk-desc").classList.add("sk-desc-hidden");
        document.querySelector(".sk-left").classList.remove("out-of-focus");
        document.querySelector(".sk-center").classList.remove("out-of-focus");
    }
    explainFigure = () => {
        document.querySelector(".sk-desc").classList.toggle("sk-desc-visible");
        document.querySelector(".sk-desc").classList.toggle("sk-desc-hidden");
        document.querySelector(".sk-left").classList.toggle("out-of-focus");
        document.querySelector(".sk-center").classList.toggle("out-of-focus");

    }

    showSkill = (e,skillId) => {
        var flClass = "sk-flyout";
        var visibleClass = "sk-flyout sk-flyout-visible";
        var flyouts = document.getElementsByClassName(flClass);
        for(var i = 0; i < flyouts.length; i++){
            flyouts[i].className = flClass;
        }
        
        if(e != null && e.target != null){
            e.target.className = visibleClass;
        }
        var skill = this.skills.filter((sk)=> sk.skillId === skillId)[0];
        var label = skill.label;
        var desc = skill.desc;
        document.getElementById("sk-label-sp").innerText = label;
        document.getElementById("sk-desc-p").innerText = desc;
    }
    
    renderGrowthChart = () => {
        document.getElementById("sk-chart").remove();
        document.getElementById("sk-graph").innerHTML = '<canvas id="sk-chart" ></canvas>';
        var ctx = document.getElementById("sk-chart");
        LineChart(ctx, this.growthData, "", "Ability");
    }

    renderVisionChart = () => {
        document.getElementById("sk-chart").remove();
        document.getElementById("sk-graph").innerHTML = '<canvas id="sk-chart" ></canvas>';
        var ctx = document.getElementById("sk-chart");
        BubbleChart(ctx, this.visionData.datasets);
    }

    renderBonusChart = () => {
        document.getElementById("sk-chart").remove();
        document.getElementById("sk-graph").innerHTML = '<canvas id="sk-chart" ></canvas>';
        var ctx = document.getElementById("sk-chart");
        BubbleChartBonus(ctx, this.bonusData.datasets, "Added value", "Ability");
    }

    render(){
        return(
            <DefaultLayout>
                <DefaultHeader
                    title="core skills"
                    watermark="Performance"
                    titleColor={this.themeColor}>
                </DefaultHeader>
                <DefaultContentWrapper>
                    <div className="compatibility-toggle">
                        <span>Please turn your device horizontally or
                             navigate this page on tablet or desktop. 
                            Charts are hard to view on tiny screens.</span>
                        <img src={sorry}></img>
                    </div>
                    <div className="skills-container">
                        <div className="sk-left">
                            <div className="sk-flyout" style={{animationDuration:"0.5s"}} onClick={(e) => { this.showSkill(e,"vision"); this.renderVisionChart();}}>
                                <span>Vision</span>
                            </div>
                            <div className="sk-flyout" style={{animationDuration:"1s"}} onClick={(e) => { this.showSkill(e,"growth"); this.renderGrowthChart();}}>
                                <span>Growth</span>
                            </div>
                            <div className="sk-flyout" style={{animationDuration:"2s"}} onClick={(e) => { this.showSkill(e,"bonus"); this.renderBonusChart();}}>
                                <span>Bonus</span>
                            </div>
                        </div>
                        <div className="sk-center" id="sk-graph">
                            <canvas id="sk-chart" ></canvas>
                        </div>
                        <div className="sk-right">
                            <div className="sk-desc sk-desc-hidden">
                                <p id="sk-desc-p"></p>
                            </div>
                            <div className="sk-label" 
                                title="click to close"
                                onMouseEnter={this.explainFigure} 
                                onMouseLeave={this.closeHint} 
                                onClick={this.explainFigure}>
                                <span id="sk-label-sp"></span>
                            </div>
                        </div>

                    </div>
                </DefaultContentWrapper>
            </DefaultLayout>
        );
    }
}
