import { Component } from "react";
import "./css/contact.css";

export default class Contact extends Component {
  render() {
    return (
      <div className="contact-container">
        <div className="btn-home">
          <a href="#" title="home">
            <div className="btn-home-arrow"></div>
          </a>
        </div>
        <h1>
          <a href="https://www.linkedin.com/in/huyenpk/" target="_blank">
            Grab a coffee ?
          </a>
        </h1>
      </div>
    );
  }
}
