import { Component } from "react";
import NavItem from "./NavItem";
import "./css/navigation.css";
export default class NavigationMain extends Component {
    render() {
        return (
            <div className="navigation-container">
                <div className="myname-container">
                    <div className="job-container">
                        <div className="job-trait"></div>
                        <div className="job-title">Software engineer</div>
                    </div>

                    <div className="myname">Huyen Pham</div>
                </div>
                <div className="nav-content">
                        <NavItem linkPath = "/skills" smTitle ="Core skills" mainTitle = "360° Skills"/>
                        <NavItem linkPath = "/projects" smTitle ="Porfolio" mainTitle = "Work & Production"/>
                        <NavItem linkPath = "/contact" smTitle ="Contact" mainTitle = "Team coffee ?"/>
                        {/* <NavItem linkPath = "/about" smTitle ="About" mainTitle = "Persona"/> */}
                        {/* <NavItem linkPath = "/experiments" smTitle ="Experiments" mainTitle = "Trail blazer"/> */}
                </div>
            </div>
        );
    }
}

export function AnimateNav() {
    var navsitem = document.querySelectorAll(".nav div.animate");
    var navNames = document.querySelectorAll(".myname-container div");
    navsitem.forEach((item, index) => {
        item.style.animationDelay = `${index / 10 + 0.5}s`;
        item.classList.toggle("anim");
    });
    navNames.forEach((item, index) => {
        item.classList.toggle("anim-name");
    });
}

