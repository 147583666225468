import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { GetThemeColor } from '../Global/Global';


export default function BubbleChart(ctx, data){
    var themeColor = GetThemeColor().themeMainColor;
    var chart = new Chart(ctx, {
        type: 'bubble',
        data: { datasets: data},
        plugins: [ChartDataLabels],
        options: {
            scales: {
                y: {
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 100,
                        display:false
                    }
                },
                x: {
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 100,
                        display:false
                    }
                },
                xAxes: [{
                    gridLines: {
                        color: "rgba(0, 0, 0, 0)",
                    },
                    scaleLabel: {
                        display: true,
                        labelString: "Completeness of vision",
                        fontFamily: 'Jost',
                        fontColor : themeColor,
                        fontSize:16,
                    }
                }],
                yAxes: [{
                    gridLines: {
                        color: "rgba(0, 0, 0, 0.1)",
                    },
                    scaleLabel: {
                        display: true,
                        labelString: "Ability to execute",
                        fontFamily: 'Jost',
                        fontColor : themeColor,
                        fontSize:16,
                    }   
                }]
                
            },
            legend: {
                labels: {
                    fontFamily: 'Jost'
                }
            },
            annotation : annotation
        }
    });

    return chart;
}

const annotation = {
    annotations: [
        
        {
            drawTime: "beforeDatasetsDraw",
            type: "box",
            xScaleID: "x-axis-0",
            yScaleID: "y-axis-0",
            xMin: 0,
            xMax: 50,
            yMin: 50,
            yMax: 100,
            // backgroundColor: "rgba(250, 92, 1, 0.1)",
            backgroundColor : "white",
            borderWidth: 1
        },
        {
            drawTime: "beforeDatasetsDraw",
            type: "box",
            xScaleID: "x-axis-0",
            yScaleID: "y-axis-0",
            xMin: 0,
            xMax: 50,
            yMin: 0,
            yMax: 50,
            // backgroundColor: "rgba(0, 0, 0, 0.1)",
            backgroundColor : "white",
            borderWidth: 1
        },
        {
            drawTime: "beforeDatasetsDraw",
            type: "box",
            xScaleID: "x-axis-0",
            yScaleID: "y-axis-0",
            xMin: 50,
            xMax: 100,
            yMin: 0,
            yMax: 50,
            // backgroundColor: "rgba(1, 88, 250, 0.1)",
            backgroundColor : "white",
            borderWidth: 1
        },
        {
            drawTime: "beforeDatasetsDraw",
            type: "box",
            xScaleID: "x-axis-0",
            yScaleID: "y-axis-0",
            xMin: 50,
            xMax: 100,
            yMin: 50,
            yMax: 100,
            // backgroundColor: "rgba(1, 250, 63, 0.1)",
            backgroundColor : "white",
            borderWidth: 1
        }
        
    ]
}
