import React, { Component } from 'react';
import "./css/default-layout.css";

export default class DefaultLayout extends Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
            <div className="lt-container">
                {this.props.children}
            </div>
        );
    }
}